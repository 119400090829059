import React, { useEffect, useState } from "react"
import "./index.scss"
import { useParams } from "react-router-dom"
import TranslationVersion from "./components/TranslationVersion"
import { indexApi, itemApi } from "helpers/api_helper"
import { loadingSpinner } from "siteSettings"
import { FullTranslationVersionType } from "types/translationVersions"
import MetaTags from "components/UI/MetaTags/metaTags"
import { BookType } from "types/books"

const Index = () => {
	const { id } = useParams<{ id: string }>()
	if(!id) return ""

	const [translationVersion, setTranslationVersion] = useState<FullTranslationVersionType | null>(null)
	const [books, setBooks] = useState<BookType[]>([])

	useEffect(() => { loadPage() }, [id])

	const loadPage = () => { 
		itemApi("translation_versions", id, setTranslationVersion) 
		indexApi("books", setBooks)
	}
	const context = { loadPage, books }

	if (!translationVersion) { return loadingSpinner }
	return <div>
		<MetaTags title={translationVersion.name} description={translationVersion.description} url={`translations/${id}`} />
		<TranslationVersion translationVersion={translationVersion} context={context} />
	</div>

}

export default Index
