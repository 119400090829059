import React, { FormEvent, useContext, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { apiPath } from "helpers/helpers"
import { AppContext } from "structure/AppContext"

import MetaTags from "components/UI/MetaTags/metaTags"

const LogIn = () => {
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [formColor, setFormColor] = useState("transparent")
	const [error, setErrorMessage] = useState("")

	const appContext = useContext(AppContext)

	const handleLogin = async (e: FormEvent) => {
		e.preventDefault()
		setFormColor("rgba(255,255,255,.4)")

		try {
			const res = await axios.post(`${apiPath}/users/auth/login`, { username, password })

			setFormColor("rgba(0,200,0,.4)")
			appContext.login(res.data.user, res.data.token)
			setTimeout(() => { window.location.replace("/users/dashboard") }, 200)

		} catch (err: any) {
			if (err.response.data.message === "Invalid Credentials.") {
				setFormColor("rgba(200,0,0,.4)")
				setErrorMessage("The information you entered does not match our records. Please try again.")
				setTimeout(() => { setFormColor("transparent") }, 2000)
			}
		}
	}

	return <div>
		<MetaTags title={"Log In"} privatePage url={"users/login"} />
		<h2 style={{ paddingTop: "5rem" }}>Log In</h2>
		<div>
			<Link to="/users/register">Create an Account</Link>&nbsp;|&nbsp;
			<Link to="/users/forgottenPassword">Forgot Password</Link>
		</div>
		<br />
		{error || ""}
		<br />
		<form onSubmit={(e) => handleLogin(e)} style={{ maxWidth: "800px", width: "100%", margin: "auto", backgroundColor: formColor }}>
			<div>
				<label>Username or Email</label><br />
				<input
					onChange={(e) => { setUsername(e.target.value) }} type="text"
					name="username" placeholder="Username"
					value={username} />
			</div>
			<br />
			<div>
				<label>Password</label><br />
				<input
					onChange={(e) => { setPassword(e.target.value) }} type="password"
					name="password" placeholder="Password"
					value={password} />
			</div>
			<br /><br />
			<button>Login</button>
		</form>
	</div>

}

export default LogIn
