import React, {useContext} from "react"
import { updateApi } from "helpers/api_helper"
import { BookEditorContext } from "../context"

interface DropspaceProps {
	kind: "part" | "chapter" | "verse"
	order: number
	parent: number
}

const Dropspace = ({ kind, order, parent }: DropspaceProps) => {

	const context = useContext(BookEditorContext)
	const {dragItem} = context
	
	const update = () => {
		if(dragItem) {
			const url = dragItem.item_kind + "s"
			const id = dragItem.item_id
			updateApi(url, id, { order }, context)
		}
	}

	const sameKind = dragItem && kind === dragItem.item_kind
	const validOrder = dragItem && (order !== dragItem.item_order)
	const sameParent = dragItem && ((!parent && !dragItem.item_parent) || (parent === dragItem.item_parent))

	const show = sameKind && validOrder && sameParent

	return <div className={`dropspace dropspace-${kind}`} 
		onDragOver={(e) => {
			if (show) {
				e.preventDefault()
				const { target } = e
				if (target instanceof HTMLElement) {
					target.classList.add("dropspace-hover")
				}
			}
		}}
		onDragLeave={(e) => {
			const { target } = e
			if (target instanceof HTMLElement) {
				target.classList.remove("dropspace-hover")
			}
		}}
		onDrop={(e) => {
			const { target } = e
			if (target instanceof HTMLElement) {
				target.classList.remove("dropspace-hover")
			}
			update()
		}}>
	</div>
}

export default Dropspace
