import React, { useState, useContext } from "react"

import ControlBar from "./components/ControlBar"
import PartChapters from "./components/PartChapters"
import Dropspace from "../Dropspace"
import { PartType } from "types/parts"
import { BookEditorContext } from "components/BookEditor/context"
import { PartField } from "helpers/books_helper"


import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"

const BookPart = ({ part }: { part: PartType }) => {
	const context = useContext(BookEditorContext)
	const [open, setOpen] = useState(true)

	if (part.book_part_id !== undefined) {
		part.id = part.book_part_id
		return <div>

			<Dropspace kind={"part"} order={part.order-1} parent={part.book_id} />

			<ControlBar part={part} open={open} setOpen={setOpen} />

			{open ? <>
				<p className="align-left">
					<NoteForm foreign_class="part" foreign_id={part.book_part_id} context={context}  />
					<NoteModal notes={part.notes} context={context} />
					{PartField(part, "summary", context)}
					{PartField(part, "commentary", context)}
				</p>

				<PartChapters part={part} />
			</> : ""}

			<Dropspace kind={"part"} order={part.order} parent={part.book_id} />

		</div>
	} else return ""
}

export default BookPart
