import React from "react"
import { NoteType } from "types/note"
import { Col, Row } from "react-bootstrap"
import { NoteField } from "helpers/note_helper"
import { Link } from "react-router-dom"
import { deleteApi } from "helpers/api_helper"

interface NoteProps {
	item: NoteType
	context: { loadPage: () => void }
}

const NoteComponent = ({ item, context }: NoteProps) => {
	if (!item) return ""
	item.id = item.note_id

	const deleteNote = () => deleteApi("notes", item.note_id, context)

	return <div className="note-item align-left">
		<Row>
			<Col lg={2}>
				{NoteField(item, "urgency", context)}
			</Col>
			<Col lg={2}>
				{NoteField(item, "kind", context)}
			</Col>
			<Col lg={2}>
				{NoteField(item, "status", context)}
			</Col>

			<Col lg={5}></Col>
			<Col lg={1}>
				<span onClick={deleteNote} className="fas fa-trash format-link"></span>
			</Col>


			<Col lg={12}>
				{NoteField(item, "text", context)}
			</Col>

			
			<Col lg={4}>
				{ item.user && item.user.username }
			</Col>
			<Col lg={4}>
				{NoteField(item, "created_at", context)}
			</Col>
			<Col lg={4}>
				{ item.item && <Link style={{padding:".5rem"}} to={item.item.url}>{item.item.content}</Link> }
			</Col>

		</Row>
	</div>
}

export default NoteComponent
