import React from "react"
import { Container } from "react-bootstrap"
import Collapsable from "./CollapseablePanel"

const ExampleSection = () => {
    
	const examples = [406, 435, 474, 461, 468]
	return <div className="example-section">
		<Container style={{ padding: "5rem 1rem" }}>
			<h2>Examples</h2>
			{examples.map(e => <Collapsable key={e} id={e} />)}
		</Container>
	</div>

}

export default ExampleSection
