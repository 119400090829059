import React from "react"
import { Routes, Route } from "react-router-dom"

import BookPrinter from "./Books/index"
import CategoryPrinter from "./Category/index"

const Printer = () => {
	return <Routes>
		<Route path="/books" element={<BookPrinter />} />
		<Route path="/category" element={<CategoryPrinter />} />
	</Routes>
}

export default Printer
