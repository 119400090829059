import React from "react"

import { TranslationType } from "types/translationVersions"

import { createApi, deleteApi, updateApi } from "helpers/api_helper"
import { checkRole } from "helpers/helpers"
import { TranslationField } from "helpers/translation_helper"
import { Col, Row } from "react-bootstrap"
import ToolTip from "components/UI/ToolTip"

import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"

interface TranslationVersionProps {
	translation: TranslationType;
	context: {
		loadPage: () => void;
	}
}

const Translation = ({ translation, context }: TranslationVersionProps) => {
	translation.id = translation.translation_id

	const deleteTranslation = () => {
		deleteApi("translations", translation.translation_id, context, `${translation.chapter}:${translation.verse}`)
	}
	const insertTranslation = () => {
		const data = {
			translation_version_id: translation.translation_version_id,
			book_id: translation.book_id,
			verse: translation.verse,
			chapter: translation.chapter
		}
		createApi("translations", data, context)
	}
	
	const mergeTranslation = () => {
		updateApi("translations/merge", translation.translation_id, {}, context)
	}
	
	const breakChapter = () => {
		updateApi("translations/breakChapter", translation.translation_id, {}, context)
	}

	return <div className="align-left">
		{translation.subtitle &&
			<p className="align-center"><b>{TranslationField(translation, "subtitle", context)}</b></p>}

		<Row>
			<Col lg={10}>
				{TranslationField(translation, "chapter", context)}:
				{TranslationField(translation, "verse", context)}.
				{!translation.subtitle && checkRole(2) && TranslationField(translation, "subtitle", context)}
				{TranslationField(translation, "text", context)}
			</Col>
			<Col lg={2}>
				{checkRole(2) ? <>
					<ToolTip text="Break Chapter">
						<span onClick={breakChapter} className="primary-button small-button">
							<i className="fas fa-file-dashed-line"></i>
						</span>
					</ToolTip>
					<ToolTip text="Merge with prior">
						<span onClick={mergeTranslation} className="primary-button small-button">
							<i className="fas fa-merge"></i>
						</span>
					</ToolTip>
					<ToolTip text="Insert Before">
						<span onClick={insertTranslation} className="primary-button small-button">
							<i className="fas fa-add"></i>
						</span>
					</ToolTip>
					<ToolTip text="Delete translation">
						<span onClick={deleteTranslation} className="primary-button small-button">
							<i className="fas fa-trash"></i>
						</span>
					</ToolTip>
				</> : ""}
				<NoteForm foreign_class="translation" foreign_id={translation.translation_id} context={context} />
				<NoteModal notes={translation.notes} context={context} />
				{TranslationField(translation, "commentary", context)}
			</Col>
		</Row>

	</div>
}

export default Translation
