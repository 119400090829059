import React, { useContext } from "react"
import { bookProgressStatus } from "helpers/books_helper"
import { PrintTranslations } from "helpers/translation_helper"
import { BookReaderContext } from "../../../context"

const TranslationStatus = () => {
	const { book } = useContext(BookReaderContext)

	return <div>
		<h3>Translation Status</h3>
		{bookProgressStatus(book)}
		<PrintTranslations translations={book.untranslated} />
	</div>

}

export default TranslationStatus
