import MenuBody from "./MenuBody"
import { DastContext } from "../../context"
import React, { useContext } from "react"

function MenuBackground() {

	const {setShowMenu} = useContext(DastContext)

	return <>
		<div className="dast-menu-background" onClick={(e: any) => {
			if(e.target.classList.contains("dast-menu-background")) { setShowMenu(false)}
		}}>
			<MenuBody  />
		</div>
	</>
}

export default MenuBackground
