import React from "react"
import { Link } from "react-router-dom"
import { checkRole } from "helpers/helpers"

import { CategoryEditorFields, CategoryType } from "types/categories"
import { CategoryField } from "helpers/category_helper"
import DynamicField from "components/UI/DynamicFormField/DynamicField"

import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"
import { Col, Row } from "react-bootstrap"
import { FField } from "helpers/item_helper"

interface CategoryProps {
	category: CategoryType;
	categories: CategoryType[];
	context: {
		loadPage: () => void;
	}
}

const MainInfo = ({ category, context, categories }: CategoryProps) => {
	const parentOptions = categories.map(c => { return { name: c.name, id: c.category_id } })
	const parent = categories.find(c => c.category_id === category.parent_id)
	category.id = category.category_id

	return <Row>
		<Col lg={3}>
		</Col>
		<Col lg={6}>
			{parent ? <Link className="primary-button secondary-button small-button" to={`/categories/${parent.name}`}>Part of the {parent.name}</Link> : ""}
			<h1>
				<DynamicField
					item={category}
					path={"categories"}
					context={context}
					autosave={true}
					field={FField("name", CategoryEditorFields)}
					callbacks={{
						success: (value) => { window.location.href = `/categories/${value}` }
					}}
				/>
			</h1>




			<p>{CategoryField(category, "short_description", context)}</p>
		</Col>
		<Col lg={3}>
			<NoteForm foreign_class="category" foreign_id={category.category_id} context={context} />
			<NoteModal notes={category.notes} context={context} />

			{checkRole(2) && (<>
				<DynamicField
					item={category}
					path={"categories"}
					context={context}
					autosave={true}
					display="Change Parent"
					field={{
						key: "parent_id",
						title: "parent",
						description: "",
						example: "",
						kind: "id-select",
						options: { valueArray: parentOptions }
					}}
				/>
				<br />
				<Link to={`/print/category?category_id=${category.category_id}`}>Print</Link>
			</>)}
		</Col>
		<p>{CategoryField(category, "long_description", context)}</p>
	</Row>


}

export default MainInfo
