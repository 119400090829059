import React, { useState } from "react"
import "./index.scss"

import { createApi } from "helpers/api_helper"

const AddBookButton = ({context}: {context: any}) => {
	const [newBookName, setNewBookName] = useState("")

	return <>
		<h3>Add Book</h3>
		<input value={newBookName} onChange={(e) => setNewBookName(e.target.value)} />
		<span onClick={() => createApi("/books", { name: newBookName, full_name: newBookName }, context)}>Create</span>
	</>

}

export default AddBookButton
