import React, { useEffect, useState } from "react"
import "./index.scss"
import { Container, Row, Col } from "react-bootstrap"

import BookComponent from "./components/Book"
import { groupProgressComponent } from "helpers/books_helper"
import { indexApi } from "helpers/api_helper"
import { BookType } from "types/books"
import { loadingSpinner } from "siteSettings"
import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {

	const [books, setBooks] = useState<BookType[]>([])
	const [sort, setSort] = useState("order")
	const [sortOrder, setSortOrder] = useState(1)
	const [filter, setRawFilter] = useState("")
	const setFilter = (data: string) => {
		setRawFilter(data.toLowerCase())
	}

	useEffect(() => { loadPage() }, [])
	const loadPage = () => { indexApi("books", setBooks) }

	const options = [
		["original_verse_count", "Length"],
		["order", "Order"],
		["abridged_percent", "Has Abridged"],
		["setting_start_year", "Year Set"],
		["start_year", "Year Written"],
	]
	const sortOptions = [
		[1, "Asc"],
		[-1, "Desc"],
	]


	const sortedBooks = books.filter(book => {
		if(filter) return book.name?.toLowerCase().search(filter) >= 0 || book.full_name?.toLowerCase().search(filter) >= 0 || book.alternate_names?.toLowerCase().search(filter) >= 0
		return true
	}).sort((a, b) => {
		const processedA = parseInt(`${a[sort as keyof BookType] || 100000}`)
		const processedB = parseInt(`${b[sort as keyof BookType] || 100000}`)
		return (sortOrder * (processedA - processedB))
	})

	return <div id="books-index-view">
		<MetaTags 
			title={"Abridged Bible Books"} 
			description={"A list of all abridged religious books in our database."} 
			url="books" />
		<br />
		<br />
		<h1>Bible Book Database</h1>
		<p>List of all books.</p>

		<div className="book-sort-banner">
			<span>Sort</span>&nbsp;
			<select onChange={(e) => setSort(e.target.value)}>
				{options.map(o => <option key={o[0]} value={o[0]} selected={o[0] === sort}>{o[1]}</option>)}
			</select>&nbsp;

			<select onChange={(e) => setSortOrder(Number.parseInt(e.target.value))}>
				{sortOptions.map(o => <option key={o[0]} value={o[0]} selected={o[0] === sort}>{o[1]}</option>)}
			</select>&nbsp;

			<input placeholder="Search Term..." onChange={(e) => {setFilter(e.target.value)}} />
		</div>


		{books.length === 0 ? loadingSpinner :
			<>
				<Container className="book-outer-container">
					<Row className="book-index-columns">
						{
							sortedBooks.map(book =>
								<Col key={book.book_id} xs={12} lg={4}>
									<BookComponent key={book.book_id} book={book} />
								</Col>
							)}
					</Row>
				</Container>

				{groupProgressComponent(books)}
			</>
		}
	</div>

}

export default Index
