import { convertNewLinesToLineBreaks, printDateTime } from "helpers/helpers"
import { DynamicFieldContextType } from "../types"

const FieldDisplay = ({ dynamicFieldContext }: { dynamicFieldContext: DynamicFieldContextType }) => {
	const { display, item, field, blankDisplay } = dynamicFieldContext
	const valueArray = field.options?.valueArray
	
	if (display) return display

	//First check for boolean - there's too much specificity between null/false
	if (field.kind === "boolean") {
		const values = valueArray || ["No", "Yes", "Select"]
		if (item[field.key] === false) { return values[0] }
		if (item[field.key] === true) { return values[1] }
		if (item[field.key] === true) { return values[2] }
	}


	if (item[field.key] || item[field.key] === 0) {
		if (field.kind === "password") { return "****" }
		if (field.kind === "text") { return convertNewLinesToLineBreaks(item[field.key]) }
		if (field.kind === "date") { return printDateTime(item[field.key]) }

		if (valueArray) {
			if (field.kind === "enum") { return valueArray[item[field.key]] }
			if (field.kind === "id-select") { return valueArray.find(o => o.id === item[field.key]).name }
		}
		else return item[field.key]
	}
	else {
		if (blankDisplay) return blankDisplay
		else return field.key
	}
}

export default FieldDisplay