import React from "react"
import { Link } from "react-router-dom"

import { curr_user } from "helpers/helpers"
import { UserType } from "types/user"
import { createApi, deleteApi } from "helpers/api_helper"
import { Col, Row } from "react-bootstrap"


interface UserCardProps {
	user: UserType
	loadPage: () => void
}

const UserCard = ({ user, loadPage }: UserCardProps) => {

	const banUser = () => { createApi("users/ban", { user_id: user.user_id }, { loadPage }) }
	const unbanUser = () => { createApi("users/unban", { user_id: user.user_id }, { loadPage }) }

	const promoteUser = () => { createApi("users/promote", { user_id: user.user_id, user_role: 1 }, { loadPage }) }
	const promoteMod = () => { createApi("users/promote", { user_id: user.user_id, user_role: 2 }, { loadPage }) }
	const promoteAdmin = () => { createApi("users/promote", { user_id: user.user_id, user_role: 3 }, { loadPage }) }

	const deleteUser = () => { deleteApi("users", user.user_id, { loadPage }, user.username) }



	let buttons = <></>

	if (user.user_verified && user.user_id !== curr_user.user_id && user.user_role < curr_user.user_role) {
		if (user.user_role === 1) { buttons = <span onClick={promoteMod}>Promote Mod</span> }
		if (user.user_role === 2) {
			buttons = <>
				<span className="format-link" onClick={promoteUser}>Demote User</span>
				<span className="format-link" onClick={promoteAdmin}>Promote Admin</span>
			</>
		}
		if (user.user_role === 3) {
			<span className="format-link" onClick={promoteMod}>Demote Mod</span>
		}
	}

	return <Row className="user-list-card">

		<Col lg={2}>
			<Link to={`/users/${user.user_id}`}>{user.username}</Link>
		</Col>

		<Col lg={2}>
			{user.user_email}
		</Col>

		<Col lg={2}>
			{user.user_verified === true ? ["", "User", "Mod", "Admin"][user.user_role] : ""}
			{user.user_verified === false ? "Banned" : ""}
			{user.user_verified === null ? "Unverified" : ""}
		</Col>

		<Col lg={2}>
			{user.user_verified === false ? <span className="format-link" onClick={unbanUser}>Unban</span> : ""}
			{user.user_verified === true ? <span className="format-link" onClick={banUser}>Ban</span> : ""}
			{user.user_verified === null ? <span className="format-link" onClick={banUser}>Ban</span> : ""}
		</Col>

		<Col lg={2}>
			{buttons}
		</Col>

		<Col lg={2}>
			{user.user_id !== curr_user.user_id && user.user_role <= curr_user.user_role ?
				<span className="format-link" onClick={deleteUser}>Delete</span> : ""}
		</Col>
	</Row>
}

export default UserCard
