import React from "react"

import { BookTranslationVersionType, TranslationType, TranslationVersionType } from "types/translationVersions"
import Translation from "./Translation"
import { createApi, updateApi } from "helpers/api_helper"

interface TranslationVersionProps {
	translations: TranslationType[]
	context: {
		loadPage: () => void
		translationVersions: TranslationVersionType[]
	}
	translationVersion: BookTranslationVersionType
}

const Translations = ({ translations, context, translationVersion }: TranslationVersionProps) => {

	let currentChapter = -1

	const printChapter = (translation: TranslationType) => {
		const chapter = translation.chapter
		if (chapter !== currentChapter) {
			currentChapter = chapter
			return <div>
				{chapter > 1 && <div className="primary-button" onClick={() => appendTranslation(chapter - 1)}>Append Verse</div>}
				<div className="primary-button" onClick={() => mergeChapter(translation)}>Merge Chapter</div>
				<h3>Chapter {chapter}</h3>
			</div>
		} else { return "" }
	}

	const appendTranslation = (chapter: number) => {
		const lastTranslationInChapter = translations.filter(t => t.chapter === chapter).sort((a, b) => b.verse - a.verse)[0]
		const data = {
			translation_version_id: translationVersion.translation_version_id,
			book_id: translationVersion.book.book_id,
			verse: lastTranslationInChapter?.verse + 1 || 1,
			chapter
		}
		createApi("translations", data, context)
	}
	
	const mergeChapter = (translation: TranslationType) => {
		updateApi("translations/mergeChapter", translation.translation_id, {}, context)
	}

	return <div>

		{translations.map(translation => <>
			{printChapter(translation)}
			<Translation key={translation.translation_id} translation={translation} context={context} />
		</>)}

		<div className="primary-button d-block m-4" onClick={() => appendTranslation(translations[translations.length - 1].chapter)}>Append Verse</div>
		<div className="primary-button d-block m-4" onClick={() => appendTranslation(translations[translations.length - 1].chapter + 1)}>New Chapter</div>
	</div>

}

export default Translations
