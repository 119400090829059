import { ContactMessageType } from "types/contactMessage"

export const contact_kind_options = [
	"Feedback",
	"Issue/Help",
	"Bug Report",
	"Other",
]

export const print_contact_kind = (contactMessage: ContactMessageType) => {
	if (contactMessage.kind == null) return null
	return contact_kind_options[contactMessage.kind]
}

export const contact_status_options = [
	"Unread",
	"Read",
	"Handled",
	"Saved",
	"Deleted"
]

export const print_contact_status = (contactMessage: ContactMessageType) => {
	if (contactMessage.status == null) return null
	return contact_status_options[contactMessage.status]
}

export const print_created_time = (contactMessage: ContactMessageType) => {
	const dateObj = new Date(contactMessage.created_at)
	return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear() - 2000} ${dateObj.getHours()}:${dateObj.getMinutes()}`
}
