import React from "react"
import Book from "./Book/Book"
import { BookContext } from "../bookContext"
import { FullBookType } from "types/books"
import { useSearchParams } from "react-router-dom"


const Books = ({ books }: { books: FullBookType[] }) => {
	const [searchParams] = useSearchParams()
	const title = searchParams.get("title") || ""
	return <>
		<div className="print-content">
			{books.length > 1 ? <>
				{title && <>
					<h1>{title}</h1>
					<div className="pagebreak"></div>
				</>}
				<h2>Table of Contents</h2>
				{
					books.map((book, i) => <div className="toc-book" key={book.book_id}>
						<a href={`#book-${book.book_id}`}>
							#{i + 1}. The {book.full_name}
						</a>
					</div>)
				}
				<div className="pagebreak"></div>
			</> : ""}

			{books.map(book =>
				<BookContext.Provider key={book.book_id} value={{ book }}>
					<div id={`book-${book.book_id}`}>
						<div id="top-center-display"> </div>
						<Book />
					</div>
				</BookContext.Provider>
			)}
		</div>
	</>
}

export default Books
