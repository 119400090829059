import React from "react"
import { ContactMessageType } from "types/contactMessage"
import { contact_status_options, print_contact_kind, print_created_time } from "helpers/contact_helper"
import { Col, Row } from "react-bootstrap"
import DynamicField from "components/UI/DynamicFormField/DynamicField"

interface ContactMessageProps {
	item: ContactMessageType
	context: { loadPage: () => void }
}

const ContactMessageComponent = ({ item, context }: ContactMessageProps) => {
	if (!item) return ""
	item.id = item.contact_message_id

	return <div className="contactMessage-item align-left">
		<Row>
			<Col xs={6} lg={1}>
				<DynamicField  item={item} path={"contact"} field={{key: "status", title: "status", description: "", example: "", kind: "enum", options: { valueArray: contact_status_options } }} context={context} autosave={true} />
			</Col>
			<Col xs={6} lg={1}>
				{print_contact_kind(item)}
			</Col>
			<Col lg={2}>
				{item.name || "Anonymous"} {item.email ? ` (${item.email})` : ""}
			</Col>
			<Col lg={7}>
				{item.text}
			</Col>
			<Col lg={1}>
				{print_created_time(item)}
			</Col>
		</Row>
	</div>
}

export default ContactMessageComponent
