import React from "react"
import { Link } from "react-router-dom"

import { Container } from "react-bootstrap"
import { BookTranslationVersionType, TranslationVersionType } from "types/translationVersions"
import { deleteApi, updateApi } from "helpers/api_helper"
import TranslationImport from "./TranslationImport"
import { checkRole } from "helpers/helpers"
import AIGenerateButton from "./AIGenerate/AIGenerate"
import Translations from "./Translations"

interface TranslationVersionProps {
	translationVersion: BookTranslationVersionType
	context: {
		loadPage: () => void
		translationVersions: TranslationVersionType[]
	}
}

const TranslationVersion = ({ translationVersion, context }: TranslationVersionProps) => {
	if (!translationVersion.translation_version_id) return ""
	translationVersion.id = translationVersion.translation_version_id

	const translations = translationVersion.translations.sort((a, b) => {
		const processedA = (a.chapter * 1000) + a.verse
		const processedB = (b.chapter * 1000) + b.verse
		return processedA - processedB
	})

	const deleteAllTranslations = () => {
		deleteApi("translation_versions/book",
			`${translationVersion.translation_version_id}/${translationVersion.book.book_id}`,
			context,
			`translations for ${translationVersion.book.full_name}`)
	}

	const transferBook = (e: any) => {
		const from_id = translationVersion.translation_version_id
		const to_id = e.target.value
		const book_id = translationVersion.book.book_id
		updateApi(`translation_versions/transfer/${from_id}/${to_id}`, book_id, {}, context)
		window.location.href = `/translations/${to_id}/${book_id}`
	}


	const transferOptions = context.translationVersions.filter(tv => tv.translation_version_id !== translationVersion.translation_version_id)

	return <Container>
		<div style={{ padding: "5rem 0" }}>
			<Link to={`/translations/${translationVersion.translation_version_id}`}>Translation Information</Link>&nbsp;|&nbsp;
			<Link to={`/books/${translationVersion.book.book_id}`}>Book Information</Link>
			<br />

			<h1>{translationVersion.book.full_name} - {translationVersion.name}</h1>
		</div>

		{!translationVersion.public_domain && <div><b><i>This translation is not in the public domain, therefore it is not avilable.</i></b></div>}

		<Translations translations={translations} context={context} translationVersion={translationVersion} />

		{!checkRole(2) ? "" :
			<div>

				<hr />
				<div className="p-5">
					<TranslationImport context={context} translationVersion={translationVersion} />
				</div>
				<hr />
				<div className="p-5">
					<h3>Transfer To Different Version</h3>
					<select onChange={transferBook}>
						{transferOptions.map(tv =>
							<option key={tv.translation_version_id} value={tv.translation_version_id}>
								{tv.name}
							</option>)}
					</select>
				</div>
				<hr />

				<div className="p-5">
					<AIGenerateButton translation_id={translationVersion.translation_version_id} book_id={translationVersion.book.book_id} translationVersion={translationVersion} />
				</div>
				<hr />

				<div className="p-5">
					<span className="primary-button" onClick={deleteAllTranslations}>Delete ALL Translations</span>
				</div>
			</div>}
	</Container>


}

export default TranslationVersion
