
import { DastContext } from "../../context"
import React, { ReactElement, useContext } from "react"
import ToolTip from "components/UI/ToolTip"
import { FormFieldType } from "types/forms"

function TableAxisXCell({ field }: {field: FormFieldType}) {
	const {
		toggleField, toggleSort, options, sortField, sortDirection
	}= useContext(DastContext)

	let display: string | ReactElement = field.title
	if (options.fieldHeaderDisplay) { display = options.fieldHeaderDisplay(field) }

	if(field.description) {
		display = <ToolTip text={field.description} placement="bottom">{display}</ToolTip>
	}

	return <div key={field.title} className="data-cell" style={{width: field.options?.width || options.cellWidth || "100px"}}>
		<div>
			<div>
				<span className="format-link d-inline-block" onClick={() => toggleField(field.key)}>Hide</span>
			</div>
			<br />
			<div>
				<span className="format-link d-inline-block" style={{padding:".5rem"}} onClick={() => toggleSort(field.key)}>
					{
						sortField === field.key ? (sortDirection === 1 ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i> ) : ""
					} 
					{display}
				</span>
			</div>
		</div>
	</div>

}

export default TableAxisXCell
