import React, {useContext} from "react"
import { Link } from "react-router-dom"
import { BookReaderContext } from "../../../context"
import { BookType } from "types/books"
import "./BottomNavigation.scss"
import { Row, Col} from "react-bootstrap"

const BottomNavigation = () => {
	const { book } = useContext(BookReaderContext)

	const {previous, next} = book.navigation

	const format = (b: BookType, title: string) => {
		return <div>
			{title}
			<Link className="primary-button d-block" to={`/books/${b.book_id}`}>{b.name}</Link>
		</div>
	}

	return <div>
		
		<div className="bottom-the-end">THE END</div>
		<Row className="bottom-book-nav">
			<Col md={4}>
				{ previous ? format(previous, "Previous") : "" }
			</Col>
			<Col md={4}>
					Back to All
				<Link className="primary-button d-block" to={"/books"}>
					All Books
				</Link>
			</Col>
			<Col md={4}>
				{ next ? format(next, "Next") : "" }
			</Col>
		</Row>

		<div onClick={() => window.scrollTo(0,0) }>Back To Top</div>
	</div>

}

export default BottomNavigation
