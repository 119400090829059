import React from "react"
import { DynamicFieldContextType } from "../types"

const FieldForm = ({ dynamicFieldContext }: { dynamicFieldContext: DynamicFieldContextType }) => {
	const { value, setValue, autosave, changed, save, setEditing, field, autoSavetimeout } = dynamicFieldContext
	const valueArray = field.options?.valueArray

	const autoSaveOnBlur = () => {
		clearTimeout(autoSavetimeout)
		if (autosave && changed) { save() }
		setTimeout(() => { setEditing(false) }, 250)
	}

	if (field.kind === "string" && !field.options?.textbox) {
		return <>
			<input
				autoFocus={!dynamicFieldContext.options?.formOnly}
				spellCheck={true}
				value={value || ""}
				onChange={(e) => { setValue(e.target.value) }}
				onBlur={autoSaveOnBlur}

				size={field.options?.widthSize}
			/>
			{ value && value.length && <span>&nbsp;{value.length} / 255</span> }
		</>
	}

	if (field.kind === "string" && field.options?.textbox) {
		return <>
			<textarea
				autoFocus={!dynamicFieldContext.options?.formOnly}
				spellCheck={true}
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value)
				}}
				onBlur={autoSaveOnBlur}

				style={{ width: "100%" }}
			/>
			{ value && value.length && <span>&nbsp;{value.length} / 255</span> }
		</>
	}

	if (field.kind === "text") {
		return <textarea
			autoFocus={!dynamicFieldContext.options?.formOnly}
			spellCheck={true}
			value={value || ""}
			onChange={(e) => {
				setValue(e.target.value)
			}}
			onBlur={autoSaveOnBlur}

			style={{ width: "100%" }}
		/>
	}

	if (field.kind === "enum") {
		return <select
			autoFocus={!dynamicFieldContext.options?.formOnly}
			onChange={(e) => {
				if (e.target.value === "-1") { setValue(null) }
				else { setValue(e.target.value) }
			}}
			onBlur={autoSaveOnBlur}
			value={!value ? "-1" : value}
		>

			{field.options?.required ? "" : <option value="-1"></option>}
			{valueArray?.map((o: string, i: number) => <option value={i} key={i + o}>{o}</option>)}

		</select>
	}

	if (field.kind === "boolean") {
		return <select
			autoFocus={!dynamicFieldContext.options?.formOnly}
			onChange={(e) => {
				if (e.target.value === "null") { setValue(null) }
				if (e.target.value === "true") { setValue(true) }
				if (e.target.value === "false") { setValue(false) }
			}}
			onBlur={autoSaveOnBlur}
			value={ value === true ? "true" : (value === false ? "false" : "null") }
		>
			{field.options?.required ? "" : <option value="null">?</option>}
			<option value="true">True</option>
			<option value="false">False</option>
		</select>
	}

	if (field.kind === "id-select") {
		return <select
			autoFocus={!dynamicFieldContext.options?.formOnly}
			onChange={(e) => {
				if (e.target.value === "-1") { setValue(null) }
				else { setValue(e.target.value) }
			}}
			onBlur={autoSaveOnBlur}
			value={!value ? "-1" : value}
		>
			{field.options?.required ? "" : <option value="-1">none</option>}
			{valueArray?.map((option: { id: number, name: string }) => <option key={option.id} value={option.id}>
				{option.name}
			</option>)}
		</select>
	}



	//number, password
	return <input
		autoFocus={!dynamicFieldContext.options?.formOnly}
		spellCheck={true}
		value={value || ""}
		onChange={(e) => { setValue(e.target.value) }}
		onBlur={autoSaveOnBlur}

		size={field.options?.widthSize}
	/>

}

export default FieldForm