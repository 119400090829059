import React, { useEffect } from "react"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { TranslationType, TranslationVersionType } from "types/translationVersions"
import { FormFieldType } from "types/forms"
import { useSearchParams } from "react-router-dom"
import { scrollToId } from "./helpers"


export const PrintTranslations = ({translations}: {translations: TranslationType[]}) => {
	return <div>
		{translations.map(translation =>
			<PrintTranslation key={translation.translation_id} translation={translation} />
		)}
	</div>
}

export const PrintTranslation = ({translation}: {translation: TranslationType}) => {
	const [searchParams] = useSearchParams() //controls search term with url
	const chapter = searchParams.get("chapter")
	const verse = searchParams.get("verse")
	const scrollPosition = {chapter: chapter ? parseInt(chapter) : null, verse: verse ? parseInt(verse) : null}
	useEffect(() => {
		if (scrollPosition.chapter && scrollPosition.verse && //both target_chapter & target_verse exist
			scrollPosition.chapter === translation.chapter &&
			scrollPosition.verse === translation.verse
		) {
			scrollToId(`translation-${translation.translation_id}`)
		}
		if (scrollPosition.chapter && !scrollPosition.verse && //only chapter exists
			scrollPosition.chapter === translation.chapter &&
			1 === translation.verse
		) {
			scrollToId(`translation-${translation.translation_id}`)
		}
	}, [])
	return <div className="align-left translation-line" id={`translation-${translation.translation_id}`}>
		{translation.chapter}:{translation.verse}. {translation.text}
	</div>
}

export const bible_version_text = ["Individual Translation", "Full Bible Version"]
export const bible_version_print = (translation: TranslationVersionType) => {
	if (translation.bible_version === null || translation.bible_version === undefined) { return "?" }
	return bible_version_text[translation.bible_version ? 1 : 0]
}

export const public_domain_text = ["Copyrighted", "Public Domain"]
export const public_domain_print = (translation: TranslationVersionType) => {
	if (translation.public_domain === null || translation.bible_version === undefined) { return "?" }
	return public_domain_text[translation.public_domain ? 1 : 0]
}

export const TranslationVersionEditorFields: FormFieldType[] = [
	{
		key: "name",
		title: "name",
		description: "",
		example: "",
		kind: "string",
	},
	{
		key: "abbreviation",
		title: "abbreviation",
		description: "",
		example: "",
		kind: "string",
	},
	{
		key: "description",
		title: "description",
		description: "",
		example: "",
		kind: "string",
		options: { textbox: true }
	},
	{
		key: "history",
		title: "history",
		description: "",
		example: "",
		kind: "text",
	},
	{
		key: "link",
		title: "link",
		description: "",
		example: "",
		kind: "string",
	},
	{
		key: "public_domain",
		title: "public_domain",
		description: "",
		example: "",
		kind: "boolean",
		options: { valueArray: public_domain_text }
	},
	{
		key: "bible_version",
		title: "bible_version",
		description: "",
		example: "",
		kind: "boolean",
		options: { valueArray: bible_version_text }
	},
]

export const TranslationEditorFields: FormFieldType[] = [
	{
		key: "chapter",
		title: "chapter",
		description: "",
		example: "",
		kind: "number",
		options: { widthSize: 3 }
	},
	{
		key: "verse",
		title: "verse",
		description: "",
		example: "",
		kind: "number",
		options: { widthSize: 3 }
	},
	{
		key: "text",
		title: "text",
		description: "",
		example: "",
		kind: "text",
	},
	{
		key: "commentary",
		title: "commentary",
		description: "",
		example: "",
		kind: "text",
	},
	{
		key: "subtitle",
		title: "subtitle",
		description: "",
		example: "",
		kind: "string",
	},
]

export const TranslationField = (translation: any, fieldname: any, context: any) => {
	const field = TranslationEditorFields.find(f => f.key === fieldname)
	if (field) {
		return <DynamicField item={translation} path={"translations"} context={context} autosave={true} field={field} />
	}
}

export const TranslationVersionField = (translationVersion: any, fieldname: any, context: any) => {
	const field = TranslationVersionEditorFields.find(f => f.key === fieldname)
	if (field) {
		return <DynamicField item={translationVersion} path={"translation_versions"} context={context} autosave={true} field={field} />
	}
}