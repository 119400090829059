import React, { useState, useContext, ChangeEvent } from "react"
import { createApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { Col, Row } from "react-bootstrap"


const VerseImport = () => {
	const context = useContext(BookEditorContext)
	const { book } = context

	const [verseText, setVerseText] = useState("")
	const [errors, setErrors] = useState<number[]>([])

	const callImport = () => {
		createApi(`import/verses/${book.book_id}`, { verses: verseText }, context)
	}

	const validate = (value: string) => {
		const lines = value.split("\n")
		const failures: number[] = []
		lines.forEach((line, i) => {
			try {
				const parts = line.split(". ")
				const numbers = parts[0].split(":")
				const text = parts.slice(1).join(". ")
				const chapter = numbers[0]
				const verses = numbers[1].split("-")
				const start_verse = verses[0]
				const end_verse = verses[1] ? verses[1] : verses[0]
				if (
					!text || 
					!chapter || `${parseInt(chapter)}` !== chapter ||
					!start_verse || `${parseInt(start_verse)}` !== start_verse || 
					!end_verse || `${parseInt(end_verse)}` !== end_verse
				) { failures.push(i + 1) }
			} catch {
				failures.push(i)
			}
		})
		setErrors(failures)
	}

	const textChange = (e: ChangeEvent) => {
		const target = e.target as HTMLInputElement
		validate(target.value)
		setVerseText(target.value)
	}


	return <div className="align-left p-5">

		<h3>Mass Verse Upload</h3>
		<Row>
			<Col lg={3}>
				-One verse per newline<br />
				-Formatted:<br />
				1:1. Text<br />
				1:2. Text<br />
				1:4-5. Text<br />
				2:1. Text<br />
				2:2. Text<br />
				2:3. Text<br />
				<br />

				{
					errors.length > 0 ?
						`Error(s) on line(s): ${errors.join(",")}` :
						<span className="primary-button" onClick={callImport}>Submit</span>
				}
				
			</Col>
			<Col lg={9}>
				<textarea value={verseText} style={{ width: "100%", height: "300px" }} onChange={textChange} />
			</Col>
		</Row>
	</div>

}

export default VerseImport
