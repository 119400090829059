import React, { useEffect, useState } from "react"
import "./index.scss"

import ContactMessage from "./ContactMessage"
import { indexApi } from "helpers/api_helper"
import { ContactMessageType, ContactMessageFormFields } from "types/contactMessage"


import MetaTags from "components/UI/MetaTags/metaTags"
import Lister from "components/UI/Lister/Lister"
import { Container } from "react-bootstrap"

const Index = () => {
	<MetaTags title={"Admin Inbox"} url="contact/all" privatePage />
	const [contactMessages, setContactMessages] = useState<ContactMessageType[]>([])

	useEffect(() => { loadPage() }, [])
	const loadPage = () => { indexApi("contact", setContactMessages) }
	const context = { loadPage }

	return <div id="contactMessages-view">

		<div style={{ padding: "4rem" }}>
			<h1>Contact Messages</h1>
			<p>What the people are saying:</p>
		</div>
		<div className="book-sort-banner"></div>

		<Container>
			<Lister
				items={contactMessages}
				ItemComponent={ContactMessage}
				FormFields={ContactMessageFormFields}
				context={context}
				options={{ defaultSortField: "created_at", defaultSortOrder: -1 }}
				search={["name", "email", "text"]}
				filters={["kind", "status"]}
				sort={["created_at"]}
			/>
		</Container>
	</div>

}

export default Index
