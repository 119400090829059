import React, { useState, useContext } from "react"
import { itemApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { smallLoadingSpinner } from "siteSettings"
import { ChapterType } from "types/chapter"
import ToolTip from "components/UI/ToolTip"

const SummaryImport = ({ chapter }: { chapter: ChapterType }) => {
	const context = useContext(BookEditorContext)

	const [loading, setLoading] = useState(false)

	const callback = () => {
		setLoading(false)
		context.loadPage()
	}

	const callImport = () => {
		setLoading(true)
		itemApi("converter/chapterSummary", chapter.chapter_id, callback)
	}

	return <span className="primary-button small-button" onClick={callImport}>
		{loading ? smallLoadingSpinner :
			<ToolTip text="AI-generate summary and name for chapter">
				<i className="fas fa-brain-circuit"></i>
			</ToolTip>}
	</span>

}

export default SummaryImport
