import React from "react"
//import jwt from 'jsonwebtoken'
import { apiPathLink } from "siteSettings"

export const apiPath = apiPathLink + "/api"

export const curr_user = localStorage.user ? JSON.parse(localStorage.user) : false
export const token = localStorage.token
export const headers = { headers: { "authorization": localStorage.token } }

export const checkRole = (role: number) => {
	if(role === -1) return !curr_user
	if(role === 0) return true
	if(!curr_user) return false
	return curr_user.user_role >= role
}

export const logoutCookies = () => {
	localStorage.removeItem("user")
	localStorage.removeItem("token")
}
export const loginCookies = (user: any, token: string) => {
	localStorage.setItem("user", JSON.stringify(user))
	localStorage.setItem("token", token)
}

export const expireTokenCheck = () => {
	// //Decode the local token
	// var decodedToken = jwt.decode(localStorage.token, { complete: true })

	// var dateNow = new Date()
	// //JWT stored in seconds, not milliseconds for some reason, so x 1000
	// var expirationDate = new Date(decodedToken.payload.exp * 1000)

	// if (expirationDate.getTime() < dateNow.getTime()) { return true }
	// else {return false}

	return false
}

export const convertNewLinesToLineBreaks = (text: string) => {
	return text?.split("\n").map(l => <>{l}<br /></>)
}

export const printDateTime = (date: number) => {
	const dateObj = new Date(date)
	return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear() - 2000} ${dateObj.getHours()}:${dateObj.getMinutes()}`
}


export const scrollToId = (id: string, e?: React.MouseEvent) => {
	e?.preventDefault()
	const element = document.getElementById(`${id}`)
	if(element) {
		window.scrollTo({
			top: element.offsetTop - 120,
			behavior: "smooth"
		})
	}
}