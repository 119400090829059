import React, { useContext, useEffect } from "react"
import { checkRole, curr_user } from "helpers/helpers"
import { Link } from "react-router-dom"
import MetaTags from "components/UI/MetaTags/metaTags"

import { RedditCallback, RedditClient } from "siteSettings"
import { useSearchParams } from "react-router-dom"
import { AppContext } from "structure/AppContext"
import DefaultTranslationSetting from "./AppDefaultTranslationSetting"
import { Container } from "react-bootstrap"

//user?: string, token?: string

const Profile = () => {

	const redditConnectLink = `https://www.reddit.com/api/v1/authorize?client_id=${RedditClient}&response_type=code&state=${RedditClient + curr_user.user_id}&redirect_uri=${RedditCallback}&duration=permanent&scope=identity submit mysubreddits`

	const appContext = useContext(AppContext)
	const [urlParams] = useSearchParams()

	useEffect(() => {
		const incomingUser = urlParams.get("user")
		if (incomingUser) {
			const token = urlParams.get("token")
			if (token) {
				try {
					const user = JSON.parse(incomingUser || "")
					appContext.login(user, token)
					setTimeout(() => { window.location.replace("/users/dashboard") }, 200)
				} catch {
					throw new Error("Error updating user. Logout and log back in.")
				}
			}
		}
	}, [])

	return <div style={{ paddingTop: "5rem" }}>
		<MetaTags title={"Admin Dashboard"} url={"users/dashboard"} privatePage />

		<Container>
			<h2>Welcome, {curr_user.username}</h2>
			<p>{curr_user.user_email}, {["", "User", "Mod", "Admin"][curr_user.user_role]}</p>

			<h3>Site Settings</h3>
			<DefaultTranslationSetting />

			{checkRole(2) ? <>
				<h3>Quick Links</h3>
				<Link className="primary-button" to="/books/completion">Manage Books</Link>&nbsp;
				<Link className="primary-button" to="/converter">AI Converter</Link>&nbsp;
				<Link className="primary-button" to="/contact/all">Contact Messages</Link>&nbsp;
				<Link className="primary-button" to="/notes">Notes</Link>&nbsp;
				<Link className="primary-button" to="/social">Social Posts</Link>&nbsp;
				<Link className="primary-button" to="/users">Manage Users</Link>


				<h3>Reddit Account</h3>
				<div>
					{curr_user.reddit_refresh_token ? <a className="primary-button secondary-button" href={redditConnectLink}>Successfully Connected to Reddit</a> :
						<a className="primary-button" href={redditConnectLink}>Connect to Reddit</a>
					}
				</div>


			</> : ""}
		</Container>

	</div>

}

export default Profile
