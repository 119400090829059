import React from "react"
import ControlBar from "./components/ControlBar"
import PartChapters from "./components/PartChapters"
import {convertNewLinesToLineBreaks} from "helpers/helpers"
import { PartType } from "types/parts"

const BookPart = ({ part }: {part: PartType}) => {
	if (part.book_part_id !== undefined) {
		part.id = part.book_part_id
		return <div>
			<ControlBar part={part} />
			{part.commentary ? <div>
				<h4 className="book-part-title">Commentary</h4>
				<p className="align-left">{convertNewLinesToLineBreaks(part.commentary)}</p>
			</div> : ""}
			<PartChapters part={part}  />
		</div>
	} else return ""
}

export default BookPart
