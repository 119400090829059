
import { FormItemType } from "types/forms"
import { DastContext } from "../../context"
import React, { useContext } from "react"

function TableAxisYCell({ item, itemNumber }: { item: FormItemType, itemNumber: number }) {
	const { toggleItem, options }= useContext(DastContext)

	const evenOddClass = itemNumber / 2 === Math.round(itemNumber / 2) ? "dr-even" : "dr-odd"

	let display = `${item.id}`
	if(options.itemHeaderDisplay) { display = options.itemHeaderDisplay(item) }

	return <div key={item.id} className={`data-cell ${evenOddClass}`}>
		<div>
			<div>{display}</div>
			<div onClick={() => toggleItem(item)}>[ Hide ]</div>
		</div>
	</div>
}

export default TableAxisYCell
