import { FormFieldType } from "./forms.js"
import { VerseType } from "./verse.js"
import { NoteType } from "./note.js"

export interface ChapterType {
	//Default ID Fields
	chapter_id: number
	id: number
	book_part_id: number
	book_id: number

	//Editable Fields
	name: string
	order: number
	summary: string
	commentary: string
	
	//Auto Fields
	numbers: number[]		//Auto-generated from the verses.
	numbersDetailed: any[]	//Auto-generated from the verses.
	verses: VerseType[]
	
	notes: NoteType[]

}

export const ChapterFields: FormFieldType[] = [
	{
		key: "name",
		title: "name",
		description: "",
		example: "",
		kind: "string"
	},
	{
		key: "order",
		title: "order",
		description: "",
		example: "",
		kind: "number"
	},
	{
		key: "summary",
		title: "summary",
		description: "",
		example: "",
		kind: "text"
	},
	{
		key: "commentary",
		title: "commentary",
		description: "",
		example: "",
		kind: "text"
	}
]

