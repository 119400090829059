import React from "react"
import logo from "./images/logo.png"
import loadingGif from "./images/loading.gif"
import fonts from "stylesheets/fonts.module.scss"
const {headerFont, textFont} = fonts

export const version = "b0.9.2.1"

const backendLive: string = "auto"
export const localBeLink = "http://localhost:4001"
export const liveBeLink = "https://abridged-bible-project-28cdd7753e81.herokuapp.com"
export const apiPathLink = backendLive === "auto" ? ( window.location.hostname === "localhost" ? localBeLink : liveBeLink ) : liveBeLink

export const frontEndLink = "https://abridgedbibleproject.com"

export const siteTitle = "ABP"
export const siteFullTitle = "The Abridged Bible Project"
export const logoSrc = logo
export const siteTagline = "The Abridged Bible Project"

export const googleFonts: string[] = [headerFont, textFont]
export const googleFontString = () => { return googleFonts.map( f => f.replaceAll(" ", "+").replaceAll("\"", "")).join("|") }

export const loadingSpinner = <div style={{textAlign:"center"}}><img alt="loading gif"  src={loadingGif} style={{ height: "64px", margin: "64px auto", display: "block" }} /> </div>
export const smallLoadingSpinner = <span><img alt="loading gif"  src={loadingGif} style={{ height: "24px", margin: "6px", display: "inline-block" }} /></span>

export const permissionError = <div>Sorry, you do not have permissions to view this page.</div>
export const noResultsError = <div>Sorry, there was no results returned for that search.</div>
export const missingError = <div>Sorry, that page seems to be missing.</div>

export const RedditClient = "cvg1PahFonEqtdBl3UoV6g"
export const RedditCallback = "http://localhost:4001/api/utility/authCallback"