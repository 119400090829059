import React from "react"

type PaginatorResultRangeType = {
	page: number
	paginator: {
		totalPages: number,
		count: number,
		pageSize: number
	}
	results: any[]
}

const PaginatorResultRange = ({ page, paginator, results }: PaginatorResultRangeType) => {
	if(!paginator.count || paginator.count === 0) return "No Results"
	return <>
		Results {((page - 1) * paginator.pageSize) + 1} - {(((page - 1) * paginator.pageSize)) + results.length} of {paginator.count}
	</>
}

export default PaginatorResultRange