import React from "react"
import { FormFieldType } from "types/forms"
import FieldRow from "./components/FieldRow"

const ExplanationTable = ({ fields }: {fields: FormFieldType[]}) => {
	return <div>
		{fields.map(field => FieldRow(field))}
	</div>
}

export default ExplanationTable