import { CategoryType } from "./categories.js"
import { FormFieldType } from "./forms.js"
import { NoteType } from "./note.js"
import { PartType } from "./parts.js"
import { TranslationVersionType, TranslationType } from "./translationVersions.js"

export interface BookType {
	book_id: number
	id: number
	order: number
	name: string
	full_name: string
	abbreviation: string
	alternate_names: string
	description: string
	summary: string
	history: string
	commentary: string
	start_year: number
	end_year: number
	setting_start_year: number
	setting_end_year: number
	preservation_amount: number
	author_type: number
	author: string
	has_original_translation: boolean
	original_word_count: number
	original_verse_count: number
	abridged_completed: boolean
	abridged_percent: number
	abridged_word_count: number
	abridged_verse_count: number
	notes: NoteType[]
}

export interface FullBookType extends BookType {
	categories: CategoryType[]
	parts: PartType[]
	allTranslations: TranslationVersionType[]
	untranslated: TranslationType[]
	navigation: {
		previous: BookType | null,
		next: BookType | null
	}
}

export const emptyBookObject: FullBookType = {
	book_id: 0,
	id: 0,
	order: 0,
	name: "",
	full_name: "",
	abbreviation: "",
	alternate_names: "",
	description: "",
	summary: "",
	history: "",
	commentary: "",
	notes: [],
	start_year: 0,
	end_year: 0,
	setting_start_year: 0,
	setting_end_year: 0,
	preservation_amount: 0,
	author_type: 0,
	author: "",
	has_original_translation: false,
	original_word_count: 0,
	original_verse_count: 0,
	abridged_completed: false,
	abridged_percent: 0,
	abridged_word_count: 0,
	abridged_verse_count: 0,
	categories: [],
	parts: [],
	allTranslations: [],
	untranslated: [],
	navigation: {
		previous: null,
		next: null
	}
}

export const preservation_amount_options = [
	"Completely Lost",
	"Only Fragments",
	"Partially Complete",
	"Mostly Complete",
	"Nearly Complete",
	"Fully Preserved",
]
export const author_type_options = [
	"Traditionally",
	"Forgery",
	"Pseudapigrapha",
	"Disputedly",
	"Partially",
	"Authentically",
]

export const BookFields: FormFieldType[] = [
	{
		key: "order",
		title: "Order",
		description: "Semi-arbitrary number to identify books by.",
		example: "",
		kind: "number"
	},
	{
		key: "name",
		title: "Name",
		description: "The main \"word\" associated the text.",
		example: "Genesis, Romans, Revelation",
		kind: "string"
	},
	{
		key: "full_name",
		title: "Full Name",
		description: "The full context or title of the book.",
		example: "Book of Genesis, Letter to the Romans",
		kind: "string"
	},
	{
		key: "abbreviation",
		title: "Abbreviation",
		description: "Frequently used abbreviation, though others may exist.",
		example: "Gen, Rom, Rev",
		kind: "string"
	},
	{
		key: "alternate_names",
		title: "Alternate Names",
		description: "Any other existing the names of the texts.",
		example: "Revalation, Apocaplypse of John",
		kind: "string"
	},
	{
		key: "description",
		title: "Description",
		description: "A sentence or two covering the topic of the whole book.",
		example: "",
		kind: "text"
	},
	{
		key: "summary",
		title: "Summary",
		description: "An indepth summary of the entire book.",
		example: "",
		kind: "text"
	},
	{
		key: "history",
		title: "History",
		description: "The background, creation, writing, popularlity, opinion, etc. of the book",
		example: "",
		kind: "text"
	},
	{
		key: "author",
		title: "Author",
		description: "Who it is said wrote the book.",
		example: "",
		kind: "string"
	},
	{
		key: "author_type",
		title: "Authorship Type",
		description: "What scholars and theologians think about the authorship claim.",
		example: author_type_options.join(", "),
		kind: "enum",
		options: { valueArray: author_type_options }
	},
	{
		key: "written_year",
		title: "Written Date",
		description: "The approximate dates that scholars believe the text was actually written down",
		example: "",
		kind: "placeholder"
	},
	{
		key: "start_year",
		title: "Written Start Date",
		description: "The earliest approximate year that scholars believe the text was actually written down",
		example: "",
		kind: "number"
	},
	{
		key: "end_year",
		title: "Written End Date",
		description: "The latest approximate year that scholars believe the text was actually written down",
		example: "",
		kind: "number"
	},
	{
		key: "preservation_amount",
		title: "Preservation Status",
		description: "How much of the book still survives.",
		example: preservation_amount_options.join(", "),
		kind: "enum",
		options: { valueArray: preservation_amount_options }
	},
	{
		key: "setting_year",
		title: "Setting Date",
		description: "The approximate dates that the texts take place- assuming the timeline presented by the texts",
		example: "",
		kind: "placeholder"
	},
	{
		key: "setting_start_year",
		title: "Setting Start Date",
		description: "The approximate year that the text starts- assuming the timeline presented by the texts",
		example: "",
		kind: "number"
	},
	{
		key: "setting_end_year",
		title: "Setting End Date",
		description: "The approximate year that the text ends- assuming the timeline presented by the texts",
		example: "",
		kind: "number"
	},
	{
		key: "has_original_translation",
		title: "Has Public Translation",
		description: "If there is a public domain version of the original translation available on this site.",
		example: "",
		kind: "boolean"
	},
	{
		key: "original_word_count",
		title: "Original Word Count",
		description: "Approximate word count of the original text.",
		example: "",
		kind: "number"
	},
	{
		key: "original_verse_count",
		title: "Original Verse Count",
		description: "Approximate verse count of the original text.",
		example: "",
		kind: "number"
	},
	{
		key: "abridged_completed",
		title: "Abridged Version Completed",
		description: "If there is a completed abridged version available.",
		example: "",
		kind: "boolean"
	},
	{
		key: "abridged_percent",
		title: "abridged_percent",
		description: "",
		example: "",
		kind: "number"
	},
	{
		key: "abridged_word_count",
		title: "Abridged Word Count",
		description: "The amount of words in the abridged version.",
		example: "",
		kind: "number"
	},
	{
		key: "abridged_verse_count",
		title: "Abridged Verse Count",
		description: "The amount of verses in the abridged version.",
		example: "",
		kind: "number"
	},
]

export const BookEditorFields: FormFieldType[] = [
	...BookFields
		.filter(bf => bf.kind !== "placeholder")
		.filter(bf => !["name", "full_name", "alternate_names", "abbreviation", "order", "history", "summary", "description"].includes(bf.key))
]

export const BookExplanationFields: FormFieldType[] = [
	...BookFields.filter(bf => !["start_year", "end_year", "setting_start_year", "setting_end_year", "abridged_percent"].includes(bf.key))
]



const book_completion_settings = {
	"name": { options: { width: "150px" } },
	"full_name": { options: { width: "150px" } },
	"abbreviation": { options: { width: "90px" } },
	"alternate_names": { options: { width: "250px" } },
	"description": { options: { width: "300px" } },
	"summary": { options: { width: "300px" } },
	"history": { options: { width: "300px" } },
	"author_type": { options: { width: "120px" } },
	"author": { options: { width: "120px" } },
	"preservation_amount": { options: { width: "150px" } }
}

const updateFields = (fields: FormFieldType[], settings: Record<string, any>) => {

	return fields.map(field => {
		const updatedSettings = settings[field.key]
		if (updatedSettings) return { ...field, ...updatedSettings }
		return field
	})
}

export const BookCompletionFields: FormFieldType[] = [
	...updateFields(BookFields.filter(bf => bf.kind !== "placeholder"), book_completion_settings)
]


















export const fieldInfo = [
	{
		title: "preservation_amount",
		description: "How much has the document been preserved? How much have we found, and how much is missing?",
		valueArray: [
			{
				title: "Fully Preserved",
				description: "The document has been entirely preserved. This is not a manner of accuracy of the FIRST document, but if we have a copy of it at it's height.",
				example: "The Entire Canon"
			},
			{
				title: "Nearly Complete",
				description: "The document has been found almost in it's entirety, and what we can piece together is only missing a few words.",
				example: "Gospel of Thomas"
			},
			{
				title: "Mostly Complete",
				description: "The document has been found almost in it's entirety, and what we can piece together is only missing a few verses.",
				example: ""
			},
			{
				title: "Partially Complete",
				description: "We have found around half of the document.",
				example: "Gospel of Mary"
			},
			{
				title: "Only Fragments",
				description: "We have only found fragments. In some cases, the only fragments we have are references to the work by other authors.",
				example: ""
			},
			{
				title: "Completely Lost",
				description: "We have no copies of this document, at all.",
				example: "Gospel of Eve, Ancient Book of Jasher"
			},
		]
	},
	{
		title: "author_type",
		description: "Who does it say wrote the book, and what do scholars and theologians think about it?",
		example: "",
		valueArray: [
			{
				title: "Traditional",
				description: "Tradition later claims an author but likely not written by that person, even the text doesn't really say.",
				example: "Torah, Gospels"
			},
			{
				title: "Authentic",
				description: "Authentic - Said to have been written by someone, was likely written by them.",
				example: "7 of Paul's letters"
			},
			{
				title: "Partially",
				description: "Thought to include actual ideas from the named person.",
				example: "Isaiah and the 12 Prophets"
			},
			{
				title: "Disputed",
				description: "Any grey area- Unknown author or scholarship/tradition is in debate with itself about legitimacy",
				example: "Some Letters of Paul are disputed. John 2, John 3, and Revelation all only say it's from \"John\", but tradition says it's \"The\" John."
			},
			{
				title: "Pseudepigrapha",
				description: "Though the text claims it was written by someone, it was likely not written by them. These are sincere attempt to pass off by writing in 1st person. Has textual value, done to give prestige to work, not to make themselves money.",
				example: "Timothy, Peter, James"
			},
			{
				title: "Forgery",
				description: "Said to have been written by someone, was not, and was likely only done for money.",
				example: ""
			},
		]
	}
]