import { FormFieldType } from "./forms"

export interface NewSocialPostType {
	title: string
	body: string
	kind: number
	scheduled_date: number
}

export const BlankSocialPost = {
	title: "",
	body: "",
	kind: 0,
	scheduled_date: Date.now()
}

export interface SocialPostType extends NewSocialPostType {
	social_post_id: number
	id: number
}

export const social_post_kind_options = [
	"rX Contro",
	"HetX Discussion",
	"Contradiction",
	"ProgSermon",
	"Queer",
	"Tips"
]



export const SocialPostFormFields: FormFieldType[] = [
	{
		key: "title",
		title: "Title",
		description: "",
		example: "",
		kind: "string"
	},
	{
		key: "kind",
		title: "Kind",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: social_post_kind_options }
	},
	{
		key: "scheduled_date",
		title: "Scheduled Date",
		description: "",
		example: "",
		kind: "date"
	},
	{
		key: "body",
		title: "Body",
		description: "",
		example: "",
		kind: "text"
	},
	{
		key: "user_id",
		title: "User",
		description: "",
		example: "",
		kind: "number",
		options: {hidden: true}
	},
]

export const NewSocialPostFields: FormFieldType[] = [
	...SocialPostFormFields.filter(f => !["created_at", "status"].includes(f.key))
]