import React, { useEffect, useState } from "react"

import "stylesheets/App.scss"

import Header from "structure/Header"
import Body from "structure/Body"
import Footer from "structure/Footer"

import { curr_user, expireTokenCheck, loginCookies, logoutCookies } from "../helpers/helpers"

import { AppContext } from "structure/AppContext"
import { Route, Routes } from "react-router"
import PrintComponent from "components/Printer/controller"

const App = () => {
	//Store user & token in app state so that the app rerenders on login/logout
	const [user, setUser] = useState(curr_user)
	const [token, setToken] = useState(localStorage.token)

	const initialChoice = parseInt(localStorage.getItem("translationChoice") || "1")
	const [translationChoice, rawSetTranslationChoice] = useState(initialChoice)

	//Stores translation choice in a cookie before storing in state
	const setTranslationChoice = (value: number) => {
		localStorage.setItem("translationChoice", `${value}`)
		rawSetTranslationChoice(value)
	}

	useEffect(() => {
		//This checks to see if a user's login is expired, logs them out, or just goes on. 
		if (curr_user) { if (expireTokenCheck()) { logout() } }
	}, [])

	const logout = () => {
		logoutCookies()
		setUser({})
		setToken(null)
		window.location.replace("?loggedIn=false")
	}

	const login = (incomingUser: any, incomingToken: string) => {
		loginCookies(incomingUser, incomingToken)
		setUser(incomingUser) //Setting token & user in state forces reload of App on update
		setToken(incomingToken) //Setting token & user in state forces reload of App on update
	}

	const contextValue = {
		logout,
		login,
		user,
		token,
		translationChoice,
		setTranslationChoice
	}

	return <AppContext.Provider value={contextValue}>
		<Routes>
			<Route path="/print/*" element={<PrintComponent />} />
			<Route path="/*" element={<div className="App">
				<Header />
				<Body />
				<Footer />
			</div>} />
		</Routes>
	</AppContext.Provider>

}

export { App }
