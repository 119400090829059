import React from "react"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { PartType } from "types/parts"

const ControlBar = ({ part }: { part: PartType }) => {
	return <div className="control-title-bar ctb-part">
		<div className="control-main-bar">
			<h2 className="control-bar-name">Part {part.order}{part.name ? `: ${part.name}` : ""}</h2>
			{part.summary ? <div>
				<hr />
				<p className="align-left">{convertNewLinesToLineBreaks(part.summary)}</p>
			</div> : ""}
		</div>
	</div>
}

export default ControlBar
