import { FormItemType, FormFieldType } from "types/forms"


export const FField = (key: string, fields: FormFieldType[]) => {
	const field = fields.find(f => f.key === key)
	if (!field) throw new Error("Field cannot be found.")
	return field
}

export const GeneralizeItems = (items: any[], idField: string, nameField: string) => {
	return items.map(i => ({id: i[idField], name: i[nameField]}))
}

export const sortItemsByField = (items: FormItemType[], Field: FormFieldType, sortDirection: number) => {
	const sortField = Field.key
	return items.sort((a, b) => {
		const processedA = a[sortField as keyof FormItemType] || -1000000
		const processedB = b[sortField as keyof FormItemType] || -1000000
		if (typeof processedA === "number" && typeof processedB === "number") return sortDirection * (processedA - processedB)
		else return sortDirection * `${processedA}`.localeCompare(`${processedB}`)
	})
}

export const searchItemsByFields = (items: FormItemType[], Fields: FormFieldType[], searchTerm: string) => {
	const term = searchTerm.toLowerCase()
	return items.filter(item => {
		let success = false
		Fields.map(field => {
			const itemValue = `${item[field.key]}`.toLowerCase()
			if (itemValue.match(term)) {
				success = true
			}
		})
		return success
	})
}

type FiltersType = Record<string, string>
export const filterItemsByField = (items: FormItemType[], filters: FiltersType) => {
	let sortedItems = [...items]
	Object.entries(filters).forEach(([fieldName, value]) => {
		sortedItems = sortedItems.filter(i => i[fieldName] === value)
	})
	return sortedItems
}

