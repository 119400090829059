import React, { useState } from "react"
import "./search.scss"
import SearchBooks from "./components/SearchBooks"
import SearchText from "./components/SearchText"
import { Row, Col, Container } from "react-bootstrap"
import ToolTip from "components/UI/ToolTip"
import MetaTags from "components/UI/MetaTags/metaTags"
import { useSearchParams } from "react-router-dom"

const SearchComponent = () => {
	const [searchParams] = useSearchParams() //controls search term with url
	const [panel, setPanel] = useState(searchParams.get("searchText") ? 1 : 0) //if the searchText url is set, set that box to auto-showing

	return <Container>
		<MetaTags 
			title={"Search the Bible"} 
			description={"Search the Bible and other religious texts to find specific books or phrases."}
			url="search" />
		<div style={{ padding: "5rem 0 0" }}>
			<h1>Search</h1>

			<Row>
				<Col xs={6}>
					<ToolTip text={"Search all the books to see if there's a name."}>
						<div data-toggle="tooltip" data-placement="top" title="Tooltip on top" onClick={() => setPanel(0)} className={`search-option ${panel === 0 ? "search-option-selected" : ""}`}>
							Search Books by Name
						</div>
					</ToolTip>
				</Col>
				<Col>
					<ToolTip text={"Enter a phrase to see where it shows up."}>
						<div data-toggle="tooltip" data-placement="top" title="Tooltip on top" onClick={() => setPanel(1)} className={`search-option ${panel === 1 ? "search-option-selected" : ""}`}>
							Search In The Texts
						</div>
					</ToolTip>
				</Col>
			</Row>
		</div>

		{panel === 0 ? <div>
			<SearchBooks />
		</div> : ""}
		{panel === 1 ? <div>
			<SearchText />
		</div> : ""}
	</Container>

}

export default SearchComponent
