import { contact_kind_options, contact_status_options } from "helpers/contact_helper"
import { FormFieldType } from "./forms"

export interface NewContactMessageType {
	name: string | null
	email: string | null
	text: string | null
	kind: number | null
}

export const BlankMessage = {
	name: null,
	email: null,
	text: null,
	kind: null
}

export interface ContactMessageType extends NewContactMessageType {
	contact_message_id: number
	id: number
	created_at: number
	status: number
}


export const ContactMessageFormFields: FormFieldType[] = [
	{
		key: "name",
		title: "Your Name",
		description: "Your name, if you want.",
		example: "",
		kind: "string"
	},
	{
		key: "email",
		title: "Your Email",
		description: "An email address to reach you at, if you want.",
		example: "",
		kind: "string"
	},
	{
		key: "kind",
		title: "Reason for Contacting",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: contact_kind_options, required: true }
	},
	{
		key: "status",
		title: "Status",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: contact_status_options }
	},
	{
		key: "text",
		title: "Your Message",
		description: "",
		example: "",
		kind: "text",
		options: { required: true }
	},
	{
		key: "created_at",
		title: "Created",
		description: "",
		example: "",
		kind: "date"
	},

]

export const NewContactMessageFormFields = [
	...ContactMessageFormFields.filter(f => !["created_at", "status"].includes(f.key))
]