import React, { useState, useContext } from "react"
import ChapterVerses from "./components/ChapterVerses"
import ControlBar from "./components/ControlBar"
import Dropspace from "../Dropspace"
import { ChapterType } from "types/chapter"
import { BookEditorContext } from "components/BookEditor/context"
import { ChapterField } from "helpers/chapters_helper"

import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"
import SummaryImport from "./components/SummaryImport"

const ChapterComponent = ({ chapter }: { chapter: ChapterType }) => {
	const context = useContext(BookEditorContext)
	const [open, setOpen] = useState(true)

	if (chapter.chapter_id !== undefined) {
		chapter.id = chapter.chapter_id
		return <div id={`chapter-${chapter.chapter_id}`}>
			<Dropspace kind={"chapter"} order={chapter.order-1} parent={chapter.book_part_id} />
			<ControlBar chapter={chapter} open={open} setOpen={setOpen} />
			{open ? <>
				<div>
					<NoteForm foreign_class="chapter" foreign_id={chapter.chapter_id} context={context} />&nbsp;
					<NoteModal notes={chapter.notes} context={context} />&nbsp;
					<SummaryImport chapter={chapter} />
				</div>

				{ChapterField(chapter, "summary", context)}
				{ChapterField(chapter, "commentary", context)}

				<ChapterVerses chapter={chapter} />
			</> : ""}
			<Dropspace kind={"chapter"} order={chapter.order} parent={chapter.book_part_id} />
		</div>
	} else return ""
}

export default ChapterComponent
