import TableDataItemCell from "./TableDataItemCell"

import { DastContext } from "../../context"
import React, { useContext } from "react"
import { FormItemType } from "types/forms"

function TableDataItem({ item, itemNumber }: { item: FormItemType, itemNumber: number }) {
	const { fieldSort }= useContext(DastContext)

	return <div className="item-wide">
		{
			fieldSort().map(field =>
				<TableDataItemCell key={item.id+field.key} field={field} item={item} itemNumber={itemNumber} />
			)
		}
	</div>
}

export default TableDataItem
