import React, {  useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { printChapterHeading } from "helpers/chapters_helper"
import { itemApi } from "helpers/api_helper"
import { FullBookType, emptyBookObject } from "types/books"
import { printVerse } from "helpers/verse_helper"

const Collapsable = ({ id }: { id: number }) => {
	const [open, setOpen] = useState(false)

	const [data, setData] = useState<FullBookType>(emptyBookObject)

	const loadPage = () => {
		itemApi("books/preview", id, setData)
	}

	useEffect(() => { loadPage() }, [])

    
	if (!data.book_id) return ""


	return <>
		<h3 className="collapse-main-bar" onClick={() => setOpen(!open)}>
			{data.full_name}, {printChapterHeading(data.parts[0].chapters[0])}
		</h3>
		{open ? <div className="collapse-body align-left">
			{data.parts[0].chapters[0].verses.slice(0, 12).map(v => printVerse(v))}
			<br />
			<Link className="primary-button d-block" style={{width:"fit-content"}} to={`/books/${id}`}>Read More</Link>
		</div> : ""}
	</>

}

export default Collapsable
