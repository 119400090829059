import React from "react"
import { Routes, Route } from "react-router-dom"

import Home from "components/Home/home"
import UserComponent from "components/Users/controller"
import PagesController from "components/pages/pages"
import CategoryComponent from "components/Categories/controller"
import TranslationComponent from "components/Translations/controller"
import BookReadComponent from "components/BookReader/index"
import BookEditorComponent from "components/BookEditor/index"
import BooksListComponent from "components/Books/Index/index"
import BooksDirectoryComponent from "components/Books/DirectoryList/index"
import BooksCompletionComponent from "components/Books/CompletionTable/index"
import SearchComponent from "components/Search/search"
import ContactComponent from "components/ContactMessages/controller"
import NotesComponent from "components/Notes/controller"
import SocialPostsComponent from "components/SocialPosts/controller"

import FourOhFour from "./404"

import { checkRole } from "helpers/helpers"

const Body = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>
				{/* Home & Pages */}
				<Route path="/" element={<Home />} />
				<Route path="/*" element={<PagesController />} />

				{/* Books - Main Resource */}
				<Route path="/books" element={<BooksListComponent />}  />
				<Route path="/books/directory" element={<BooksDirectoryComponent />}  />

				<Route path="/books/:id" element={<BookReadComponent />} />

				{checkRole(2) && <>
					<Route path="/books/completion" element={<BooksCompletionComponent />}  /> 
					<Route path="/books/:id/edit" element={<BookEditorComponent />} />
				</>}

				<Route path="/search" element={<SearchComponent />} />

				{/* Other Resources */}
				<Route path="/categories/*" element={<CategoryComponent />} />
				<Route path="/translations/*" element={<TranslationComponent />} />
				
				{/* Site & Admin */}
				<Route path="/contact/*" element={<ContactComponent />} />
				<Route path="/notes/*" element={<NotesComponent />} />
				<Route path="/users/*" element={<UserComponent />} />
				{checkRole(2) && <Route path="/social/*" element={<SocialPostsComponent />} /> }
				
				{/* 404 */}
				<Route path="/*" element={<FourOhFour />} />
			</Routes>
		</div>
	</div>
}

export default Body
