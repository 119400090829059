import React from "react"
import { SocialPostType } from "types/socialPost"
import { Col, Row } from "react-bootstrap"
import { SocialField } from "helpers/social_helper"
import { deleteApi, itemApi } from "helpers/api_helper"

interface SocialPostProps {
	item: SocialPostType
	context: { loadPage: () => void }
}

const SocialPostComponent = ({ item, context }: SocialPostProps) => {
	if (!item) return ""
	item.id = item.social_post_id

	const postPost = () => {
		itemApi("social_posts/post", item.id, () => {context.loadPage()})
	}

	const deletePost = () => {
		deleteApi("social_posts", item.id, context, item.title)
	}

	return <div className="socialPost-item align-left">
		<Row>
			<Col xs={10}>
				{SocialField(item, "title", context)}
			</Col>
			<Col xs={1}>
				<span className="primary-button small-button" onClick={postPost}><i className="fas fa-check"></i></span>
			</Col>
			<Col xs={1}>
				<span className="primary-button small-button" onClick={deletePost}><i className="fas fa-trash"></i></span>
			</Col>
			<Col xs={12}>
				{SocialField(item, "body", context)}
			</Col>
			<Col xs={4}>
				{SocialField(item, "kind", context)}
			</Col>
			<Col xs={4}>
				{SocialField(item, "scheduled_date", context)}
			</Col>
			<Col xs={4}>
				{SocialField(item, "user_id", context)}
			</Col>
		</Row>
	</div>
}

export default SocialPostComponent
