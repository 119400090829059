import React, { useEffect, useState } from "react"
import "./index.scss"
import { Container, Row } from "react-bootstrap"
import { checkRole } from "helpers/helpers"
import Category from "./Category"
import { createApi, indexApi } from "helpers/api_helper"
import { CategoryResponseType } from "types/categories"
import { loadingSpinner } from "siteSettings"
import MetaTags from "components/UI/MetaTags/metaTags"
import ToolTip from "components/UI/ToolTip"



const Index = () => {

	const [categories, setCategories] = useState<CategoryResponseType>({ all: [], sorted: [] })
	const [newCategory, setNewCategory] = useState("")

	useEffect(() => { loadPage() }, [])

	const loadPage = () => { indexApi("categories", setCategories) }
	const context = { loadPage }
	const createCategory = () => { createApi("categories", { name: newCategory }, context) }

	const allCategories = categories.all
	const topCategories = categories.sorted?.sort((a, b) => a.order - b.order) || []

	return <div id="categories-view">
		<MetaTags
			title={"Categories"}
			description={"All religious texts in our database sorted by category, time, and group."}
			url="categories" />

		<div className="pv-5">
			<h1>All Categories</h1>
			<p>List of all categories- separated by canon, religion, and time period.</p>
		</div>

		<div className="dark-stretchy">
			{checkRole(2) ? <div>
				<input value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
				<ToolTip text="Create new category">
					<span className="primary-button small-button" onClick={createCategory}><i className="fas fa-plus"></i></span>
				</ToolTip>
			</div> : ""}
		</div>

		{topCategories.length === 0 ? loadingSpinner :
			<Container className="category-outer-container">
				<Row>
					{topCategories.map(category => <Category key={category.category_id} category={category} categories={allCategories} context={{ loadPage }} />)}
				</Row>
			</Container>
		}


	</div>

}

export default Index
