import React from "react"
import {Routes, Route} from "react-router-dom"

import Index from "./Index/index"
import LogIn from "./Login/logIn"
import Verify from "./Verify/verify"
import Logout from "./Logout/logout"
import Register from "./Register/register"
import UserProfile from "./Dashboard/dashboard"

import ForgottenPassword from "./PasswordForget/forgottenPassword"
import ResetPassword from "./PasswordReset/resetPassword"

import { checkRole } from "helpers/helpers"

const User = () => {
	return <div>
		<Routes>
			{checkRole(2) && <Route path="" element={<Index />} /> }
			<Route path="login" element={<LogIn />} />
			<Route path="verify/:username/:verify_hash" element={<Verify />} />

			{checkRole(1) && <Route path="dashboard" element={<UserProfile />} /> }
			<Route path="register" element={<Register />} />
			
			<Route path="forgottenPassword" element={<ForgottenPassword />} />
			<Route path="resetPassword/:username/:verify_hash" element={<ResetPassword />} />

			<Route path="logout" element={<Logout />} />
		</Routes>
	</div>
}

export default User
