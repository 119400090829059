import { AppContext } from "structure/AppContext"
import React, { useContext, useEffect} from "react"
import { Link } from "react-router-dom"

import MetaTags from "components/UI/MetaTags/metaTags"

const Logout = () => {
	const appContext = useContext(AppContext)
	useEffect(() => {
		if(appContext.user.user_id) appContext.logout()
	}, [])

	return <div className='tpBlackBg'>
		<MetaTags title={"Logged Out"} url={"users/logout"} privatePage />
		<h2>You are logged out.</h2>
		<p><Link to="/">Return Home</Link></p>
	</div>
}

export default Logout
