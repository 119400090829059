import React from "react"
import { checkRole } from "helpers/helpers"

import { Row } from "react-bootstrap"
import { createApi, superDeleteApi } from "helpers/api_helper"
import { BookCategoryType, CategoryType } from "types/categories"
import { groupDataComponent } from "helpers/books_helper"
import Book from "./Book"
import ResourceSelect from "components/UI/ResourceSelect/ResourceSelect"
import Subcategories from "./Subcategories"

interface CategoryProps {
	category: CategoryType;
	context: {
		loadPage: () => void;
	}
}

const BookSection = ({ category, context }: CategoryProps) => {
	const addBook = (book_id: number) => {
		createApi("categories/books", { book_id, category_id: category.category_id }, context)
	}
	const deleteAllBooks = () => {
		superDeleteApi("categories/books/category", category.category_id, context, category.name)
	}

	if (!category.category_id) return ""

	const books: BookCategoryType[] = []
	const parseCategoryBooks = (c: CategoryType) => {
		books.push(...c.books)
		c.subcategories.forEach(sc => parseCategoryBooks(sc))
	}
	parseCategoryBooks(category)


	return <div className="pv-3">
		<h2>Structure of the {category.name}</h2>
		{groupDataComponent(books)}
		<Row>
			{category.books.sort((a, b) => a.order-b.order).map(book => <Book key={book.book_id} book={book} context={context} category={category} />)}
		</Row>
		<br />
		<Subcategories category={category} context={context} />
		{checkRole(2) && <ResourceSelect path="books" callback={addBook} existing={books} idField="book_id" nameField="name" title="Add Books" />}
		{checkRole(2) && <span onClick={deleteAllBooks}>Delete All Books</span>}

	</div>
}



export default BookSection
