import React from "react"

import Title from "./components/Title"
import BookParts from "./components/BookParts"
import TableOfContents from "../TableOfContents/TableOfContents"
import SummaryBackground from "./components/SummaryBackground"

const Book = () => {

	return <>
		<Title />
		<div className="pagebreak"></div>
		<SummaryBackground />
		<div className="pagebreak"></div>
		<TableOfContents />
		<div className="pagebreak"></div>
		<BookParts />
	</>
}

export default Book
