import React, {useContext} from "react"

import BookPart from "../../Part/Part"
import { BookReaderContext } from "../../../context"

const BookParts = () => {
	const { book } = useContext(BookReaderContext)
	
	return <div>
		{book.parts.map(part => <BookPart part={part} key={part.book_part_id} />)}
	</div>
}

export default BookParts
