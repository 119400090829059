import React from "react"
import ToolTip from "../ToolTip"
import { frontEndLink } from "siteSettings"

const LinkGrabber = ({ link, linkNoun }: { link: string, linkNoun?: string }) => {
	return <ToolTip text={`Copy link to this ${linkNoun || "page"}`} placement="bottom">
		<button className="transparent-button xsmall-button" onClick={() => navigator.clipboard.writeText(frontEndLink + "/" + link)}>
			<i className="fas fa-link"></i>
		</button>
	</ToolTip>
}

export default LinkGrabber