import { printChapterHeading } from "helpers/chapters_helper"
import { PartType } from "types/parts"
import React from "react"

interface PartProps {
	part: PartType
}

const PartComponent = ({ part }: PartProps) => {

	return <>
		<br />
		<div><b>Part {part.order} {part.name ? `: ${part.name}` : ""}</b></div>
		{part.chapters?.map(chapter => <div className="toc-chapter" key={chapter.chapter_id} >
			<a href={`#chapter-${chapter.chapter_id}`}>
				{printChapterHeading(chapter)}. {chapter.name}
			</a>
		</div>
		)}
	</>

}

export default PartComponent
