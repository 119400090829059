import React, { useContext, useEffect } from "react"
import { Col, Row } from "react-bootstrap"

import { convertNewLinesToLineBreaks, scrollToId } from "helpers/helpers"
import { printNumber, printVerse } from "helpers/verse_helper"
import { PrintTranslations } from "helpers/translation_helper"
import { VerseType } from "types/verse"
import { BookReaderContext } from "../../context"
import LinkGrabber from "components/UI/LinkGrabber/LinkGrabber"

const VerseComponent = ({ verse }: { verse: VerseType }) => {
	const { book, showAbridged,
		showCommentary,
		compareTranslation, scrollPosition } = useContext(BookReaderContext)
	if (!verse.verse_id) return ""

	//Possible to only show commentary.
	if (!compareTranslation && !showAbridged) {
		if (showCommentary && verse.commentary) {
			return <p>{printNumber(verse)} {convertNewLinesToLineBreaks(verse.commentary)}</p>
		} else {
			return ""
		}
	}

	useEffect(() => {
		if (scrollPosition.chapter && scrollPosition.verse && //both target_chapter & target_verse exist
			scrollPosition.chapter === verse.original_chapter  //the Verse is the correct target_chapter
			&& scrollPosition.verse >= verse.original_start_verse && scrollPosition.verse <= verse.original_end_verse //and target_verse in bounds
		) {
			scrollToId(`verse-${verse.verse_id}`)
		}
	}, [])

	let verseComponent: any = ""
	if (compareTranslation && showAbridged) {
		verseComponent = <Row>
			<Col xs={6} lg={5}>
				{printVerse(verse)}
			</Col>
			<Col xs={6} lg={6}>
				<PrintTranslations translations={verse.translations} />
			</Col>
			<Col xs={12} lg={1}>
				<LinkGrabber link={`books/${book.name}?chapter=${verse.original_chapter}&verse=${verse.original_start_verse}`} linkNoun="verse" />
			</Col>
		</Row>
	} else {
		if (compareTranslation) {
			verseComponent = <Row>
				<Col xs={10} lg={11}>
					<PrintTranslations translations={verse.translations} />
				</Col>
				<Col xs={2} lg={1}>
					<LinkGrabber link={`books/${book.name}?chapter=${verse.original_chapter}&verse=${verse.original_start_verse}`} linkNoun="verse" />
				</Col>
			</Row>
		} else {
			verseComponent = <Row>
				<Col xs={10} lg={11}>{printVerse(verse)}
				</Col>
				<Col xs={2} lg={1}>
					<LinkGrabber link={`books/${book.name}?chapter=${verse.original_chapter}&verse=${verse.original_start_verse}`} linkNoun="verse"  />
				</Col>
			</Row>
		}
	}

	return <div id={`verse-${verse.verse_id}`}>
		{verse.subtitle ? <h3>{verse.subtitle}</h3> : ""}
		{verseComponent}
		{showCommentary && verse.commentary ? <p style={{ fontStyle: "italic" }}>*{convertNewLinesToLineBreaks(verse.commentary)}</p> : ""}
	</div>

}

export default VerseComponent
