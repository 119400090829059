import { BookFields, BookType } from "types/books"
import { PartFields } from "types/parts"
import React from "react"
import ToolTip from "components/UI/ToolTip"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { FormFieldType } from "types/forms"
import { preservation_amount_options, author_type_options } from "types/books"
import { Link } from "react-router-dom"


export const completeTitle = (book: BookType) => {
	const altNames = book.alternate_names?.split(", ") || []
	const titles = [book.name, book.abbreviation, ...altNames]
	const processed: string[] = []
	titles.forEach(t => { if (t && !processed.includes(t)) { processed.push(t) } })
	return `${book.full_name} [${processed.join(", ")}]`
}


//Ignores the proceeding integer like in "1 Timothy"
export const smartSortBooksByAlphaShortName = (books: BookType[], order = 1) => {
	return books.sort((a, b) => {
		let processedA, processedB

		const paarray = (a.name || "").split(" ")
		if (!Number.isNaN(parseInt(paarray[0]))) { processedA = paarray[1] + paarray[0] }
		else { processedA = paarray.join(" ") }

		const pbarray = (b.name || "").split(" ")
		if (!Number.isNaN(parseInt(pbarray[0]))) { processedB = pbarray[1] + pbarray[0] }
		else { processedB = pbarray.join(" ") }

		return order * processedA.localeCompare(processedB)
	})
}

export const preservationText = (book: BookType) => {
	if (book.preservation_amount == null) return null
	return preservation_amount_options[book.preservation_amount]
}

export const authorText = (book: BookType) => {
	const text = book.author_type == null ? "" : `${author_type_options[book.author_type]} `
	const author = book.author || "Unknown"
	return `${text}by ${author}`
}

export const printAcademicYearRange = (book: BookType) => {
	if (!book.start_year && !book.end_year) { return "Unknown" }
	if (book.start_year === book.end_year) { return processDate(book.start_year) }
	return processDate(book.start_year) + "-" + processDate(book.end_year)
}

export const printSettingYearRange = (book: BookType) => {
	if (!book.setting_start_year && !book.setting_end_year) { return "Unknown" }
	if (book.setting_start_year === book.setting_end_year) { return processDate(book.setting_start_year) }
	return processDate(book.setting_start_year) + "-" + processDate(book.setting_end_year)
}
export const printAcademicYearRangeText = (book: BookType) => {
	if (!book.start_year && !book.end_year) { return "Unknown" }
	if (book.start_year === book.end_year) { return "Written in " + processDate(book.start_year) }
	return "Written betwen " + processDate(book.start_year) + "-" + processDate(book.end_year)
}
export const printSettingYearRangeText = (book: BookType) => {
	if (!book.setting_start_year && !book.setting_end_year) { return "Unknown" }
	if (book.setting_start_year === book.setting_end_year) { return "Set in " + processDate(book.setting_start_year) }
	return "Set between " + processDate(book.setting_start_year) + "-" + processDate(book.setting_end_year)
}

export const processDate = (date: number) => {
	if (date < 0) { return date * -1 + "BC" }
	if (date > 0) { return date + "CE" }
	return "?"
}

export const calculateLengthValue = (book: BookType) => {
	return Math.round(Math.sqrt(book.original_verse_count / 16) * 10) / 10
}

export const publicDomainButton = (book: BookType) => {
	if (book.original_verse_count > 1) {
		return <ToolTip placement="bottom" text={`A public domain translation of the ${book.full_name} is available here.`}>
			<div style={{ border: "1px solid #ccc" }}>
				<Link to={`/books/${book.name}`}  className="primary-button d-block" style={{ padding: ".25rem", margin: 0 }}>
					PDV
				</Link>
			</div>
		</ToolTip>
	}
}
export const abridgedProgressButton = (book: BookType) => {

	let text = `The Abridged Version of the ${book.full_name} `
	if (book.abridged_percent > 0 && book.abridged_percent < 100 ) text += `is ${book.abridged_percent}% complete.`
	if (book.abridged_percent === 0) text += "is coming soon!"
	if (book.abridged_percent === 100) text += "is completed and available here."

	return <ToolTip variation="secondary" placement="bottom" text={text} >
		<div style={{ border: "1px solid #ccc" }}>
			<div style={{ width: `${book.abridged_percent || 0}%` }} >
				<Link to={`/books/${book.name}`} className="primary-button secondary-button d-block" style={{ padding: ".25rem 0", margin: 0 }}>
					{book.abridged_percent < 100 ?
						<span>{book.abridged_percent > 0 ? book.abridged_percent + "%" : ""}</span>
						: "ABV"}&nbsp;
				</Link>
			</div>
		</div>
	</ToolTip>
}

export const bookProgressStatusText = (book: BookType) => {
	let status = "Unstarted"
	if (book.abridged_percent > 5) { status = "Started" }
	if (book.abridged_percent > 33) { status = "In Progress" }
	if (book.abridged_percent > 90) { status = "Finishing" }
	if (book.abridged_percent === 100) { status = "Finished" }
	return status
}

export const abridgedStatus = (book: BookType) => {
	return <>{bookProgressStatusText(book)}- {100 - book.abridged_percent}% Remaining</>
}

export const bookProgressStatus = (book: BookType) => {
	let completion = <>0</>
	if( (book.original_verse_count && book.abridged_verse_count) ) {
		completion = <>{(book.original_verse_count - book.abridged_verse_count).toLocaleString()}/{book.original_verse_count.toLocaleString()}</>
	}
	return <div>
		<p>
			Abridged Status: {abridgedStatus(book)}<br />
			{completion} Verses
		</p>
	</div>
}

export const groupProgressStats = (books: BookType[]) => {
	let abridged_verse_count = 0
	let original_verse_count = 0
	let abridged_word_count = 0
	let original_word_count = 0

	books.forEach(book => {
		abridged_verse_count += book.abridged_verse_count
		original_verse_count += book.original_verse_count
		abridged_word_count += book.abridged_word_count
		original_word_count += book.original_word_count
	})

	const finished = books.filter(b => b.abridged_percent === 100).length
	const progress = books.filter(b => b.abridged_percent < 100 && b.abridged_percent > 0).length
	const todo = books.filter(b => !b.abridged_percent).length

	return {
		abridged_verse_count, original_verse_count,
		abridged_word_count, original_word_count,
		finished, progress, todo
	}
}

export const groupProgressComponent = (books: BookType[]) => {
	const { finished, progress, todo, abridged_verse_count, original_verse_count } = groupProgressStats(books)

	return <div>
		{books.length} Total books - Abridged: {finished} / In Progress: {progress} / To Do: {todo}<br />
		{Math.round(abridged_verse_count / original_verse_count * 100)}% Complete - {abridged_verse_count.toLocaleString()} / {original_verse_count.toLocaleString()} Verses
	</div>
}

export const groupDataComponent = (books: BookType[]) => {
	const { original_verse_count, original_word_count } = groupProgressStats(books)

	return <div>
		{books.length} Total books - {original_verse_count.toLocaleString()} Verses - {original_word_count.toLocaleString()} Words
	</div>
}

export const bookFieldData = (fieldname: any) => {
	const result = BookFields.find(f => f.key === fieldname)
	if (result) return result
	const blankResult: FormFieldType = { key: "", title: "", description: "", example: "", kind: "number" }
	return blankResult
}
export const BookField = (book: any, fieldname: any, context: any) => {
	const field = bookFieldData(fieldname)
	if (field) {
		return <DynamicField item={book} path={"books"} context={context} autosave={true} field={field} />
	}
}
export const BookFieldComplete = (book: any, fieldname: any, context: any) => {
	const field = bookFieldData(fieldname)
	if (field) {
		return <DynamicField item={book} path={"books"} context={context} autosave={true} field={field} label={true} tooltip={true} />
	}
}

export const PartField = (part: any, fieldname: any, context: any) => {
	const field = PartFields.find(f => f.key === fieldname)
	if (field) {
		return <DynamicField item={part} path={"parts"} context={context} autosave={true} field={field} />
	}
}