import axios from "axios"
import { apiPath, headers } from "helpers/helpers"

/* Api helpers - 
In general, loads up axios, headers, and apiPath to easily build the api path.
index & item are used to retrieve data, so they take a callback that sets state.
the others are used to update data, so they take an object/context with a "loadPage" function.

indexApi('users', setUsers)
Gets all users

itemApi('users', 1, setUser)
Gets user with id = 1

createApi('users', {email: "example"}, context)		
Creates user with data							

updateApi('users', 1, {email: "example"}, context)		
Updates user with id = 1

deleteApi('users', 1, context, "username")				
Deletes user with id = 1, shows "username" in delete message.


*/

/* IndexApi - used to get a list of items */
type IndexApiArgs = (
	url: string,
	callback: (data: any) => void
) => Promise<void>

export const indexApi: IndexApiArgs = async (url, callback) => {
	try {
		const path = `${apiPath}/${url}`
		const res = await axios.get(path, headers)
		callback(res.data)
	} catch (err) { console.log(err) } //eslint-disable-line
}

/* ItemApi - used to get a single item by id */
type ItemApiArgs = (
	url: string, 
	id: number | string,
	callback: (data: any) => void,
	append?: string | number | null
) => Promise<void>

export const itemApi: ItemApiArgs = async (url, id, callback, append = null) => {
	try {
		const path = `${apiPath}/${url}/${id}${append ? "/" + append : ""}`
		const res = await axios.get(path, headers)
		callback(res.data)
	} catch(err) { console.log(err) } //eslint-disable-line
}

/* CreateApi - used to create an item based on the data */
type CreateApiArgs = (
	url: string, 
	data: any, 
	context: { loadPage: () => void }
) => Promise<any>

export const createApi: CreateApiArgs = async (url, data, context) => {
	try {
		const path = `${apiPath}/${url}`
		await axios.post(path, data, headers)
		context.loadPage() 
		return true
	}
	catch(err: any) { 
		console.log(err) //eslint-disable-line
		return err.response.data
	} 
}

/* UpdateApi - used to update an item based on the id and data */
type UpdateApiArgs = (
	url: string, 
	id: number | string,
	data: any, 
	context: { loadPage: () => void }
) => Promise<any>

export const updateApi: UpdateApiArgs = async ( url, id, data, context ) => {
	try {
		const path = `${apiPath}/${url}/${id}`
		await axios.put(path, data, headers)
		context.loadPage() 
		return true
	}
	catch(err: any) { 
		console.log(err) //eslint-disable-line
		return err.response.data
	}
}

/* DeleteApi - used to delete an item based on the id- shows confirmation screen. */
type DeleteApiArgs = (
	url: string, 
	id: number | string, 
	context: { loadPage: () => void }, 
	name?: string | null | undefined
) => Promise<void>

export const deleteApi: DeleteApiArgs = async ( url, id, context, name ) => {
	if (window.confirm(`Are you sure you want to delete ${name ? name : "this"}?`)) {
		try {
			const path = `${apiPath}/${url}/${id}`
			await axios.delete(path, headers)
			context.loadPage() 
		}
		catch(err) { console.log(err) } //eslint-disable-line
	}
}

/* DeleteApi - used to delete an item based on the id- shows confirmation screen. */
type SuperDeleteApiArgs = (
	url: string, 
	id: number | string, 
	context: { loadPage: () => void }, 
	name: string | null | undefined
) => Promise<void>

export const superDeleteApi: SuperDeleteApiArgs = async ( url, id, context, name ) => {
	if (name === window.prompt(`Are you sure you want to delete?\nEnter "${name}" to confirm.`)) {
		try {
			const path = `${apiPath}/${url}/${id}`
			await axios.delete(path, headers)
			context.loadPage() 
		}
		catch(err) { console.log(err) } //eslint-disable-line
	}
}