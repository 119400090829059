import React, {useContext} from "react"

import { createApi, deleteApi, updateApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { PartType } from "types/parts"
import ToolTip from "components/UI/ToolTip"
import { PartField } from "helpers/books_helper"

interface ControlBarTypes {
	part: PartType
	open: boolean
	setOpen: (value: boolean) => void
}

const ControlBar = ({ part, open, setOpen }: ControlBarTypes) => {
	const context = useContext(BookEditorContext)
	const { setDragItem } = context
	const id = part.book_part_id
	const deletePart = () => { deleteApi("parts", id, context, part.name) }
	const insertPart = () => { createApi(`parts/insert/${id}`, {}, context) }
	const mergePart = () => { updateApi("parts/merge", id, {}, context) }

	const drag = () => {
		setDragItem({
			item_id: part.book_part_id,
			item_order: part.order,
			item_kind: "part",
			item_parent: part.book_id
		})
	}

	const endDrag = () => {
		setDragItem(null)
	}

	return <div className="control-title-bar ctb-part">

		<div className="control-bar-up">
			<ToolTip text={"Insert new part before"}>
				<span className="fas fa-circle-plus" onClick={insertPart}></span>
			</ToolTip>&nbsp;
			<ToolTip text={"Merge part with the one before"}>
				<span className="fas fa-merge" onClick={mergePart}></span>
			</ToolTip>&nbsp;
		</div>

		<div className="control-bar-left">
			<ToolTip text={"Drag to reorder part"}>
				<span className="fas fa-arrows-up-down-left-right" draggable onDragStart={drag} onDragEnd={endDrag} ></span>
			</ToolTip>&nbsp;
		</div>
		<div className="control-bar-right">
			<ToolTip text={"Delete whole part"}>
				<span className="fas fa-trash" onClick={deletePart}></span>
			</ToolTip>&nbsp;
		</div>

		<div className="control-main-bar">
			<h2 className="control-bar-name">
                Part {PartField(part, "order", context)}: {PartField(part, "name", context)}
			</h2>

			<div className="control-bar-collapse">
				<span className={`fas ${open ? "fa-angle-down" : "fa-angle-up"}`} onClick={() => { setOpen(!open) }}></span>
			</div>
		</div>

	</div>

}

export default ControlBar
