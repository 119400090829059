import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import Note from "./Note"
import { indexApi } from "helpers/api_helper"
import { NoteFormFields, NoteType } from "types/note"

import MetaTags from "components/UI/MetaTags/metaTags"
import Lister from "components/UI/Lister/Lister"

const Index = () => {
	<MetaTags title={"Admin To-Do Notes"} url="notes" privatePage />
	const [notes, setNotes] = useState<NoteType[]>([])

	useEffect(() => { loadPage() }, [])
	const loadPage = () => { indexApi("notes", setNotes) }
	const context = { loadPage }

	return <div id="notes-view">

		<div style={{ padding: "4rem" }}>
			<h1>Notes</h1>
			<p>To-Do Notes and Requests</p>
		</div>
		<div className="book-sort-banner"></div>



		<Container>
			<Lister
				items={notes}
				ItemComponent={Note}
				FormFields={NoteFormFields}
				context={context}
				options={{ defaultSortField: "created_at", defaultSortOrder: -1 }}
				search={["text"]}
				filters={["kind", "status", "urgency", "foreign_class"]}
				sort={["created_at"]}
			/>
		</Container>
	</div>

}

export default Index
