import React, { useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { apiPath } from "helpers/helpers"
import { Container } from "react-bootstrap"

import MetaTags from "components/UI/MetaTags/metaTags"

const ForgottenPassword = () => {
	const [username, setUsername] = useState("")
	const [sent, setSent] = useState(false)


	const handleChange = (e: React.ChangeEvent) => {
		const target = e.target as HTMLInputElement
		setUsername(target.value)
	}

	const submitForm = async (e: React.FormEvent) => {
		e.preventDefault()
		await axios.get(`${apiPath}/users/auth/forgottenPassword/${username}`)
		setSent(true)
	}

	return <Container>
		<div style={{ paddingTop: "5rem" }}>
			<MetaTags title={"Request Your Password"} url={"users/forgottenPassword"} privatePage />
			{sent ? "Please check your email for the link to reset your password. If you do not see it in a few minutes, check your spam folder." : ""}

			<h2>Forgot Your Password?</h2>

			<div>
				<Link to="/users/register">Create an Account</Link>&nbsp;|&nbsp;
				<Link to="/users/login">Log In</Link>
			</div>
			<br />
			<h4>Enter your username & we&apos;ll send you a link.</h4>
			<br />

			<form onSubmit={submitForm} style={{ margin: "auto" }}>
				<div>
					<input
						onChange={handleChange} type="text"
						name="username" placeholder="Username or Email"
						value={username} />
				</div>
				<br />
				<span className="primary-button" onClick={submitForm}>Submit</span>
			</form>

		</div>
	</Container>
}

export default ForgottenPassword
