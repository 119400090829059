import React from "react"
import { Link } from "react-router-dom"

import { Container } from "react-bootstrap"
import { CategoryType } from "types/categories"
import BookSection from "./BookSection"
import MainInfo from "./MainInfo"

interface CategoryProps {
	category: CategoryType;
	categories: CategoryType[];
	context: {
		loadPage: () => void;
	}
}

const CategoryComponent = ({ category, context, categories }: CategoryProps) => {
	if (!category.category_id) return ""

	return <Container style={{paddingTop: "4rem"}}>
		<Link className="primary-button small-button" to={"/categories"}>Back to All Categories</Link><br />

		<MainInfo category={category} context={context} categories={categories} />
		<BookSection category={category} context={context} />
	</Container>


}

export default CategoryComponent
