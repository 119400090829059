import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react"
import "./index.scss"
import { Container } from "react-bootstrap"
import ToolTip from "../ToolTip"

type ModalPropTypes = {
	titleText: string | ReactElement
	buttonText: string | ReactElement
	toolTipText?: string
	showState?: boolean
	callbacks?: {
		onOpen?: () => void
		onClose?: () => void
	}
}

const Modal = ({ titleText, buttonText, toolTipText, showState, callbacks, children }: PropsWithChildren<ModalPropTypes>) => {

	const [show, rawSetShow] = useState(showState || false)
	const setShow = (data: boolean) => {
		if(data === true && callbacks?.onOpen) callbacks.onOpen()
		rawSetShow(data)
		if(data === false && callbacks?.onClose) callbacks.onClose()
	}


	useEffect(() => {
		setShow(showState || false)
	}, [showState])

	let modalOpenButton = <span className="primary-button small-button" onClick={() => setShow(true)}>
		{buttonText}
	</span>

	if (toolTipText) {
		modalOpenButton = <ToolTip text={toolTipText}>
			{modalOpenButton}
		</ToolTip>
	}

	return <>
		{modalOpenButton}
		
		{show && <div className="cmodal-bg" onClick={(e: any) => e.target.classList.contains("cmodal-bg") ? setShow(false) : ""}>
			<Container>
				<div className="cmodal-main">
					<div className="cmodal-header">
						<span>{titleText}</span>
						<span className="format-link light-link">
							<span onClick={() => setShow(false)} style={{ padding: ".5rem" }} className="fas fa-xmark"></span>
						</span>
					</div>
					<div className="cmodal-body">
						{children}
					</div>
				</div>
			</Container>
		</div>}
	</>
}

export default Modal
