import React, { useContext } from "react"

import BookPart from "../../Part/Part"
import { BookContext } from "../../../bookContext"

const BookParts = () => {
	const { book } = useContext(BookContext)

	return <div>
		{book.parts.map(part => <BookPart part={part} key={part.book_part_id} />)}
	</div>



}

export default BookParts
