import React from "react"

import { Link } from "react-router-dom"

import { Row, Col } from "react-bootstrap"
import {
	publicDomainButton,
	abridgedProgressButton
} from "helpers/books_helper"
import { BookType } from "types/books"
import { superDeleteApi } from "helpers/api_helper"

const BookComponent = ({ book, context }: { book: BookType, context: any }) => {
	book.id = book.book_id

	const deleteBook = () => {
		superDeleteApi("books", book.book_id, context, book.name)
	}

	return <div className="book-item " key={book.book_id}>
		<Row className="book-index-row">
			<Col xs={1} className="book-order">
				{book.order}
			</Col>
			<Col xs={6} className="book-name">
				<Link className="book-row-link" to={`/books/${book.name}`}>
					{book.full_name}
				</Link>
			</Col>

			{
				book.preservation_amount === 0 ?
					<Col xs={4}>LOST</Col>
					:
					<>
						<Col xs={2}>
							{abridgedProgressButton(book)}
						</Col>
						<Col xs={2}>
							{publicDomainButton(book)}
						</Col>
					</>
			}
			<Col xs={1}>
				<span onClick={deleteBook}><i className="fas fa-trash"></i></span>
			</Col>
		</Row>
	</div>
}

export default BookComponent
