import React from "react"

const FourOhFour = () => {
	return <div>
		<h2>We&apos;re Sorry</h2>
		<p>There&apos;s totally not any secret information being hidden from you...</p>
		<h3>...</h3>
		<h3>.</h3>
		<h1>ERROR: 404 Page Not Found</h1>
	</div>
}

export default FourOhFour