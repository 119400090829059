import React from "react"

import { Link } from "react-router-dom"
import { RegisterUserFormFields, newUserData } from "types/user"
import FormBuilder from "components/UI/FormBuilder/Form"
import { Container } from "react-bootstrap"

import MetaTags from "components/UI/MetaTags/metaTags"

const Register = () => {
	return <Container>
		<MetaTags title={"Register Your Account"} description={""} url={"users/register"} privatePage />
		<h2 style={{ paddingTop: "5rem" }}>Create an Account</h2>
		<div>
			<Link to="/users/forgottenPassword">Forgot Password</Link>&nbsp;|&nbsp;
			<Link to="/users/login">Log In</Link>
		</div>
		<br />
		<FormBuilder
			FormFields={RegisterUserFormFields}
			itemData={newUserData}
			path="users/auth/register"
			method="create"
			options={{
				hideOnSuccess: true,
				successElement: <div>
					Thank you. Please go check your email for the verification link.
					If you do not see it within a few minutes, please check your spam folder.<br />
					<Link to="/users/login">Login</Link>
				</div>
			}}
		/>
		<br />
	</Container>
}

export default Register
