import React, {useContext} from "react"
import Chapter from "../../Chapter/Chapter"
import { createApi } from "helpers/api_helper"
import { PartType } from "types/parts"
import { BookEditorContext } from "components/BookEditor/context"

const PartChapters = ({ part }: { part: PartType }) => {
	const context = useContext(BookEditorContext)
	const appendChapter = () => { createApi(`chapters/append/${part.book_part_id}`, {}, context) }

	return <div>
		{part.chapters?.map(chapter => <Chapter chapter={chapter} key={chapter.chapter_id} />)}
        
		<span onClick={appendChapter}>
			<span className="fas fa-circle-plus"></span> Add Chapter
		</span>

	</div>
}

export default PartChapters
