import React from "react"
import { Routes, Route } from "react-router-dom"

import ViewContactMessage from "./Index/index"
import SubmitContactMessage from "./View/index"
import { checkRole } from "helpers/helpers"

const TranslationComponent = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>
                
				<Route path="" element={<SubmitContactMessage />} />
				{ checkRole(2) ? <Route path="all" element={<ViewContactMessage />} /> : "" }


			</Routes>
		</div>
	</div>
}

export default TranslationComponent
