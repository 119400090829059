import React, { useState, useEffect } from "react"
import "./print.scss"
import "./paged.css"
import { useSearchParams } from "react-router-dom"
import { indexApi } from "helpers/api_helper"
import { PrinterContext } from "./printerContext"
import { loadingSpinner } from "siteSettings"

import MetaTags from "components/UI/MetaTags/metaTags"
import Books from "./components/Books"

const BookPrinter = () => {
	const [searchParams] = useSearchParams()
	const showAbridged = searchParams.get("abridged") !== "false"
	const compareTranslation = searchParams.get("translation") !== "false"
	const showCommentary = searchParams.get("commentary") !== "false"
	const translation_id = searchParams.get("translation_id") || 1

	const [books, rawSetBooks] = useState([])

	const setBooks = (data: any) => {
		rawSetBooks(data)
		setTimeout(() => {
			const ppf: any = window
			ppf.PagedPolyfill.preview()
		}, 1000)
	}

	const loadPage = () => {
		indexApi(`books/mass/${translation_id}/${searchParams.get("books")}`, setBooks)
	}

	useEffect(() => loadPage(), [])

	const context = {
		showAbridged,
		compareTranslation,
		showCommentary
	}

	return <PrinterContext.Provider value={context}>
		<MetaTags title={"The Abridged Bible Project Printer"} description={""} url={"print`"} />
		<div id="printer-view">
			{books.length > 0 ? <Books books={books} /> : loadingSpinner}
		</div>
	</PrinterContext.Provider>
}

export default BookPrinter
