import React, { useEffect, useState } from "react"

import Menu from "./components/Menu/Menu"
import Table from "./components/Table/Table"
import "./index.scss"

import { DastContext } from "./context"
import { DastOptions, DastContextType } from "./types"
import { FField, sortItemsByField } from "helpers/item_helper"
import { FormFieldType, FormItemType } from "types/forms"

type DoubleAxisScrollTableType = {
    FormFields: FormFieldType[]
    items: FormItemType[]
    id?: string | null
    options?: DastOptions
}

function DoubleAxisScrollTable({ FormFields, items, id, options }: DoubleAxisScrollTableType) {
	if (!id) { id = `${Math.round(Math.random() * 1000000)}` }
	const cssId = `dast-${id}`
	if (!options) { options = {} }

	const [visibleItems, setVisibleItems] = useState(items.map(r => r.id))
	const [visibleFields, setVisibleFields] = useState(FormFields.map(c => c.key))

	const [sortField, setSortField] = useState<string>(options.defaultSortField || "id")
	const [sortDirection, setSortDirection] = useState(1)

	const [showMenu, setShowMenu] = useState(false)

	useEffect( () => {
		setVisibleItems(items.map(r => r.id))
	}, items)

	useEffect(() => {
		const divs = document.querySelectorAll(`#${cssId} .dast-scroll-x`)
		divs.forEach(div => div.addEventListener("scroll", () => {
			divs.forEach(d => {
				d.scrollLeft = div.scrollLeft
			})
		}))

		const div2s = document.querySelectorAll(`#${cssId} .dast-scroll-y`)
		div2s.forEach(div => div.addEventListener("scroll", () => {
			div2s.forEach(d => {
				d.scrollTop = div.scrollTop
			})
		}))
	}, [])

	const toggleItem = (item: FormItemType) => {
		if (visibleItems.includes(item.id)) {
			setVisibleItems(visibleItems.filter(r => r != item.id))
		}
		else { setVisibleItems([...visibleItems, item.id]) }
	}

	const toggleField = (field_key: string) => {
		if (visibleFields.includes(field_key)) {
			setVisibleFields(visibleFields.filter(c => c != field_key))
		}
		else {
			setVisibleFields([...visibleFields, field_key])
		}
	}

	const toggleSort = (field_key: string) => {
		if (sortField === field_key) { setSortDirection(sortDirection * -1) }
		else { setSortDirection(1) }
		setSortField(field_key)
	}

	const itemSort = () => {
		const filteredItems = items
			.filter(item => visibleItems.includes(item.id))
		return sortItemsByField(filteredItems, FField(sortField, FormFields), sortDirection)
	
	}

	const fieldSort = () => {
		return FormFields.filter(field => visibleFields.includes(field.key))
	}

	const context: DastContextType = {
		items, FormFields, id, cssId, options,
		visibleItems, setVisibleItems,
		visibleFields, setVisibleFields,
		sortField, setSortField,
		sortDirection, setSortDirection,
		showMenu, setShowMenu,
		toggleItem, toggleField, toggleSort,
		itemSort, fieldSort
	}

	return <DastContext.Provider value={context}>
		<div className="dast-container">
			<Menu />
			<Table />
		</div>
	</DastContext.Provider>
}

export default DoubleAxisScrollTable
