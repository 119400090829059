import React from "react"
import Chapter from "../../Chapter/Chapter"
import { PartType } from "types/parts"

const PartChapters = ({ part }: {part: PartType}) => {
	return <div>
		{part.chapters?.map(chapter => <Chapter chapter={chapter}  key={chapter.chapter_id} />)}
	</div>
}

export default PartChapters
