import React, {useContext} from "react"
import "./tableOfContents.scss"
import Part from "./Part"
import { BookContext } from "../../bookContext"


const TableOfContents = () => {
	const {book} = useContext(BookContext)

	return <div>
		<h2>Table of Contents</h2>

		<div className="print-table-of-contents">
			{
				book.parts?.map(part => <Part key={part.book_part_id} part={part} />)
			}
		</div>
	</div>

}

export default TableOfContents
