import React from "react"
import "./header.scss"
import { Link } from "react-router-dom"

import { logoSrc } from "siteSettings"

import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap"

import { checkRole, curr_user } from "helpers/helpers"

const Header = () => {
	return (
		<Navbar expand="lg" className="bg-body-tertiary header">
			<Container>
				<Navbar.Brand href="/">
					<Link className="main-home-link" to="/">
						<img src={logoSrc} alt={"Site logo"} height="36px" width="36px" title="logo" loading="eager" />&nbsp;
						<span className="homeText">ABP</span>
					</Link>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				<Navbar.Collapse>
					<Nav style={{ width: "100%", justifyContent: "center" }}>

						<Nav.Item><Link to="/search"><i className="search-button fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Search</Link></Nav.Item>
						<NavDropdown.Divider />
						<Nav.Item><Link to="/books">Books</Link></Nav.Item>
						<Nav.Item><Link to="/categories">Categories</Link></Nav.Item>
						<Nav.Item><Link to="/translations">Translations</Link></Nav.Item>
						<NavDropdown.Divider />

						<NavDropdown title="About" id="basic-nav-dropdown">
							<NavDropdown.Item><Link to="/help">Help and How to Use</Link></NavDropdown.Item>
							<NavDropdown.Item><Link to="/about">About the Project</Link></NavDropdown.Item>
							<NavDropdown.Item><Link to="/help-the-project">Help the Project</Link></NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item><Link to="/contact">Contact</Link></NavDropdown.Item>
							<NavDropdown.Divider />

							{checkRole(-1) ? <>
								<NavDropdown.Item><Link to="/users/login">Login</Link></NavDropdown.Item>
								<NavDropdown.Item><Link to="/users/register">Register</Link></NavDropdown.Item>
							</> : ""}
							{checkRole(1) ? <>
								<NavDropdown.Item><Link to="/users/dashboard">{curr_user.username}</Link></NavDropdown.Item>
								<NavDropdown.Item><Link to="/users/logout">Logout</Link></NavDropdown.Item>
							</> : ""}

						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)

}
export default Header