import React from "react"
import { DynamicFieldContextType } from "../types"

const FieldLabel = ({dynamicFieldContext}: { dynamicFieldContext: DynamicFieldContextType }) => {
	const { label, field } = dynamicFieldContext
	if(!label) return ""
	return <div>
		{field.title}{field.options?.required ? "**" : ""}
	</div>
}

export default FieldLabel