import React, { useEffect, useState, useContext } from "react"
import "./index.scss"
import { useParams } from "react-router-dom"

import Book from "./components/Book/Book"
import { itemApi } from "helpers/api_helper"
import { FullBookType, emptyBookObject } from "types/books"
import { ChapterType } from "types/chapter"
import { AppContext } from "structure/AppContext"
import { BookEditorContext, BookEditorContextType, DragItemType } from "./context"
import { loadingSpinner } from "siteSettings"

import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {
	const { translationChoice } = useContext(AppContext)
	const { id } = useParams<{ id: string }>()
	if(!id) return ""
	const [book, setBook] = useState<FullBookType>(emptyBookObject)
	const [dragItem, setDragItem] = useState<DragItemType | null>(null)

	const [compareTranslation, rawSetCompareTranslation] = useState(localStorage.getItem("book-show-translation") !== "false")
	const setCompareTranslation = (value: boolean) => {
		localStorage.setItem("book-show-translation", `${value}`)
		rawSetCompareTranslation(value)
	}

	const [localTranslationChoice, setLocalTranslationChoice] = useState(translationChoice)

	useEffect(() => {
		if (book.allTranslations.find(t => t.translation_version_id === translationChoice)) {
			setLocalTranslationChoice(translationChoice)
		}
	}, [translationChoice])
	useEffect(() => { loadPage() }, [id, localTranslationChoice, translationChoice])
	const loadPage = () => { itemApi("books", id, setBook, localTranslationChoice) }

	const parts = book.parts
	const chapters: ChapterType[] = []
	parts.map(p => chapters.push(...p.chapters))

	book.id = book.book_id
	const context: BookEditorContextType = {
		loadPage,
		book, parts, chapters,
		dragItem, setDragItem,
		compareTranslation, setCompareTranslation,
		localTranslationChoice, setLocalTranslationChoice
	}

	return <BookEditorContext.Provider value={context}>
		<MetaTags title={`Edit ${book.name}`} url={`books/${id}/edit`} privatePage />

		<div id="editor-view" className={`${dragItem?.item_kind ? "dragging-" + dragItem.item_kind : ""}`}>
			{book.book_id ? <Book /> : loadingSpinner}
		</div>
	</BookEditorContext.Provider>
}

export default Index
