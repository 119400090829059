import React from "react"

interface AppContentType {
	login: (incomingUser: any, incomingToken: string) => void;
	logout: () => void;
	user: any;
	token: string;
	translationChoice: number;
	setTranslationChoice: (value: number) => void;
}

export const AppContext = React.createContext<AppContentType>({
	login: () => { return },
	logout: () => { return },
	user: {},
	token: "",
	translationChoice: 0,
	setTranslationChoice: () => { return }
})