import React, { useEffect, useState } from "react"
import "./index.scss"
import { Container, Row, Col } from "react-bootstrap"

import BookComponent from "./components/Book"
import { groupProgressComponent } from "helpers/books_helper"

import { indexApi } from "helpers/api_helper"
import { BookType } from "types/books"
import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {
	const [books, setBooks] = useState<BookType[]>([])
	const [sort, setSort] = useState("order")
	const [sortOrder, setSortOrder] = useState(1)

	useEffect(() => { loadPage() }, [])

	const loadPage = () => { indexApi("books", setBooks) }

	const options = [
		["original_verse_count", "Length"],
		["order", "Order"],
		["abridged_percent", "Has Abridged"],
	]
	const sortOptions = [
		[1, "Asc"],
		[-1, "Desc"],
	]


	return <div id="books-directory-view">
		<MetaTags title={"The Directory"} description={"Quick overview of all books in our database."} url={"books/directory"} />
		<h1>Bible Book Database</h1>
		<p>List of all books.</p>

		<div className="book-sort-banner">
			<span>Sort</span> <select onChange={(e) => setSort(e.target.value)}>
				{options.map(o => <option key={o[0]} value={o[0]} selected={o[0] === sort}>{o[1]}</option>)}
			</select>
			<select onChange={(e) => setSortOrder(Number.parseInt(e.target.value))}>
				{sortOptions.map(o => <option key={o[0]} value={o[0]} selected={o[0] === sort}>{o[1]}</option>)}
			</select>
		</div>

		{groupProgressComponent(books)}

		<Container className="book-outer-container">
			<Row className="book-index-columns">
				{
					books.sort((a, b) => {
						const processedA = parseInt(`${a[sort as keyof BookType]}`)
						const processedB = parseInt(`${b[sort as keyof BookType]}`)
						return (sortOrder * (processedA - processedB))
					}).map(book =>
						<Col key={book.book_id} lg={4}>
							<BookComponent key={book.book_id} book={book} context={{loadPage}}/>
						</Col>
					)}
			</Row>
		</Container>
	</div>
}

export default Index
