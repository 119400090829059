import React from "react"
import "./tableOfContents.scss"
import Part from "./Part"
import { scrollToId } from "helpers/helpers"


const TocParts = ({ setOpen, context }: { setOpen: (value: boolean) => void, context: any }) => {
	const { book } = context
	return <div className="toc-panel" id="toc-parts">
		<div id="toc-parts-list">
			<div className="format-link light-link" onClick={(e) => { setOpen(false); scrollToId("book-summary", e) }} >Summary - Top</div>
			{
				book.parts?.map((part: any) => <Part key={part.book_part_id} part={part} setMenuOpen={setOpen} />)
			}
		</div>
	</div>

}

export default TocParts
