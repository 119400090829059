import { FullBookType, emptyBookObject } from "types/books"
import React from "react"

interface BookReaderContextType {
	loadPage: () => void
	book: FullBookType
	showAbridged: boolean
	setShowAbridged: (value: boolean) => void
	compareTranslation: boolean
	setCompareTranslation: (value: boolean) => void
	showCommentary: boolean
	setShowCommentary: (value: boolean) => void
	localTranslationChoice: number
	setLocalTranslationChoice: (value: number) => void
	scrollPosition: { chapter: number | null, verse: number | null }
}

export const BookReaderContext = React.createContext<BookReaderContextType>({
	loadPage: () => { return },
	book: emptyBookObject,
	showAbridged: true,
	setShowAbridged: () => { return },
	compareTranslation: true,
	setCompareTranslation: () => { return },
	showCommentary: true,
	setShowCommentary: () => { return },
	localTranslationChoice: 0,
	setLocalTranslationChoice: () => { return },
	scrollPosition: {chapter: null, verse: null}
})