import React from "react"

import DynamicField from "components/UI/DynamicFormField/DynamicField"

type FormFieldProps = {
	item: any
	fieldData: any
	update: any
	options: any
}

function FormField({ item, fieldData, update, options }: FormFieldProps) {
	const width = "100%"

	if(fieldData.options?.hidden) return ""

	const dynamicFieldOptions: Record<string, any> = {}
	if(fieldData.kind !== "password") dynamicFieldOptions.formOnly = true
	dynamicFieldOptions.role = options?.role

	return <div key={fieldData.key} style={{ width: width }}>
		<DynamicField
			callback={update}
			item={item}
			field={fieldData}
			autosave={true}
			label={true}
			description={true}
			options={dynamicFieldOptions}
		/>
	</div>
}

export default FormField