import { indexApi } from "helpers/api_helper"
import React, { useState } from "react"
import { loadingSpinner } from "siteSettings"
import { BookTranslationVersionType, TranslationType } from "types/translationVersions"
import TranslationsSuggestions from "./TranslationsSuggestions"
import TranslationImport from "./AITranslationImport"
import { Link } from "react-router-dom"

type ComponentPropTypes = {
	translation_id: number
	book_id: number
	translationVersion: BookTranslationVersionType
}

const AIGenerateButton = ({ translationVersion, translation_id, book_id }: ComponentPropTypes) => {

	const [loading, setLoading] = useState(false)
	const [generatedTranslations, setGeneratedTranslations] = useState<TranslationType[]>([])
	const [success, setSuccess] = useState(false)

	const context = {
		loadPage: () => {
			setGeneratedTranslations([])
			setSuccess(true)
		}
	}

	const doGeneration = async () => {
		setLoading(true)
		await indexApi(`converter/translation/${translation_id}/${book_id}`, setGeneratedTranslations)
		setLoading(false)
	}

	return <div>
		<h3>AI-Update</h3>
		{loading ? <div>
			{loadingSpinner}<br />
			Depending on length, this may take several minutes.
		</div> :
			<div>
				<span className="primary-button" onClick={doGeneration}>{generatedTranslations.length > 0 && "Re-"}Generate Modern Text</span>
				<TranslationsSuggestions translations={generatedTranslations} />
				<TranslationImport translationVersion={translationVersion} generatedTranslations={generatedTranslations} context={context} />
				{success && <div>
					<Link className="primary-button secondary-button" to={`/books/${translationVersion.book.book_id}/edit`}>
						Success! Edit The {translationVersion.book.full_name}
					</Link>
				</div>
				}
			</div>
		}
	</div>

}

export default AIGenerateButton