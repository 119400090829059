import React, { useState, useContext } from "react"
import { itemApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { loadingSpinner } from "siteSettings"

const SummaryImport = () => {
	const context = useContext(BookEditorContext)
	const { book } = context

	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const callback = () => {
		setLoading(false)
		setSuccess(true)
		context.loadPage()
	}

	const callImport = () => {
		setLoading(true)
		itemApi("converter/bookSummary", book.book_id, callback)
	}

	const callWholeBook = () => {
		setLoading(true)
		itemApi(`converter/translation/${book.allTranslations[0].translation_version_id}`, book.book_id, callback)
	}

	return <div className="p-5">
		<h3>AI Generate Chapter Summaries</h3>

		{loading ?
			<div>
				{loadingSpinner}<br />
				Depending on length, this may take several minutes.
			</div> : 
			<div>
				<span className="primary-button" onClick={callImport}>{ success && "Re-"}Generate</span>
				<span className="primary-button" onClick={callWholeBook}>Whole Book</span>
				{ success && <span className="primary-button secondary-button">Success!</span>}
			</div>
		}
	</div>
}

export default SummaryImport
