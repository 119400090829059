import React, { useState } from "react"
import "./index.scss"

import { loadingSpinner } from "siteSettings"
import { FormFieldType } from "types/forms"
import { ListerItemType, ListerOptions } from "./types"
import { FField, filterItemsByField, searchItemsByFields, sortItemsByField } from "helpers/item_helper"
import Modal from "../Modal/Modal"


type ListerProps = {
	items: ListerItemType[]
	ItemComponent: any
	context: any
	FormFields: FormFieldType[]
	filters?: string[]
	search?: string[]
	sort?: string[]
	options?: ListerOptions
}

const Lister = ({ items, ItemComponent, context, FormFields, filters, sort, search, options }: ListerProps) => {
	const SearchFields = FormFields.filter(f => search?.includes(f.key))
	const SortFields = FormFields.filter(f => sort?.includes(f.key))
	const FilterFields = FormFields.filter(f => filters?.includes(f.key))

	const [searchTerm, setSearchTerm] = useState("")
	const [showSearchForm, setShowSearchForm] = useState(false)

	const defaultSortField = options?.defaultSortField || "id"
	const defaultSortOrder = options?.defaultSortOrder || 1
	const [sortField, setSortField] = useState(defaultSortField)
	const [sortOrder, setSortOrder] = useState(defaultSortOrder)
	const [showSortForm, setShowSortForm] = useState(false)
	const resetSort = () => {
		setSortField(defaultSortField)
		setSortOrder(defaultSortOrder)
	}

	const [filterValues, setFilterValues] = useState<Record<string, any>>({})
	const updateFilter = (field: FormFieldType, value: number) => {
		if (value === -1) {
			const updateFilters: any = { ...filterValues }
			delete updateFilters[field.key]
			setFilterValues(updateFilters)
		} else {
			setFilterValues({ ...filterValues, [field.key]: value })
		}
	}

	let sortedItems = items
	sortedItems = searchItemsByFields(sortedItems, SearchFields, searchTerm)
	sortedItems = filterItemsByField(sortedItems, filterValues)
	sortedItems = sortItemsByField(sortedItems, FField(sortField, FormFields), sortOrder)

	if (items.length === 0) return loadingSpinner

	const arrayFilters = Object.keys(filterValues)
	const filterText = `Filter${arrayFilters.length > 0 ? `${arrayFilters.length > 1 ? "s" : ""} (${arrayFilters.length})` : ""}`

	return <div>

		<div className="lister-form-options">
			{/* Search */}
			<span>
				{
					SearchFields && <>
						<span className="primary-button small-button">
							{showSearchForm ? <>
								<i className="fas fa-magnifying-glass"></i>
								<span>Search</span>
								<input autoFocus onBlur={() => setShowSearchForm(false)} onChange={(e) => setSearchTerm(e.target.value)} />
							</> :
								<span onClick={() => setShowSearchForm(true)}>
									<i className="fas fa-magnifying-glass"></i>
									<span>Search</span>
									{searchTerm && `: "${searchTerm}"`}
								</span>
							}
						</span>

						{searchTerm && !showSearchForm && <span className="primary-button small-button" onClick={() => setSearchTerm("")}><i className="fas fa-xmark"></i></span>}
					</>
				}
			</span>

			{/* Sort */}
			<span>
				{showSortForm ? <span className="primary-button small-button">
					<i className={`fas fa-${sortOrder === 1 ? "caret-up" : "caret-down"}`}></i>
					<span>Sort By</span>
					<select onChange={(e) => setSortField(e.target.value)}>
						<option value={options?.defaultSortField || "id"} selected={sortField === "id"}>Default</option>
						{SortFields.map(field => {
							let display = field.title
							if (display.length > 32) { display = display.slice(0, 32) + "..." }
							return <option key={field.key} value={field.key} selected={sortField === field.key}>{display}</option>
						})}
					</select>
					<select onChange={(e) => setSortOrder(parseInt(e.target.value))}>
						<option selected={sortOrder === 1} value="1">Asc</option>
						<option selected={sortOrder === -1} value="-1">Desc</option>
					</select>
					<span onClick={() => setShowSortForm(false)}>
						<i className="fas fa-minus-circle"></i>
					</span>
				</span> :
					<span className="primary-button small-button" onClick={() => setShowSortForm(true)}>
						<i className={`fas fa-${sortOrder === 1 ? "caret-up" : "caret-down"}`}></i>
						{
							(sortField === defaultSortField) ?
								<span>Sort</span> :
								<span>Sorted by {FField(sortField, FormFields).title}</span>
						}
					</span>
				}

				{
					(sortField !== defaultSortField || sortOrder !== defaultSortOrder) &&
					<span className="primary-button small-button" onClick={resetSort}><i className="fas fa-xmark"></i></span>
				}
			</span>


			{/* Filters */}
			<span>
				<Modal buttonText={<span><i className="fas fa-filter"></i> {filterText}</span>} titleText="Filter">
					{FilterFields?.map(field => <div className="align-left" key={field.key}>
						<span style={{width:"120px",display:"inline-block", textAlign: "right", padding:".5rem"}}>{field.title}</span>

						<select onChange={(e) => updateFilter(field, parseInt(e.target.value))} value={filterValues[field.key] === undefined ? -1 : filterValues[field.key]}>
							<option value={-1}>-</option>
							{field.options?.valueArray?.map((option, i) => <option key={option} value={i}>{option}</option>)}
						</select>

						{filterValues[field.key] !== undefined ? <span className="primary-button small-button" onClick={() => updateFilter(field, -1)}><i className="fas fa-xmark"></i></span> : ""}
						<br /><br />
					</div>)}
				</Modal>

				{arrayFilters.length > 0 && <span className="primary-button small-button" onClick={() => setFilterValues({})}><i className="fas fa-xmark"></i></span>}
			</span>
		</div>

		<div>
			{sortedItems.length === 0 && "No results."}
			{sortedItems.map(item => <ItemComponent key={item.id} context={context} item={item} />)}
		</div>
	</div>

}

export default Lister
