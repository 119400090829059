import React, { useContext } from "react"

import { ChapterField, printChapterHeading } from "helpers/chapters_helper"
import { createApi, deleteApi, updateApi } from "helpers/api_helper"
import { ChapterType } from "types/chapter"
import { BookEditorContext } from "components/BookEditor/context"

import ToolTip from "components/UI/ToolTip"
import DynamicField from "components/UI/DynamicFormField/DynamicField"

interface ControlBarProps {
	chapter: ChapterType
	open: boolean
	setOpen: (value: boolean) => void
}

const ControlBar = ({ chapter, open, setOpen }: ControlBarProps) => {
	const context = useContext(BookEditorContext)
	const id = chapter.chapter_id
	const deleteChapter = () => { deleteApi("chapters", id, context, chapter.name) }
	const insertChapter = () => { createApi(`chapters/insert/${id}`, {}, context) }
	const mergeChapter = () => { updateApi("chapters/merge", id, {}, context) }
	const breakPart = () => { createApi(`parts/break/${id}`, {}, context) }

	const partOptions = context.parts.map(c => {
		return { name: c.name, id: c.book_part_id }
	})

	const drag = () => {
		context.setDragItem({
			item_id: chapter.chapter_id,
			item_order: chapter.order,
			item_kind: "chapter",
			item_parent: chapter.book_part_id
		})
	}

	const endDrag = () => {
		context.setDragItem(null)
	}


	return <div className="control-title-bar ctb-chapter">

		<div className="control-bar-up">

			<ToolTip text={"Break from here into new part"}>
				<span className="fas fa-file-dashed-line" onClick={breakPart}></span>
			</ToolTip>&nbsp;
			<ToolTip text={"Insert chapter before this"}>
				<span className="fas fa-circle-plus" onClick={insertChapter}></span>
			</ToolTip>&nbsp;
			<ToolTip text={"Merge chapter with the one before"}>
				<span className="fas fa-merge" onClick={mergeChapter}></span>
			</ToolTip>&nbsp;
			<DynamicField
				item={chapter}
				path={"chapters"}
				context={context}
				autosave={true}
				field={{
					key: "book_part_id",
					title: "part",
					description: "",
					example: "",
					kind: "id-select",
					options: { valueArray: partOptions, required: true }
				}}
				display={
					<ToolTip text={"Change part"}>
						<span className="fas fa-swap"></span>
					</ToolTip>}
			/>
		</div>

		<div className="control-bar-left">
			<ToolTip text={"Drag to reorder chapter"}>
				<span className="fas fa-arrows-up-down-left-right" draggable onDragStart={drag} onDragEnd={endDrag} ></span>
			</ToolTip>
		</div>
		<div className="control-bar-right">
			<ToolTip text={"Delete whole chapter"}>
				<span className="fas fa-trash" onClick={deleteChapter}></span>
			</ToolTip>
		</div>

		<div className="control-main-bar">
			<h2 className="control-bar-name">
				{printChapterHeading(chapter)}.&nbsp;
				{ChapterField(chapter, "name", context)}
				{ChapterField(chapter, "order", context)}
			</h2>

			<div className="control-bar-collapse">
				<span className={`fas ${open ? "fa-angle-down" : "fa-angle-up"}`} onClick={() => { setOpen(!open) }}></span>
			</div>
		</div>

	</div>

}

export default ControlBar
