import React from "react"
import Verse from "../../Verse/Verse"
import { ChapterType } from "types/chapter"

const ChapterVerses = ({ chapter }: { chapter: ChapterType}) => {
	return <div style={{padding:"1rem"}}>
		{
			chapter.verses?.map(verse => <Verse verse={verse} key={verse.verse_id} />)
		}
	</div>
}

export default ChapterVerses
