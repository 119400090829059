import TableAxisYCell from "./TableAxisYCell"

import { DastContext } from "../../context"
import React, { useContext } from "react"

function TableAxisY() {
	const { itemSort } = useContext(DastContext)

	return <div className="dast-scroll-y dast-label-y">
		<div>
			{
				itemSort().map((item, itemNumber) =>
					<TableAxisYCell key={item.id}  item={item} itemNumber={itemNumber} />
				)
			}
		</div>
	</div>
}

export default TableAxisY
