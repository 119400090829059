import React from "react"
import "./home.scss"

import Top from "./components/Top"
import ExampleSection from "./components/ExampleSection"
import CategorySection from "./components/Categories"
import BookSection from "./components/Books"
import MetaTags from "components/UI/MetaTags/metaTags"

const Home = () => {
    
	return <>
		<MetaTags url="" />
		<Top />
		<ExampleSection />
		<CategorySection />
		<BookSection />
	</>

}

export default Home
