import React from "react"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { ChapterFields, ChapterType } from "types/chapter"

export const printChapterHeading = (chapter: ChapterType) => {
	return `Chapter${chapter.numbers.length > 1 ? "s" : ""} ${printChapterNumberRange(chapter)}`
}
export const printShortChapterHeading = (chapter: ChapterType) => {
	return `Ch ${printChapterNumberRange(chapter)}`
}

export const printChapterNumberRange = (chapter: ChapterType) => {
	const output: any = []

	const numbers = chapter.numbers.sort((a, b) => a - b )

	numbers.forEach( n => {
		if(output.length === 0) { 
			output.push([n])
			return
		}

		const last = output[output.length - 1]
		const lastNumber = last[last.length - 1]

		if(n === lastNumber+1) last.push(n)
		else output.push([n])
	})

	const outputArray = output.map((row: any) => {
		const first = chapter.numbersDetailed.find(nData => nData.number === row[0])
		let firstDisplay = first.number
		if(first.total > 1) { firstDisplay = `${first.number}${["a","b","c","d","e","f","g","h","i"][first.occurence]}`}

		if(row.length === 1) return firstDisplay
		else {
			const second = chapter.numbersDetailed.find(nData => nData.number === row[row.length-1])
			let secondDisplay = second.number
			if(second.total > 1) { secondDisplay = `${second.number}${["a","b","c","d","e","f","g","h","i"][second.occurence]}`}

			return firstDisplay + "-" + secondDisplay
		}

	})

	return outputArray.join(", ")
}

export const ChapterField = (chapter: any, fieldname: any, context: any) => {
	const field = ChapterFields.find(f => f.key === fieldname)
	if(field) {
		return <DynamicField item={chapter} path={"chapters"} context={context} autosave={true} field={field} />
	}
}