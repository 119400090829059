import React, { useEffect, useState } from "react"

import { Link, useSearchParams } from "react-router-dom"

import { indexApi } from "helpers/api_helper"
import { printStringNumber } from "helpers/verse_helper"
import { loadingSpinner } from "siteSettings"
import PaginatorNumberSelection from "components/UI/Paginator/PaginatorNumberSelection"
import PaginatorResultRange from "components/UI/Paginator/PaginatorResultRange"
import ToolTip from "components/UI/ToolTip"

const SearchText = () => {
	const [searchParams, setSearchParams] = useSearchParams() //controls search term with url

	const [loading, setLoading] = useState(false)

	const [term, setTerm] = useState(searchParams.get("searchText") || "")

	const [page, setPage] = useState(parseInt(searchParams.get("textPage") || "1"))
	const [paginator, setPaginator] = useState({ totalPages: 0, count: 0, pageSize: 0 })
	const [results, setResults] = useState<any[]>([])
	const processResults = (data: any) => {
		setPage(parseInt(data.page || "1"))
		setPaginator({ totalPages: data.totalPages, count: data.count, pageSize: data.pageSize })
		setResults(data.results || [])
	}

	const loadPage = async () => {
		setLoading(true)
		setSearchParams({ searchText: term, textPage: `${page}` })
		await indexApi(`search/texts?term=${term}&page=${page}`, processResults)
		setLoading(false)
	}

	//On component load, check to see if term is set, and if so, run the loadPage. Otherwise, wait for a term.
	useEffect(() => { if (term) { loadPage() } }, [])
	//Update everytime the page changes
	useEffect(() => { loadPage() }, [page])

	return <>
		<div className="search-box" >
			<h3>Search Book Text</h3>
			<p>Searches through all verses and translations to find the term.</p>
			<br /><br />
			<form className="search-search-box" onSubmit={(e) => {
				e.preventDefault()
				loadPage()
			}}>
				<input onChange={(e) => {
					setTerm(e.target.value)
				}} value={term} />
				<button type="submit" className="primary-button secondary-button small-button"><i className="fas fa-arrow-right"></i></button>
			</form>
		</div>

		<h3 className="search-results-header">
			<PaginatorResultRange page={page} paginator={paginator} results={results} />
		</h3>

		{loading ? loadingSpinner :
			results.map((verse: any, i: number) =>
				<div key={verse.verse_id} className="search-result">
					<ToolTip text={`Match percent: ${Math.round( verse.score/.16 ) }%`}>
						#{(paginator.pageSize * (page - 1)) + (i + 1)}.
					</ToolTip>&nbsp;
					<Link to={`/books/${verse.book?.book_id}?chapter=${verse.original_chapter}&verse=${verse.original_start_verse}`}>
						{verse.book?.full_name}, {printStringNumber(verse)}
					</Link>
					<p>{verse.text.split("\n").join(" ")}</p>
				</div>
			)}


		<PaginatorNumberSelection page={page} paginator={paginator} setPage={setPage} />

	</>
}

export default SearchText
