import React from "react"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { VerseFields, VerseType } from "types/verse"
import DynamicField from "components/UI/DynamicFormField/DynamicField"

export const printNumber = (verse: VerseType) => {
	let verseNumber = `${verse.original_start_verse}`
	if (verse.original_start_verse !== verse.original_end_verse) {
		verseNumber += "-" + verse.original_end_verse
	}

	return `${verse.original_chapter}:${verseNumber}.`
}

export const printVerse = (verse: VerseType) => {
	return <p className="align-left verse-line">
		{printNumber(verse)}&nbsp;{convertNewLinesToLineBreaks(verse.text)}
	</p>
}

export const printStringNumber = (verse: VerseType) => {
	let verseNumber = `${verse.original_start_verse}`
	let str = "Verse"
	if (verse.original_start_verse !== verse.original_end_verse) {
		verseNumber += "-" + verse.original_end_verse
		str += "s"
	}

	return `Chapter ${verse.original_chapter}, ${str} ${verseNumber}`
}

export const printVerseString = (verse: VerseType) => {
	return <>{printStringNumber(verse)}:&nbsp;{verse.text.split("\n").join(" ")}</>
}

export const VerseField = (verse: any, fieldname: any, context: any, options: any = {}) => {
	const field = VerseFields.find(f => f.key === fieldname)
	if(field) {
		return <DynamicField item={verse} path={"verses"} context={context} autosave={true} field={field} options={options}/>
	}
}