import React, { useState } from "react"

import axios from "axios"
import { apiPath } from "helpers/helpers"
import { useParams } from "react-router"

import MetaTags from "components/UI/MetaTags/metaTags"

type ResetPasswordParamType = {
	username: string;
	verify_hash: string;
}

const ResetPassword = () => {
	const [password, setPassword] = useState("")
	const [passwordConfirm, setPasswordConfirm] = useState("")
	const [error, setError] = useState("")
	const [confirm, setConfirm] = useState(false)

	const { username, verify_hash } = useParams<ResetPasswordParamType>()

	const submitForm = async (e: React.FormEvent) => {
		e.preventDefault()
		if (password === passwordConfirm) {
			await axios.put(`${apiPath}/users/auth/resetPassword/${username}/${verify_hash}`, { password })
			setConfirm(true)
		} else {
			setError("Please make sure your passwords match.")
		}
	}

	return <div className='tpBlackBg'>
		<MetaTags title={"Reset Your Password"} url={"users/resetPassword"} privatePage />
		<h2>Reset Your Password</h2>
		{confirm ? "Thank you. Please log in with your new password." :
			<form onSubmit={submitForm} style={{ width: "800px", margin: "auto" }}>

				{error || ""}
				<div>
					<label>Password</label>
					<input
						onChange={(e) => setPassword(e.target.value)} type="password"
						name="password" placeholder="password"
						value={password} />
					<div>Please enter.</div>
				</div>
				<div>
					<label>Confirm</label>
					<input
						onChange={(e) => setPasswordConfirm(e.target.value)} type="password"
						name="passwordConfirm" placeholder="Confirm"
						value={passwordConfirm} />
					<div>Please enter.</div>
				</div>
				<button type="submit">Submit</button>
			</form>
		}
	</div>
}

export default ResetPassword
