import React, { useContext } from "react"

import { Col, Row } from "react-bootstrap"
import { createApi } from "helpers/api_helper"
import { VerseType } from "types/verse"
import { TranslationType } from "types/translationVersions"
import { BookEditorContext } from "components/BookEditor/context"
import { TranslationField } from "helpers/translation_helper"

import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"

const TranslationComponent = ({ verse, translation }: { verse: VerseType, translation: TranslationType }) => {
	const context = useContext(BookEditorContext)

	const breakVerse = () => { createApi(`verses/break/${verse.verse_id}/${translation.verse}`, {}, context) }
	translation.id = translation.translation_id

	return <div>
		<Row>
			<Col lg={10}>
				{TranslationField(translation, "chapter", context)}:
				{TranslationField(translation, "verse", context)}
				{TranslationField(translation, "text", context)}

				<NoteForm foreign_class="translation" foreign_id={translation.translation_id} context={context}  />
				<NoteModal notes={translation.notes} context={context} />
			</Col>
			<Col lg={2}>
				<div>
					<div onClick={breakVerse}>Split</div>
					<a href={`https://www.biblegateway.com/verse/en/${context.book.name}%20${translation.chapter}:${translation.verse}`} target="_blank" rel="noreferrer">All Ver.</a><br />
					<a href={`https://www.bibleref.com/${context.book.name.split(" ").join("-")}/${translation.chapter}/${context.book.name.split(" ").join("-")}-${translation.chapter}-${translation.verse}.html`} target="_blank" rel="noreferrer">Info</a><br />
				</div>
			</Col>
		</Row>
	</div>
}

export default TranslationComponent
