import React from "react"
import Book from "./Book/Book"
import { BookContext } from "../bookContext"
import { BookType, FullBookType } from "types/books"
import { CategoryType } from "types/categories"
import { convertNewLinesToLineBreaks } from "helpers/helpers"

let bookCount = 1

const TableOfContentsSubcategory = ({ category }: { category: CategoryType }) => {
	return <div key={category.category_id}>
		<b>The {category.name}</b><br />
		<i>{category.short_description}</i><br />
		{
			category.books.sort((a, b) => a.order - b.order).map((book: BookType) => <div className="toc-book" key={book.book_id}>
				<a href={`#book-${book.book_id}`}>
					#{bookCount++}. The {book.full_name}
				</a>
			</div>)
		}
		{category.subcategories.map((sc: any) => <TableOfContentsSubcategory category={sc} key={sc.category_id} />)}
	</div>
}

const Category = ({ category }: { category: any }) => {
	bookCount = 1
	return <>
		<div className="print-content">
			<div className="pagebreak">
				<h1>The Abridged {category.name}</h1>
				<h3>{category.short_description}</h3>
			</div>

			<div className="pagebreak">
				<h2>About the {category.name}</h2>
				<p>{convertNewLinesToLineBreaks(category.long_description)}</p>
				{/* Written between and other stats? */}
			</div>

			<div className="pagebreak">
				<h2>The Abridged {category.name}</h2>
				<h3>Table of Contents</h3>
				<TableOfContentsSubcategory category={category} />
			</div>

			{category.books.map((book: FullBookType) =>
				<BookContext.Provider key={book.book_id} value={{ book }}>
					<div id={`book-${book.book_id}`}>
						<div id="top-center-display"> </div>
						<Book />
					</div>
				</BookContext.Provider>
			)}

			{category.subcategories.map((sc: any) => <Category category={sc} key={sc.category_id} />)}

		</div>
	</>
}

export default Category
