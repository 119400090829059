import React from "react"

import { NoteType } from "types/note"
import Modal from "components/UI/Modal/Modal"
import NoteDisplay from "../NoteDisplay"

const NoteModal = ({notes, context}: {notes: NoteType[], context: any}) => {
	if(!notes || notes.length === 0) return ""
	return <Modal buttonText={<i className="fas fa-list-check"></i>} titleText="Notes" toolTipText="See Notes">
		<NoteDisplay notes={notes} context={context} />
	</Modal>
}

export default NoteModal
