
import React from "react"
import { DastContextType } from "./types"


export const DastContext = React.createContext<DastContextType>({
	items: [],
	FormFields: [],
	id: "",
	cssId: "",
	options: {},
	visibleItems: [],
	setVisibleItems: () => { return },
	visibleFields: [],
	setVisibleFields: () => { return },
	sortField: "id",
	setSortField: () => { return },
	sortDirection: 1,
	setSortDirection: () => { return },
	showMenu: false,
	setShowMenu: () => { return },
	toggleItem: () => { return },
	toggleField: () => { return },
	toggleSort: () => { return },
	itemSort: () => { return [] },
	fieldSort: () => { return [] }
})