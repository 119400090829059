import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import { Link } from "react-router-dom"
import { indexApi } from "helpers/api_helper"
import { CategoryResponseType } from "types/categories"

const CategorySection = () => {
	const [data, setData] = useState<CategoryResponseType>({all: [], sorted: []})
	useEffect(() => { loadPage() }, [])

	const loadPage = () => { indexApi("categories", setData) }

	return <>
		<div className="dark-stretchy" style={{ padding: "5rem 1rem" }}>
			<Container>
				<h2>All Categories and Subcategories</h2>
				{data.all.map(c => <Link key={c.category_id}
					style={{ padding: ".5rem", margin: ".5rem", display: "inline-block", color: "white" }}
					to={`/categories/${c.name}`}>
					{c.name}
				</Link>)}

			</Container>
		</div>
	</>
}

export default CategorySection
