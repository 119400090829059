import React from "react"

import Title from "./components/Title"
import BookParts from "./components/BookParts"
import TableOfContents from "../TableOfContents/TableOfContents"
import SummaryBackground from "./components/SummaryBackground"

const Book = () => {

	return <>
		<div className="pagebreak">
			<Title />
		</div>
		<div className="pagebreak">
			<SummaryBackground />
		</div>
		<div className="pagebreak">
			<TableOfContents />
		</div>
		<BookParts />
	</>
}

export default Book
