import React from "react"
import { Helmet } from "react-helmet-async"
import { siteFullTitle } from "siteSettings"

const defaultDescription = "A project dedicated to shortening and simplifying the Bible and other religious texts, written for everyone."
const defaultUrl = "https://abridgedbibleproject.com"

const MetaTags = ({ title, description, url, privatePage }: { title?: string, description?: string, url: string, privatePage?: boolean }) => {
	const displayTitle = title ? `${title}- ${siteFullTitle}` : siteFullTitle

	const displayDescription = description || defaultDescription

	return <Helmet>
		<title>{displayTitle}</title>
		<meta property="og:title" content={displayTitle} />

		<meta name="description" content={displayDescription} />
		<meta property="og:description" content={displayDescription} />

		<link rel="canonical" href={`${defaultUrl}/${url}`} />
		<meta property="og:url" content={`${defaultUrl}/${url}`} />

		{privatePage ? <meta name="robots" content="noindex" /> : <meta name="robots" content="index, follow" />}

		<meta property="og:image" content="%PUBLIC_URL%/logo.png" />
	</Helmet>

}

export default MetaTags