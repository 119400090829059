import React, { useContext } from "react"
import ChapterVerses from "./components/ChapterVerses"
import ControlBar from "./components/ControlBar"
import {convertNewLinesToLineBreaks} from "helpers/helpers"
import { ChapterType } from "types/chapter"
import { printShortChapterHeading } from "helpers/chapters_helper"
import { BookContext } from "../../bookContext"

const ChapterComponent = ({ chapter }: { chapter: ChapterType }) => {
	const {book} = useContext(BookContext)
	if (chapter.chapter_id !== undefined) {
		chapter.id = chapter.chapter_id
		return <div id={`chapter-${chapter.chapter_id}`} className="pagebreak">
			<div id="top-center-display">{book.name}, {printShortChapterHeading(chapter)}</div>
			
			<ControlBar chapter={chapter} />
			{chapter.commentary ? <div>
				<h5 className="book-part-title">Commentary</h5>
				<p className="align-left">{convertNewLinesToLineBreaks(chapter.commentary)}</p>
			</div> : ""}
			<ChapterVerses chapter={chapter}  />
		</div>
	} else return ""
}

export default ChapterComponent
