import React from "react"
import { Link } from "react-router-dom"


import { CategoryType } from "types/categories"
import { CategoryField } from "helpers/category_helper"
import { checkRole } from "helpers/helpers"
import Books from "./Books"

interface CategoryProps {
	category: CategoryType;
	context: {
		loadPage: () => void;
	}
}

const Subcategories = ({ category, context }: CategoryProps) => {
	category.id = category.category_id

	return <div className="pv-3">
		{category.subcategories.sort((a, b) => a.order - b.order).map(sc => {
			sc.id = sc.category_id

			return <div key={sc.id}>
				<h3>
					<Link to={`/categories/${sc.name}`}>The {sc.name}</Link>
					[{checkRole(2) && CategoryField(sc, "order", context)}]
				</h3>
				<p>{CategoryField(sc, "short_description", context)}</p>

				<Books category={sc} context={context} />
				<Subcategories category={sc} context={context} />
			</div>
		})}
	</div>
}

export default Subcategories
