import React, { useEffect, useState, useContext } from "react"
import "./index.scss"
import { useParams, useSearchParams } from "react-router-dom"
import Book from "./components/Book/Book"
import { itemApi } from "helpers/api_helper"
import { AppContext } from "structure/AppContext"
import { BookReaderContext } from "./context"
import { emptyBookObject } from "types/books"
import { loadingSpinner } from "siteSettings"
import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {
	const { translationChoice } = useContext(AppContext)
	const { id } = useParams<{ id: string }>()
	if(!id) return ""

	const [urlParams] = useSearchParams()
	const chapter = urlParams.get("chapter")
	const verse = urlParams.get("verse")
	const scrollPosition = {chapter: chapter ? parseInt(chapter) : null, verse: verse ? parseInt(verse) : null}

	const [book, setBook] = useState(emptyBookObject)

	const [showAbridged, rawSetShowAbridged] = useState(localStorage.getItem("book-show-abridged") !== "false")
	const setShowAbridged = (value: boolean) => {
		localStorage.setItem("book-show-abridged", `${value}`)
		rawSetShowAbridged(value)
	}

	const [compareTranslation, rawSetCompareTranslation] = useState(localStorage.getItem("book-show-translation") !== "false")
	const setCompareTranslation = (value: boolean) => {
		localStorage.setItem("book-show-translation", `${value}`)
		rawSetCompareTranslation(value)
	}

	const [showCommentary, rawSetShowCommentary] = useState(localStorage.getItem("book-show-commentary") !== "false")
	const setShowCommentary = (value: boolean) => {
		localStorage.setItem("book-show-commentary", `${value}`)
		rawSetShowCommentary(value)
	}

	const [localTranslationChoice, setLocalTranslationChoice] = useState(translationChoice)

	useEffect(() => {
		if (book.allTranslations.find(t => t.translation_version_id === translationChoice)) {
			setLocalTranslationChoice(translationChoice)
		}
	}, [translationChoice])
	useEffect(() => { loadPage() }, [id, localTranslationChoice, translationChoice])
	const loadPage = () => { itemApi("books", id, setBook, localTranslationChoice) }

	const context = {
		loadPage,
		book,
		showAbridged, setShowAbridged,
		compareTranslation, setCompareTranslation,
		showCommentary, setShowCommentary,
		localTranslationChoice, setLocalTranslationChoice,
		scrollPosition
	}

	return <BookReaderContext.Provider value={context}>
		<MetaTags title={`The Abridged ${book.full_name}`} description={book.description} url={`books/${id}`} />
		<div id="reader-view">
			{book.book_id ? <Book /> : loadingSpinner}
		</div>
	</BookReaderContext.Provider>
}

export default Index
