import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "structure/AppContext"
import { indexApi } from "helpers/api_helper"
import { TranslationVersionType } from "types/translationVersions"

const DefaultTranslationSetting = () => {
	const appContext = useContext(AppContext)
	const { translationChoice, setTranslationChoice } = appContext
	const [translationOptions, setTranslationOptions] = useState([])

	const loadPage = () => {
		indexApi("translation_versions/availableBibleVersions", setTranslationOptions)
	}

	useEffect(() => loadPage(), [])
	return <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
		Default Bible Translation&nbsp;
		<select onChange={(e) => setTranslationChoice(Number.parseInt(e.target.value))}>
			{
				translationOptions.map((t: TranslationVersionType) =>
					<option
						key={t.translation_version_id}
						value={t.translation_version_id}
						selected={t.translation_version_id === translationChoice}>
						{t.abbreviation}
					</option>)
			}
		</select>
	</div>
}

export default DefaultTranslationSetting
