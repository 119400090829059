import React, {useContext} from "react"
import Verse from "../../Verse/Verse"
import { createApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { ChapterType } from "types/chapter"

const ChapterVerses = ({ chapter }: { chapter: ChapterType}) => {
	const context = useContext(BookEditorContext)
	const {book} = context

	const appendVerse = () => { createApi(`verses/append/${ chapter.chapter_id }`, {}, context) }

	const autoAppendVerse = () => {
		const nextVerse = book.untranslated[0]
		if(nextVerse) {
			const data = { 
				chapter_id: chapter.chapter_id,
				original_chapter: nextVerse.chapter,
				original_start_verse: nextVerse.verse,
				original_end_verse: nextVerse.verse
			}
			createApi(`verses/append/${ chapter.chapter_id }`, data, context)
		}
	}

	return <div>
		{chapter.verses.map(verse => <Verse verse={verse} key={verse.verse_id} />)}
		<span onClick={autoAppendVerse} className="primary-button"><span className="fas fa-circle-plus"></span> Auto Add Verse</span>
		<span onClick={appendVerse}><span className="fas fa-plus"></span> Blank Verse</span>
	</div>
}

export default ChapterVerses
