import React from "react"
import "./index.scss"
import { BlankMessage, NewContactMessageFormFields } from "types/contactMessage"
import FormBuilder from "components/UI/FormBuilder/Form"
import { Container } from "react-bootstrap"

import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {
	return <Container id="new-contact-view">
		<MetaTags 
			title={"Contact Us"} 
			description={"Contact The Abridged Bible Project with any thoughts, questions, comments, or concerns."} 
			url="contact" />

		<h1 style={{ padding: "5rem 0" }}>Leave a Message After the Beep</h1>
		<FormBuilder
			FormFields={NewContactMessageFormFields}
			itemData={BlankMessage}
			path="contact"
			method="create"

			options={{
				hideOnSuccess: true,
				successElement: <div>
					<h2>Thank you!</h2>
					<p>Your message has been submitted.</p>
				</div>,
				role: 0
			}}
		/>

	</Container>

}

export default Index
