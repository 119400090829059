import React from "react"
import { Link } from "react-router-dom"

import { Container } from "react-bootstrap"
import { FullTranslationVersionType } from "types/translationVersions"
import { TranslationVersionEditorFields, TranslationVersionField } from "helpers/translation_helper"
import { BookType } from "types/books"

import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"
import ResourceSelect from "components/UI/ResourceSelect/ResourceSelect"
import { checkRole } from "helpers/helpers"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { FField } from "helpers/item_helper"

interface TranslationVersionProps {
	translationVersion: FullTranslationVersionType
	context: {
		loadPage: () => void
		books: BookType[]
	}
}

const TranslationVersion = ({ translationVersion, context }: TranslationVersionProps) => {
	if (!translationVersion.translation_version_id) return ""
	translationVersion.id = translationVersion.translation_version_id

	const redirectBookTranslation = (id: number, name: string) => {
		window.location.replace(`/translations/${translationVersion.abbreviation}/${name}`)
	}

	return <Container style={{ paddingTop: "5rem" }}>
		<Link to={"/translations"}>Back to All Translations</Link><br />

		<NoteForm foreign_class="translation_version" foreign_id={translationVersion.translation_version_id} context={context} />
		<NoteModal notes={translationVersion.notes} context={context} />


		<h1>
			<DynamicField
				item={translationVersion}
				path={"translation_versions"}
				context={context}
				autosave={true}
				field={FField("abbreviation", TranslationVersionEditorFields)}
				callbacks={{
					success: (value) => { window.location.href = `/translations/${value}` }
				}}
			/>
			<br />
			{TranslationVersionField(translationVersion, "name", context)}
		</h1>

		<p>
			{TranslationVersionField(translationVersion, "bible_version", context)},&nbsp;
			{TranslationVersionField(translationVersion, "public_domain", context)}
		</p>

		<p>{TranslationVersionField(translationVersion, "description", context)}</p>

		{/* <DynamicField
			item={translationVersion}
			path={"translation_versions"}
			context={context}
			autosave={true}
			field={{ key: "link", title: "link", kind: "string", description: "", example: "" }}
			display={translationVersion.link ? <a href={`${translationVersion.link}`}>External Link</a> : ""} />


		<p>{TranslationVersionField(translationVersion, "history", context)}</p> */}


		<h2>Books In The {translationVersion.name}</h2>
		{translationVersion.books.sort((a, b) => a.order - b.order).map(b => <span key={b.book_id} style={{ display: "inline-block", padding: ".5rem", margin: ".5rem" }}>
			<Link to={`/translations/${translationVersion.abbreviation}/${b.name}`}> {b.full_name} </Link>
		</span>)}
		<br />
		{checkRole(2) &&
			<ResourceSelect path="books" existing={translationVersion.books} idField="book_id" nameField="name" title="Add Books" callback={redirectBookTranslation} />}

	</Container>


}

export default TranslationVersion
