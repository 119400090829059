import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"

const Top = () => {
    
	return <>
		<Container>
			<div style={{ padding: "5rem 1rem" }}>
				<h1 style={{fontSize: "24px"}}>The Abridged Bible Project</h1>
				<p>Presents</p>
				<h2 style={{fontSize: "48px"}}>The Abridged Bible Version</h2>

				<br />
				<Row>
					<Col lg={4}>
						<h3>Modernize</h3>
						<p>Update vocabulary & metaphors</p>
						<p>Modern phrasing and grammar</p>
						<p>Remove repetition</p>
					</Col>
					<Col lg={4}>
						<h3>Simplify & Shorten</h3>
						<p>Join verses together & paraphrase - verse by verse</p>
						<p>Split books into parts, includes summaries</p>
						<p>Explain major names and places</p>
						<p>Remove minor names and places</p>
					</Col>
					<Col lg={4}>
						<h3>Free Web App</h3>
						<p>Use in browser, no download required, desktop or mobile</p>
						<p>App is always 100% free- all books are available here</p>
						<p>Search the database for canon or non-canon works</p>
						<p>Coming soon - Free Downloads</p>
					</Col>
				</Row>
				<br />
				<br />
				<h3>Including the Tanach, the New Testament, the Apocrypha, Gnostic, and other religious texts.</h3>
				<br />
				<Row>
					<Col lg={3}>
					</Col>
					<Col lg={2}>
						<Link className="primary-button secondary-button d-block" to="/books">All Books</Link>
					</Col>
					<Col lg={2}>
						<Link className="primary-button secondary-button d-block" to="/categories">All Categories</Link>
					</Col>
					<Col lg={2}>
						<Link className="primary-button secondary-button d-block" to="/categories">About</Link>
					</Col>
					<Col lg={3}>
					</Col>
				</Row>
			</div>
		</Container>
		<div className="dark-stretchy">
			<Container>
				WARNING: May be offensive to some viewers.
			</Container>
		</div>
	</>

}

export default Top
