import React from "react"
import "./index.scss"

import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import MetaTags from "components/UI/MetaTags/metaTags"

const Page = () => {

	return <>
		<MetaTags
			title={"Help Us Out"}
			description={"Help contrubute and build to make the Abridged Bible Project awesome!"}
			url="help-the-project" />
		<Container>
			<h1 style={{ padding: "2rem" }}>Want to Help Contribute?</h1>
			<p>Looking for a wide variety of people to help with the project.</p>
			<p>Create a group of talented people who want to express and share.</p>

			<Link className="primary-button" to="/contact">Please Reach Out!</Link>
			<Row>
				<Col lg={4}>
					<h3>Everyone!</h3>
					<p>Beta Testers- General testing</p>
					<p>Readers- Read and comment on the texts</p>
					<p></p>
				</Col>

				<Col lg={4}>
					<h3>Technical</h3>
					<p>QA Testers</p>
					<p>Designers</p>
					<p>Developers (Built on React, Express, Typescript)</p>
				</Col>

				<Col lg={4}>
					<h3>Academic</h3>
					<p>History - Research Deep Dives</p>
					<p>Update manuscript information</p>
					<p>Verify abridged versions</p>
					<p>Collect and catalog other books</p>
				</Col>

				<Col lg={4}>
					<h3>Theological</h3>
					<p>Help balance tradition with text</p>
					<p>Verify abridged versions</p>
				</Col>

				<Col lg={4}>
					<h3>Literature</h3>
					<p>Writing</p>
					<p>Write new and improve existing</p>
					<p></p>
					<p></p>
				</Col>

				<Col lg={4}>
					<h3></h3>
					<p></p>
					<p></p>
					<p></p>
					<p></p>
				</Col>

			</Row>

			<br /><br />
			<Link className="primary-button" to="/contact">Send a Message</Link>
		</Container>
	</>

}

export default Page
