import { DastContext } from "../../context"
import React, { useContext } from "react"

function MenuBody() {
	const {
		items, FormFields, options,
		visibleItems, setVisibleItems,
		visibleFields, setVisibleFields,
		toggleItem, toggleField, toggleSort,
		setShowMenu,
		sortField,
		sortDirection, setSortDirection
	} = useContext(DastContext)

	return <>
		<span className="dast-options-button format-link light-link" style={{ right: "10px" }} onClick={() => setShowMenu(false)}>X</span>
		<span className="dast-options">


			<select onChange={(e) => {
				toggleField( e.target.value )
				e.target.selectedIndex = 0
			}} >
				<option value={"back"} selected={true}>{visibleFields.length === FormFields.length ? "No " : ""}Hidden Fields</option>
				{FormFields.filter(c => !visibleFields.includes(c.key)).map((field) => {
					let display = field.title
					if (options.fieldHeaderDisplay) { display = options.fieldHeaderDisplay(field) }
					if (display.length > 32) { display = display.slice(0, 32) + "..." }

					return <option value={field.key} key={field.title}>
						{display}
					</option>
				})}
			</select>
			{visibleFields.length > 0 ? <span className="format-link light-link" onClick={() => { setVisibleFields([]) }}>Hide all Fields</span> : ""}
			{visibleFields.length !== FormFields.length ? <span className="format-link light-link" onClick={() => { setVisibleFields(FormFields.map(c => c.key)) }}>Show all Fields</span> : ""}
			<br />


			<select onChange={(e) => {
				toggleItem({ id: parseInt(e.target.value) })
				e.target.selectedIndex = 0
			}} >
				<option value={"back"} selected={true}>{visibleItems.length === items.length ? "No " : ""}Hidden Items</option>

				{
					items.filter(r => !visibleItems.includes(r.id))
						.map((r) => {
							let display = `${r.id}`
							if (options.itemHeaderDisplay) { display = options.itemHeaderDisplay(r) }
							return <option value={r.id} key={r.id}>
								{display}
							</option>
						})}
			</select>
			{visibleItems.length > 0 ? <span className="format-link light-link" onClick={() => { setVisibleItems([]) }}>Hide all Items</span> : ""}
			{visibleItems.length !== items.length ? <span className="format-link light-link" onClick={() => { setVisibleItems(items.map(r => r.id)) }}>Show all Items</span> : ""}
			<br />

			<span>Sort By</span>
			<select onChange={(e) => toggleSort( e.target.value )}>
				<option value={ options.defaultSortField || "id" } selected={sortField === "id"}>Default</option>
				{FormFields.map(field => {
					let display = field.title
					if (options.fieldHeaderDisplay) { display = options.fieldHeaderDisplay(field) }
					if (display.length > 32) { display = display.slice(0, 32) + "..." }
					return <option key={field.key} value={field.key} selected={sortField === field.key}>{display}</option>
				})}
			</select>
			<select onChange={(e) => setSortDirection(parseInt(e.target.value))}>
				<option selected={sortDirection === 1} value="1">Asc</option>
				<option selected={sortDirection === -1} value="-1">Desc</option>
			</select>

			<span className="format-link light-link" onClick={() => toggleSort( options.defaultSortField || "id" )}>Reset</span>
		</span>

	</>
}

export default MenuBody
