import React, { useState, useEffect } from "react"
import { checkRole } from "helpers/helpers"
import { updateApi } from "../../../helpers/api_helper"
import "./DynamicField.scss"
import FieldLabel from "./components/FieldLabel"
import { DynamicFieldContextType, DynamicFieldProps } from "./types"
import { FormColors } from "types/forms"
import FieldForm from "./components/FieldForm"
import FieldDisplay from "./components/FieldDisplay"
import ToolTip from "../ToolTip"

let autoSavetimeout: any

const DynamicField = ({ item, path, callback, context, field, autosave, options, display, blankDisplay, label, description, tooltip, callbacks }: DynamicFieldProps) => {
	if (!options) options = {}
	if (!options.role && options.role !== 0) options.role = 2

	const [value, setRawValue] = useState(item[field.key])
	useEffect(() => setRawValue(item[field.key]), [item[field.key]])
	const setValue = (data: any) => {
		doValidation(data)
		setChanged(true)
		setRawValue(data)
		setUnsaved(true)

		if (autosave) {
			clearTimeout(autoSavetimeout)
			autoSavetimeout = setTimeout(() => { }, 1000)
		}
	}
	const [editing, setEditing] = useState(false)
	const [changed, setChanged] = useState(false)
	const [unsaved, setUnsaved] = useState(false)
	const [error, setError] = useState("")
	const [fieldColor, setFieldColor] = useState("transparent")

	const doValidation = (processingValue = value) => {
		if (options?.validation) {
			const validate = options.validation(processingValue)
			if (validate !== true) {
				setError(validate)
				setFieldColor("red")
				return false
			} else {
				setError("")
				setFieldColor("transparent")
				return true
			}
		}
		return true
	}

	const save = async () => {
		clearTimeout(autoSavetimeout)
		const saveValue = value === undefined ? null : value
		if (!doValidation()) return false

		if (path && context) {
			if (await updateApi(path, item.id, { [field.key]: saveValue }, context)) {
				setFieldColor("green")
				setUnsaved(false)
				if(callbacks?.success) callbacks.success(saveValue)
				setTimeout(() => { setFieldColor("transparent") }, 1000)
			} else {
				setFieldColor("red")
				setTimeout(() => { setFieldColor("transparent") }, 1000)
			}
		}
		else if (callback) {
			callback({ ...item, [field.key]: saveValue })
			if(callbacks?.success) callbacks.success(saveValue)
			setUnsaved(false)
		}
	}

	const dynamicFieldContext: DynamicFieldContextType = {
		item, path, callback, context, field, autosave, options, display, blankDisplay, label,
		value, setValue,
		editing, setEditing,
		changed, setChanged,
		error, setError,
		save, autoSavetimeout
	}

	if (!checkRole(options.role)) return <FieldDisplay dynamicFieldContext={dynamicFieldContext} />

	const renderKey = `${item.id}-${field.key}-${item[field.key]}`

	return <span
		onClick={() => setEditing(true)}
		className={`edit-field edit-field-${field.kind} ${unsaved ? "unsaved" : ""}`}
		key={renderKey}>
		<span style={{ backgroundColor: FormColors[fieldColor] }}>

			<FieldLabel dynamicFieldContext={dynamicFieldContext} />

			{tooltip && field.description ? <div className="dynamic-field-tooltip">
				<ToolTip text={field.description} placement="top-end">?</ToolTip>
			</div> : ""}

			{(editing || options.formOnly || options.displayOnly === false) ? <>
				<FieldForm key={renderKey} dynamicFieldContext={dynamicFieldContext} />
				<br />
				{autosave ? "" : <button onClick={save}>Save</button>}
			</>
				: <span tabIndex={0}>
					<FieldDisplay dynamicFieldContext={dynamicFieldContext} />
				</span>}

			{description ? field.description : ""}

			{error ? <div>{error}</div> : ""}
		</span>
	</span>
}

export default DynamicField