import React from "react"
import { createApi } from "helpers/api_helper"
import { BookTranslationVersionType, TranslationType } from "types/translationVersions"

type TranslationImportProps = {
	translationVersion: BookTranslationVersionType
	context: { loadPage: () => void }
	generatedTranslations: TranslationType[]
}

const AITranslationImport = ({ context, translationVersion, generatedTranslations }: TranslationImportProps) => {
	const { book } = translationVersion

	let textString = ""
	generatedTranslations.forEach( t => {
		if(t) textString += `${t.chapter}:${t.verse}. ${t.text}\n`
	})

	const callImport = () => {
		createApi(`import/verses/${book.book_id}`, { verses: textString }, context)
	}

	return <div className="align-left">
		{ generatedTranslations.length > 0  && <span className="primary-button" onClick={callImport}>Accept Translation</span> }
	</div>

}

export default AITranslationImport
