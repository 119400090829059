import { FormFieldType } from "./forms"

export interface NewUserFormTypes {
	username: string
	password: string
	user_email: string
	mailing_list: boolean
}

export const newUserData: NewUserFormTypes = {
	username: "",
	password: "",
	user_email: "",
	mailing_list: true
}

const validateEmail = (email: string) => {
	const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //eslint-disable-line
	if (!email.match(mailformat)) { return "Please enter a valid email address." }
	return true
}

export const RegisterUserFormFields: FormFieldType[] = [
	{
		key: "username",
		title: "Choose a unique unsername",
		description: "",
		example: "",
		kind: "string",
		options: { required: true }
	},
	{
		key: "password",
		title: "Choose a password",
		description: "",
		example: "",
		kind: "password",
		options: { required: true }
	},
	{
		key: "user_email",
		title: "Enter Your Email",
		description: "",
		example: "",
		kind: "string",
		options: { required: true, validation: validateEmail }
	},
	{
		key: "mailing_list",
		title: "Get Email Updates",
		description: "",
		example: "",
		kind: "boolean",
		options: { required: true }
	},
]

export interface UserType {
	user_id: number
	user_role: number
	username: string
	user_email: string
	mailing_list: boolean
	user_verified: boolean
}