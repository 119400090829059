import { ChapterType } from "./chapter.js"
import { FormFieldType } from "./forms.js"
import { NoteType } from "./note.js"


export interface PartType {
	book_part_id: number
	id: number
	book_id: number

	name: string
	order: number
	summary: string
	commentary: string
	
	notes: NoteType[]
	chapters: ChapterType[]
}

export const PartFields: FormFieldType[] = [
	{
		key: "name",
		title: "name",
		description: "",
		example: "",
		kind: "string"
	},
	{
		key: "order",
		title: "order",
		description: "",
		example: "",
		kind: "number"
	},
	{
		key: "summary",
		title: "summary",
		description: "",
		example: "",
		kind: "text"
	},
	{
		key: "commentary",
		title: "commentary",
		description: "",
		example: "",
		kind: "text"
	}
]