import React, { useContext } from "react"
import { BookEditorContext } from "components/BookEditor/context"
import { BookField } from "helpers/books_helper"
import NoteForm from "components/Notes/NoteForm/NoteForm"
import NoteModal from "components/Notes/NoteModal"

const Title = () => {
	const context = useContext(BookEditorContext)
	const { book } = context

	return <div style={{ padding: "5rem 0 1rem" }}>

		<NoteForm foreign_class="book" foreign_id={book.book_id} context={context}  />
		<NoteModal notes={book.notes} context={context} />

		<h1>
			#{BookField(book, "order", context)}. {BookField(book, "name", context)}
		</h1>

		<div>
			Names:
			{BookField(book, "full_name", context)}
			{BookField(book, "abbreviation", context)}
			{BookField(book, "alternate_names", context)}
		</div>

		<div>
			{BookField(book, "description", context)}
		</div>

	</div>

}

export default Title
