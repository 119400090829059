import React, { useState } from "react"
import "./index.scss"

import { BlankNote, NewNoteFields, NoteForeignClassType } from "types/note"

import FormBuilder from "components/UI/FormBuilder/Form"
import Modal from "components/UI/Modal/Modal"

const NoteForm = ({ foreign_class, foreign_id, context }: { foreign_class: NoteForeignClassType, foreign_id: number, context: any }) => {
	const [formSubmission, setFormSubmission] = useState(false)
	const success = () => { 
		setFormSubmission(true)
		setTimeout( () => { 
			setFormSubmission(false) 
			context.loadPage()
		}, 500 )
	}

	return <Modal titleText="Leave Note" buttonText={<i className="fas fa-pencil"></i>} toolTipText={"Leave suggestion, correction, or note"} showState={formSubmission}>
		<FormBuilder FormFields={NewNoteFields} path={"notes"} itemData={{ ...BlankNote, foreign_class, foreign_id }} method={"create"} callbacks={{success}}/>
	</Modal>
}

export default NoteForm
