import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import { Link } from "react-router-dom"

import { smartSortBooksByAlphaShortName } from "helpers/books_helper"
import { indexApi } from "helpers/api_helper"
import { BookType } from "types/books"

const BookSection = () => {
	const [books, setBooks] = useState<BookType[]>([])

	useEffect(() => { loadPage() }, [])

	const loadPage = () => {
		indexApi("books", setBooks)
	}

	return <>
		<Container style={{ padding: "5rem 1rem" }}>
			<h2>The Library</h2>
			{smartSortBooksByAlphaShortName(books).filter(b => b.abridged_percent === 100).slice(0, 25).map((c: BookType) => <Link key={c.book_id}
				style={{ padding: ".5rem", margin: ".5rem", display: "inline-block", opacity: (c.abridged_percent / 66) + .33 }}
				to={`/books/${c.book_id}`}>
				{c.full_name}
			</Link>)}
			<div>
				<Link className="primary-button" to={"/books"}>See All Books</Link>
			</div>
		</Container>
	</>
}

export default BookSection
