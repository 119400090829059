import React from "react"
import { Routes, Route } from "react-router-dom"

import CategoriesListComponent from "./Index/index"
import CategoryComponent from "./View/index"

const Categories = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>    
				<Route path="" element={<CategoriesListComponent />} />
				<Route path=":id" element={<CategoryComponent />} />
			</Routes>
		</div>
	</div>
}

export default Categories
