import TableDataItem from "./TableDataItemRow"

import { DastContext } from "../../context"
import React, { useContext } from "react"

function TableData() {
	const { itemSort }= useContext(DastContext)

	return <div className="dast-data-container dast-scroll-x dast-scroll-y">
		{
			itemSort().map((item, itemNumber) => 
				<TableDataItem key={item.id}  item={item} itemNumber={itemNumber} />
			)
		}
	</div>
}

export default TableData
