import React from "react"

import { TranslationType } from "types/translationVersions"
import TranslationSuggestion from "./TranslationSuggestion"

interface TranslationVersionProps {
	translations: TranslationType[]
}

const TranslationsSuggestions = ({ translations }: TranslationVersionProps) => {
	let currentChapter = -1

	const printChapter = (chapter: number) => {
		if (chapter !== currentChapter) {
			currentChapter = chapter
			return <h3>Chapter {chapter}</h3>
		} else { return "" }
	}

	return translations.map(translation => <>
		{printChapter(translation.chapter)}
		<TranslationSuggestion key={translation.translation_id} translation={translation} />
	</>)
}

export default TranslationsSuggestions
