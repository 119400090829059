import React from "react"
import "./index.scss"

import { Col, Container, Row } from "react-bootstrap"

import { BookExplanationFields } from "types/books"

import { Link } from "react-router-dom"
import ExplanationTable from "components/UI/ExplanationTable/ExplanationTable"
import MetaTags from "components/UI/MetaTags/metaTags"

const Page = () => {
	return <>
		<MetaTags title={"Help and FAQ"} url="help" />
		<Container>
			<h1 style={{ padding: "2rem" }}>Help and How to Use</h1>
			<h2>Overview</h2>
			<p>First and foremost, you can think of this site as a library for religious books.</p>

			<Row>
				<Col lg={6}>
					<h3>Books</h3>
					<p>Click on a book from the <Link to="/books">Library</Link> to read it.</p>
					<p>There&apos;s menu options at the top that allows you to control the abridged version and the original translation, if you want to compare the two side-by-side.</p>
					<p>You can use the Table of Contents to navigate to a certain chapter.</p>
					<Link className="primary-button small-button" to="/books">See Library</Link>
				</Col>
				<Col lg={6}>

					<h3>Search</h3>
					<p>
						Looking to see if we have a specific book? Or where a certain word appears throughout all texts? <Link to="/search">Look it up!</Link> You
						can either search by book name, or search for phrases within the text.
					</p>
					<Link className="primary-button small-button" to="/search">Search</Link>

				</Col>
				<Col lg={6}>
					<h3>Categories</h3>
					<p>There are tons of different books- and categories and subcategories are given for organization.</p>
					<p>The biggest two are the Tanak, or Hebrew Bible, and the New Testament, the Christian collection,
						but those categories have subdivisions, and there are more categories outside of those, too!</p>
					<Link className="primary-button small-button" to="/categories">See Categories</Link>

				</Col>
				<Col lg={6}>
					<h3>Translation Verions</h3>
					<p>For the canon bible, verions are easy- there are several public domain bible verions, including the King James, American Standard, and English Standard.</p>
					<p>We have several public domain versions availble, and information about a few others.</p>
					<p>If a verion is both public domain and a complete bible verision, you can set it as the default translation in the menu.</p>
					<p>For texts that aren&apos;t canon- things get a little trickier. We are currently expanding to include accurate resources to the English translation.</p>
					
					<Link className="primary-button small-button" to="/translations">See Translations</Link>
				</Col>
			</Row>


			<h2>Book Information</h2>
			<p>Information fields we have for each text.</p>
			<ExplanationTable fields={BookExplanationFields} />


			<br /><br />
			<p>Have a Question or Comment?</p>
			<Link className="primary-button" to="/contact">Contact</Link>

		</Container>
	</>

}

export default Page
