import { FormFieldType } from "./forms.js"
import { TranslationType } from "./translationVersions.js"
import { NoteType } from "./note.js"

export interface VerseType {
	verse_id: number
	id: number
	chapter_id: number
	book_id: number

	order: number
	commentary: string

	text: string
	subtitle: string
	original_chapter: number
	original_start_verse: number
	original_end_verse: number

	translations: TranslationType[]
	notes: NoteType[]
}

export const VerseFields: FormFieldType[] = [
	{
		key: "order",
		title: "order",
		description: "",
		example: "",
		kind: "number"
	},
	{
		key: "commentary",
		title: "commentary",
		description: "",
		example: "",
		kind: "text"
	},

	{
		key: "text",
		title: "text",
		description: "",
		example: "",
		kind: "text"
	},
	{
		key: "subtitle",
		title: "subtitle",
		description: "",
		example: "",
		kind: "string"
	},
	{
		key: "original_chapter",
		title: "original_chapter",
		description: "",
		example: "",
		kind: "number",
		options: { widthSize: 3 }
	},
	{
		key: "original_start_verse",
		title: "original_start_verse",
		description: "",
		example: "",
		kind: "number",
		options: { widthSize: 3 }
	},
	{
		key: "original_end_verse",
		title: "original_end_verse",
		description: "",
		example: "",
		kind: "number",
		options: { widthSize: 3 }
	},
]