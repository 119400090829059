import React, { useEffect, useState } from "react"
import "./index.scss"
import { useParams } from "react-router-dom"
import CategoryComponent from "./components/Category"
import { indexApi, itemApi } from "helpers/api_helper"
import { CategoryType, CategoryResponseType } from "types/categories"
import { loadingSpinner } from "siteSettings"

import MetaTags from "components/UI/MetaTags/metaTags"


const Index = () => {
	const { id } = useParams<{ id: string }>()
	if(!id) return ""

	const [category, setCategory] = useState<CategoryType | null>(null)
	const [categories, setCategories] = useState<CategoryResponseType>({ all: [], sorted: [] })

	useEffect(() => { loadPage() }, [id])

	const loadPage = () => {
		itemApi("categories", id, setCategory)
		indexApi("categories", setCategories)
	}
	const context = {
		loadPage
	}
	

	if (!category) { return loadingSpinner }
	return <div id="category-view">
		<MetaTags title={category.name} description={category.short_description} url={`categories/${id}`} />

		<CategoryComponent category={category} context={context} categories={categories.all || []} />
	</div>

}

export default Index
