import React, { useContext } from "react"
import { bookProgressStatus } from "helpers/books_helper"
import { PrintTranslations } from "helpers/translation_helper"
import { BookEditorContext } from "components/BookEditor/context"

const TranslationStatus = () => {
	const { book } = useContext(BookEditorContext)

	return <div key={book.untranslated.length}>
		<h2>Original Translation</h2>
		{bookProgressStatus(book)}
		<PrintTranslations translations={book.untranslated} />
	</div>

}

export default TranslationStatus
