import React from "react"

interface PrinterContextType {
	showAbridged: boolean
	compareTranslation: boolean
	showCommentary: boolean
}

export const PrinterContext = React.createContext<PrinterContextType>({
	showAbridged: true,
	compareTranslation: true,
	showCommentary: true
})