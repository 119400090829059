import React, { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"

import { smartSortBooksByAlphaShortName, completeTitle } from "helpers/books_helper"
import { indexApi } from "helpers/api_helper"
import { BookType } from "types/books"
import { loadingSpinner } from "siteSettings"
import PaginatorNumberSelection from "components/UI/Paginator/PaginatorNumberSelection"
import PaginatorResultRange from "components/UI/Paginator/PaginatorResultRange"

const SearchBooks = () => {
	const [loading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams() //controls search term with url

	const [term, setTerm] = useState(searchParams.get("searchTitle") || "")

	const [page, setPage] = useState(parseInt(searchParams.get("titlePage") || "1"))
	const [paginator, setPaginator] = useState({ totalPages: 0, count: 0, pageSize: 0 })
	const [results, setResults] = useState<BookType[]>([])
	const processResults = (data: any) => {
		setPage(parseInt(data.page || "1"))
		setPaginator({ totalPages: data.totalPages, count: data.count, pageSize: data.pageSize })
		setResults(data.results)
	}

	const loadPage = async () => {
		setLoading(true)
		setSearchParams({ searchTitle: term, titlePage: `${page}` })
		await indexApi(`search/books?term=${term}&page=${page}`, processResults)
		setLoading(false)
	}

	//On component load, check to see if term is set, and if so, run the loadPage. Otherwise, wait for a term.
	useEffect(() => { if (term) { loadPage() } }, [])
	//Update everytime page and term change
	useEffect(() => { loadPage() }, [page, term])

	return <>
		<div className="search-box" >
			<h3>Search Books By Name</h3>
			<p>Searches through all book names and alternate names.</p>
			<br /><br />
			<div className="search-search-box">
				<input onChange={(e) => {
					setTerm(e.target.value)
					setPage(1)
				}} value={term} />
				<div className="primary-button secondary-button small-button" onClick={() => loadPage()}><i className="fas fa-arrow-right"></i></div>
			</div>
		</div>

		<h3 className="search-results-header">
			<PaginatorResultRange page={page} paginator={paginator} results={results} />
		</h3>

		{loading ? loadingSpinner : <div>
			{results.length === 0 && term != "" ? "No results." : ""}
			{smartSortBooksByAlphaShortName(results).map((c: BookType, i: number) =>
				<div key={c.book_id} className="search-result">
					<Link key={c.book_id} to={`/books/${c.book_id}`}>
						<b>#{(paginator.pageSize * (page-1)) + (i + 1)}.</b> {completeTitle(c)}
					</Link>
					
					<p>{c.description}</p>
				</div>
			)}

			<PaginatorNumberSelection page={page} paginator={paginator} setPage={setPage} />

		</div>}

	</>
}

export default SearchBooks


