import { BookContext } from "../../../bookContext"
import React, { useContext } from "react"
import "./Title.scss"
import { authorText, preservationText, printAcademicYearRangeText, printSettingYearRangeText } from "helpers/books_helper"

const Title = () => {
	const { book } = useContext(BookContext)

	return <div className="print-book-title">
		<h3>The Abridged</h3>
		<h1>{book.full_name}</h1>
		<br />
		<h4>
			{authorText(book)}<br />
			{printAcademicYearRangeText(book)}<br />
			{printSettingYearRangeText(book)}<br />
			Text {preservationText(book)}<br />
			{book.alternate_names && "Also known as: " + book.alternate_names}
		</h4>
		<br />
		<h3>{book.description}</h3>
	</div>
}

export default Title
