import React from "react"
import { TranslationType } from "types/translationVersions"

interface TranslationVersionProps {
	translation: TranslationType;
}

const TranslationSuggestion = ({ translation }: TranslationVersionProps) => {
	return <div className="align-left">
		{translation.chapter}:{translation.verse}. {translation.text}
	</div>
}

export default TranslationSuggestion
