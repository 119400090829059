import React from "react"
import { CategoryType } from "types/categories"
import Book from "./Book"

interface CategoryProps {
	category: CategoryType;
	context: {
		loadPage: () => void;
	}
}

const BookSection = ({ category, context }: CategoryProps) => {
	const books = category.books.sort((a, b) => a.order - b.order)

	return <>
		{books.map(book => <Book key={book.book_id} book={book} context={context} category={category} />)}
	</>
}



export default BookSection
