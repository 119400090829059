
import React, { PropsWithChildren } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/types"

type ToolTipProps = { 
	text: string, 
	placement?: Placement 
	variation?: string
}

export const ToolTip = ({ text, children, placement, variation }: PropsWithChildren<ToolTipProps>) => {
	const placementString: Placement = placement || "top"
	if(!text) return children
	return <OverlayTrigger placement={placementString} overlay={<Tooltip className={`${variation || ""}`}>{text}</Tooltip>}>
		<span style={{ cursor: "pointer" }}>
			{children}
		</span>
	</OverlayTrigger>
}

export default ToolTip