import { BookType } from "./books.js"
import { FormFieldType } from "./forms.js"
import { NoteType } from "./note.js"

export interface BookCategoryType extends BookType {
	books_to_category_id: number
}

export interface CategoryType {
	category_id: number
	id: number

	name: string
	short_description: string
	long_description: string

	parent_id: number

	order: number
	subcategories: CategoryType[]
	books: BookCategoryType[]
	allBooks: BookCategoryType[]
	bookAmount: number

	notes: NoteType[]
}

export const CategoryEditorFields: FormFieldType[] = [
	{
		key: "name",
		title: "name",
		description: "",
		example: "",
		kind: "string",
	},
	{
		key: "short_description",
		title: "short_description",
		description: "",
		example: "",
		kind: "string",
	},
	{
		key: "long_description",
		title: "long_description",
		description: "",
		example: "",
		kind: "text",
	},
	{
		key: "order",
		title: "order",
		description: "",
		example: "",
		kind: "number",
	},
]

export type CategoryResponseType = {
	all: CategoryType[]
	sorted: CategoryType[]
}