import MenuBackground from "./MenuBackground"
import { DastContext } from "../../context"
import React, { useContext } from "react"

function Menu() {
	const {showMenu, setShowMenu} = useContext(DastContext)
	return <>

		<div className="dast-options-button format-link" onClick={() => setShowMenu(true) }>Options</div>

		{ showMenu ? <MenuBackground /> : "" }

	</>
}

export default Menu
