import React, { useEffect, useState } from "react"
import "./index.scss"
import { useParams } from "react-router-dom"
import TranslationVersion from "./components/TranslationVersion"
import { indexApi, itemApi } from "helpers/api_helper"
import { loadingSpinner } from "siteSettings"
import { BookTranslationVersionType } from "types/translationVersions"

import MetaTags from "components/UI/MetaTags/metaTags"


const Index = () => {
	const { id, book_id } = useParams<{ id: string, book_id: string }>()
	if(!id || !book_id) return ""
	

	const [translationVersion, setTranslationVersion] = useState<BookTranslationVersionType | null>(null)
	const [translationVersions, setTranslationVersions] = useState<BookTranslationVersionType[]>([])

	useEffect(() => { loadPage() }, [id])

	const loadPage = () => {
		itemApi(`translation_versions/${id}`, book_id, setTranslationVersion)
		indexApi("translation_versions", setTranslationVersions)
	}
	const context = {
		loadPage,
		translationVersions
	}

	if (!translationVersion) { return loadingSpinner }
	return <div>
		<MetaTags title={""} description={""}  url={`translations/${id}/${book_id}`}  />
		<TranslationVersion translationVersion={translationVersion} context={context} />
	</div>

}

export default Index
