import React, { useContext } from "react"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { BookContext } from "../../../bookContext"

const SummaryBackground = () => {
	const { book } = useContext(BookContext)

	return <div>
		<div id="top-center-display">{book.name}</div>
		<div>
			{book.history ? <div>
				<div className="control-title-bar ctb-chapter">
					<div className="control-main-bar">
						<h3 className="control-bar-name">History</h3>
					</div>
				</div>
				<p className="align-left">{convertNewLinesToLineBreaks(book.history)}</p>
			</div> : ""}

			{book.summary ? <div>
				<div className="control-title-bar ctb-chapter">
					<div className="control-main-bar">
						<h3 className="control-bar-name">Summary</h3>
					</div>
				</div>

				<p className="align-left">{convertNewLinesToLineBreaks(book.summary)}</p>
			</div> : ""}

		</div>
	</div>

}

export default SummaryBackground
