import React from "react"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { printChapterHeading } from "helpers/chapters_helper"
import { ChapterType } from "types/chapter"

const ControlBar = ({ chapter }: { chapter: ChapterType }) => {

	return <div className="control-title-bar ctb-chapter">
		<div className="control-main-bar">
			<h3 className="control-bar-name">
				{printChapterHeading(chapter)}
			</h3>
			{chapter.summary ? <div>
				<p className="align-left">{convertNewLinesToLineBreaks(chapter.summary)}</p>
			</div> : ""}
			
			<hr />
		</div>

	</div>

}

export default ControlBar
