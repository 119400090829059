import React from "react"
import { TranslationVersionType } from "types/translationVersions"
import { Link } from "react-router-dom"
import { bible_version_print, public_domain_print } from "helpers/translation_helper"
import { deleteApi } from "helpers/api_helper"
import { checkRole } from "helpers/helpers"
import { Col } from "react-bootstrap"

interface TranslationVersionProps {
	translationVersion: TranslationVersionType
	context: { loadPage: () => void }
}

const TranslationVersionComponent = ({ translationVersion, context }: TranslationVersionProps) => {
	if (!translationVersion) return ""
	translationVersion.id = translationVersion.translation_version_id

	const deletePath = () => { deleteApi("translation_versions", translationVersion.id, context, translationVersion?.name) }

	return <Col xs={12} lg={6} className="translationVersion-item pv-1" key={translationVersion.translation_version_id}>
		<Link to={`/translations/${translationVersion.abbreviation || translationVersion.translation_version_id}`}>

			<h2>{translationVersion.name} [{translationVersion.abbreviation}]</h2>

			<p>{bible_version_print(translationVersion)}, {public_domain_print(translationVersion)}</p>
			<p>{translationVersion.description}</p>

		</Link>
		{checkRole(2) ? <span className="primary-button small-button" onClick={() => deletePath()}><i className="fas fa-trash"></i></span> : ""}
	</Col>
}

export default TranslationVersionComponent
