import React from "react"
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import {
	printAcademicYearRange,
	authorText,
	preservationText,
	calculateLengthValue,
	publicDomainButton,
	abridgedProgressButton,
	printSettingYearRange
} from "helpers/books_helper"
import { BookType } from "types/books"
import ToolTip from "components/UI/ToolTip"

const BookComponent = ({ book }: { book: BookType }) => {
	book.id = book.book_id

	return <div className="book-item " key={book.book_id}>
		<Row className="book-index-row">
			<Col xs={12} className="book-name">
				<Link className="book-row-link" to={`/books/${book.name}`}>
					<span className="book-order">#{book.order}. </span> {book.full_name}
				</Link>
			</Col>

			<Col xs={12} className="book-preservation align-left">
				<ToolTip text={"Who does it say wrote the book, and what do scholars and theologians think about it?"}>
					{authorText(book)}
				</ToolTip>
			</Col>

			<Col xs={12} className="align-left">
				{book.description}
			</Col>

			<Col xs={6} className="book-preservation align-left">
				<ToolTip text={"What years the book takes place, based on the timeline presented in the books."}>
					Set {printSettingYearRange(book)}
				</ToolTip>
			</Col>

			<Col xs={6} className="book-preservation align-right">
				<ToolTip text={"What years scholars tend to agree the book was first written down."}>
					Written {printAcademicYearRange(book)}
				</ToolTip>
			</Col>

			<Col xs={6} className="book-preservation align-left">
				<ToolTip text={"How much has the document been preserved? How much have we found, and how much is missing?"}>
					{preservationText(book)}
				</ToolTip>
			</Col>

			<Col xs={6} className="book-preservation align-right">
				<ToolTip text={"How long it takes to read the book on a scale of 1-10."}>
					Length: {calculateLengthValue(book)}/10
				</ToolTip>
			</Col>

			{
				book.preservation_amount === 0 ?
					<Col xs={12}>LOST</Col>
					:
					<>
						<Col xs={6}>
							{abridgedProgressButton(book)}
						</Col>
						<Col xs={6}>
							{publicDomainButton(book)}
						</Col>
					</>
			}

		</Row>
	</div >
}

export default BookComponent
