import { printShortChapterHeading } from "helpers/chapters_helper"
import { PartType } from "types/parts"
import { scrollToId } from "helpers/helpers"
import React from "react"

interface PartProps {
	part: PartType
	setMenuOpen: (value: boolean) => void
}

const PartComponent = ({ part, setMenuOpen }: PartProps) => {

	return <>
		<div className="toc-parts-slot">Pt {part.order}{part.name ? `: ${part.name}` : ""}</div>
		{part.chapters?.map(chapter => 
			<div key={chapter.chapter_id} className="format-link light-link" style={{ paddingLeft: "2rem" }} 
				onClick={(e)=>{  setMenuOpen(false); scrollToId(`chapter-${chapter.chapter_id}`, e) }}>
				{printShortChapterHeading(chapter)}. {chapter.name}
			</div>
		)}
	</>

}

export default PartComponent
