import React, { useState } from "react"
import { createApi } from "helpers/api_helper"
import { BookTranslationVersionType } from "types/translationVersions"
import { Row, Col } from "react-bootstrap"

type TranslationImportProps = {
	translationVersion: BookTranslationVersionType
	context: { loadPage: () => void }
}

const TranslationImport = ({ context, translationVersion }: TranslationImportProps) => {
	const { book } = translationVersion

	const [verseText, setVerseText] = useState("")

	const callImport = () => {
		createApi(`import/translation/${book.book_id}/${translationVersion.translation_version_id}`, { text: verseText }, context)
	}

	return <div className="align-left">

		<h3>Mass Translation Import</h3>
		<Row>
			<Col lg={3}>
				-One verse per newline<br />
				-Formatted:<br />
				1<br />
				Text<br />
				Text<br />
				Text<br />
				2<br />
				Text<br />
				Text<br />
				Text<br />
				<br />

				<span className="primary-button" onClick={callImport}>Submit</span>
			</Col>
			<Col lg={9}>
				<textarea value={verseText} style={{ width: "100%", height: "300px" }} onChange={(e) => setVerseText(e.target.value)}>
				</textarea>
			</Col>
		</Row>


	</div>

}

export default TranslationImport
