import React, { useContext } from "react"

import { Col, Row } from "react-bootstrap"
import Dropspace from "../Dropspace"
import Translation from "../Translation/Translation"

import { createApi, deleteApi, updateApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"
import { VerseType } from "types/verse"
import ToolTip from "components/UI/ToolTip"
import { VerseField } from "helpers/verse_helper"
import DynamicField from "components/UI/DynamicFormField/DynamicField"

import NoteModal from "components/Notes/NoteModal"
import NoteForm from "components/Notes/NoteForm/NoteForm"

const VerseComponent = ({ verse }: { verse: VerseType }) => {
	const context = useContext(BookEditorContext)
	const { chapters, setDragItem, compareTranslation } = context

	const id = verse.verse_id
	const deleteVerse = () => { deleteApi("verses", id, context, `${id}`) }
	const insertVerse = () => { createApi(`verses/insert/${id}`, {}, context) }
	const mergeVerse = () => { updateApi("verses/merge", id, {}, context) }
	const breakChapter = () => { createApi(`chapters/break/${id}`, {}, context) }

	const chapterOptions = chapters.map(c => {
		return { name: c.name, id: c.chapter_id }
	})

	const drag = () => {
		setDragItem({
			item_id: verse.verse_id,
			item_order: verse.order,
			item_kind: "verse",
			item_parent: verse.chapter_id
		})
	}

	const endDrag = () => {
		setDragItem(null)
	}

	const textHTML = <div className="align-left" style={{ width: "100%" }}>
		<div className="verse-info-options">
			<div>
				{VerseField(verse, "original_chapter", context)}:
				{VerseField(verse, "original_start_verse", context)}-
				{VerseField(verse, "original_end_verse", context)}
			</div>
			<div>
				{VerseField(verse, "subtitle", context)}
			</div>
		</div>
		<p>{VerseField(verse, "text", context)}</p>
	</div>

	if (verse.verse_id !== undefined) {
		verse.id = verse.verse_id
		return <div>

			{verse.subtitle ? <h4>{verse.subtitle}</h4> : ""}

			<Dropspace kind={"verse"} order={verse.order-1} parent={verse.chapter_id} />

			<div className="control-title-bar ctb-verse">

				<div className="control-bar-up">
					<ToolTip text={"Break past here to a new chapter"}>
						<span className="fas fa-file-dashed-line" onClick={breakChapter}></span>
					</ToolTip>&nbsp;
					<ToolTip text={"Insert verse before"}>
						<span className="fas fa-circle-plus" onClick={insertVerse}></span>
					</ToolTip>&nbsp;
					<ToolTip text={"Merge verse with previous"}>
						<span className="fas fa-merge" onClick={mergeVerse}></span>
					</ToolTip>&nbsp;

					<DynamicField
						item={verse}
						path={"verses"}
						context={context}
						autosave={true}
						field={{
							key: "chapter_id",
							title: "chapter",
							description: "",
							example: "",
							kind: "id-select",
							options: { valueArray: chapterOptions, required: true }
						}}
						display={
							<ToolTip text={"Change chapter"}>
								<span className="fas fa-swap"></span>
							</ToolTip>}
					/>

				</div>

				<div className="control-bar-left">
					<ToolTip text={"Drag to change order"}>
						<span className="fas fa-arrows-up-down-left-right" draggable onDragStart={drag} onDragEnd={endDrag} ></span>
					</ToolTip>
				</div>
				<div className="control-bar-right">
					<ToolTip text={"Delete verse"}>
						<span className="fas fa-trash" onClick={deleteVerse}></span>
					</ToolTip>
				</div>

				<div className="control-main-bar">

					{compareTranslation ?
						<Row>
							<Col lg={5}>
								{textHTML}
								<NoteForm foreign_class="verse" foreign_id={verse.verse_id} context={context}  />
								<NoteModal notes={verse.notes} context={context} />
							</Col>
							<Col lg={7}>
								<p className="align-left">
									{verse.translations.map(t => <Translation verse={verse} key={t.translation_id} translation={t} />)}
								</p>
							</Col>
						</Row> : textHTML}

				</div>

				<p>{VerseField(verse, "commentary", context)}</p>
			</div>

			<Dropspace kind={"verse"} order={verse.order} parent={verse.chapter_id} />

		</div>
	} else return ""
}

export default VerseComponent
