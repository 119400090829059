import React from "react"
import "./index.scss"

import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import MetaTags from "components/UI/MetaTags/metaTags"

const Page = () => {

	return <>
		<MetaTags title={"About The Project"} url="about" />
		<Container>
			<h1 style={{ padding: "2rem" }}>About Us</h1>

			<h2>The Abridged Bible Project</h2>
			<p>
				&quot;The Abridged Bible Project&quot; is the name of the (currently one-person) group producing &quot;The Abridged Bible Version&quot;, or [ABV].
			</p>
			<p>
				The Abridged Bible Project has a few goals in mind, but the primary goal is the complete abridging of the 66-Book Canon Bible.
				To date, the entire New Testament and about a third of the Tanach is completed. It is coming soon as a free download-
				though it is available now here on the site.
			</p>

			<h3>Why the ABV Bible</h3>
			<h4>About the Founder (Of the Project)</h4>
			<p>
				I grew up in a fundamentalist environment, and was told and taught many things about the Bible. When younger, the Bible was too complex, dense, and old
				to begin to read, and so I trusted traditional and popular interpretation. When morality and free-thinking led me away from the horror of the traditional
				and popular interpretation, I was presented an ultimatum.
			</p>
			<p>
				Many years and many spiritual journeys later, I read the Bible (fully for the first time) with a historic and dettatched viewpoint. I got into not only the 
				canon, but other related texts that put those texts into perspective.
			</p>

			<h3>Academic Benefit</h3>
			<p>Those curious to read and understand the source texts without being overwhelemed by extra religious messages.</p>

			<h3>Spiritual Benefit</h3>
			<h4>Throughout Christian history, knowledge of the Bible has been Power.</h4>
			<p>Big deal about latin and translation</p>
			<p>So many don&apos;t even understand what the Bible even is.</p>
			<p>Power is always held over those who can&apos;t read and understand.</p>

			<h4>Deconstructing the Bible</h4>
			<p>Attempt to de-clutter the Bible for deconstruction.</p>
			<p>Bring together a collection of canon and non-canon texts for comparison and understanding.</p>

			<h2>Secondary Goals</h2>
			<h3>Center for Text Information</h3>
			<p>
				Gathering and keeping updated scholary information on texts (background, years, etc.)
			</p>

			<h3>Center for Text Information</h3>
			<p>
				Abridging of other secondary religious texts (deuterocanon and church fathers)
			</p>

			<h3>Expand to Diverse and Complete Religious Texts</h3>
			<p>
				Acquiring and Processing other Translations of religious texts (gnostic sophia breaking up and giving verses and chapters)
				Gather and make available public domain translations of alternate documents.
			</p>

			<h2>Future Goals</h2>
			<h3>Reference pages</h3>
			<p>Reference pages and links for people, places, and events.</p>

			<h3>Interactive Timelines</h3>
			<p>Timeline tools and reference infromation for people, places, and things</p>

			<h3>Syncretic Versions</h3>
			<p>Bringing together all the gospels into one book; all the books of moses into one book</p>
		</Container>
		<Container>
			<h2>FAQ</h2>
			<h3>Thinking of making an app?</h3>
			<p>{`Short answer: not really, at least soon. But don't worry! 
			If you want the convienence of a icon on your home screen, there are easy ways for both iPhone and Android users to save an item to the homescreen.
			The in-depth technical answer- 
			There's a lot on the plate already- and managing two more software projects takes a lot of time!
			There's not much offering an app benefits in this case, besides the ability to download from the app store.
			Typically, you create a native application when you need native features, like camera, photos, microphone, or rotate and tilt. 
			If you want, think of it as this app is download-free!
			`}</p>

			<br /><br />
			<p>Have a Question or Comment?</p>
			<Link className="primary-button" to="/contact">Contact</Link>

		</Container>
	</>

}

export default Page
