import React from "react"
import axios from "axios"
import { apiPath } from "helpers/helpers"
import { useParams } from "react-router"
import LogIn from "../Login/logIn"

import MetaTags from "components/UI/MetaTags/metaTags"

type VerifyAccountParamType = {
	username: string;
	verify_hash: string;
}

const Verify = () => {
	<MetaTags title={"Verified Account"} url={"users/verify"} privatePage />
	const {username, verify_hash} = useParams<VerifyAccountParamType>()

	axios.get(`${apiPath}/users/auth/verify/${username}/${verify_hash}`)
	
	return <div className='tpBlackBg'>
		<h2>Thank you for verifying</h2>
		<h4>Please login using your credentials.</h4>

		<LogIn />
	</div>
}

export default Verify
