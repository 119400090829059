import React from "react"
import "./index.scss"

import Note from "./Note"
import { NoteType } from "types/note"

const NoteDisplay = ({notes, context}: {notes: NoteType[], context: any}) => {

	return notes.map(note => <Note key={note.note_id} context={context} note={note} />)

}

export default NoteDisplay
