import { BookReaderContext } from "../../../context"
import React, {useContext} from "react"
import { Row, Col } from "react-bootstrap"
import "./Title.scss"
import LinkGrabber from "components/UI/LinkGrabber/LinkGrabber"

const Title = () => {
	const {book} = useContext(BookReaderContext)

	return <Row>
		<Col lg={3}>
		</Col>
		<Col lg={6}>
			<div style={{padding: "5rem 0"}}>
				<p>#{book.order}</p>
				<h1>The Abridged {book.full_name}</h1>
				<p className="info-faded">What is the {book.full_name}?</p>
				{book.description}
			</div>
		</Col>
		<Col lg={3}>
			<br />
			<br />
			<LinkGrabber link={`books/${book.name}`} linkNoun="book" />
		</Col>
	</Row>
}

export default Title
