import React, { useEffect, useState } from "react"
import "./index.scss"
import { indexApi } from "helpers/api_helper"
import { GeneralizeItems } from "helpers/item_helper"
import ToolTip from "../ToolTip"

type ResourceSelectProps = {
	path: string
	callback: (id: number, name: string) => void
	existing: any[]
	idField: string
	nameField: string
	title: string
}

const ResourceSelect = ({ path, callback, existing, idField, nameField, title }: ResourceSelectProps) => {
	const [allItems, rawSetAllItems] = useState<any[]>([])
	const setAllItems = (data: any[]) => {
		rawSetAllItems(GeneralizeItems(data, idField, nameField))
	}
	const existingItems = GeneralizeItems(existing, idField, nameField)

	const [searchTerm, setSearchTerm] = useState("")
	const [open, setOpen] = useState(false)

	const loadPage = () => {
		indexApi(path, setAllItems)
	}
	useEffect(() => { loadPage() }, [])

	let showItems = allItems.filter(item => !existingItems.find(i => i.id === item.id))
	if (searchTerm) showItems = showItems.filter(item => item.name.toLowerCase().match(searchTerm.toLowerCase()))

	return <span className="primary-button resource-select">
		<span onClick={() => setOpen(true)}>{title}</span>
		{open && <div className="resource-select-box">
			<div style={{ display: "flex", alignItems: "center" }}>
				<input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
				<ToolTip text="Close prompt box.">
					<span className="primary-button small-button" onClick={() => setOpen(false)}><i className="fas fa-xmark"></i></span>
				</ToolTip>
			</div>
			<div className="resource-select-results">
				{
					showItems.map(item =>
						<div className="resource-select-option format-link" key={item.id} onClick={() => callback(item.id, item.name)}>
							{item.name}
						</div>
					)
				}
			</div>
		</div>}
	</span>
}



export default ResourceSelect
