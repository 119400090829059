import React from "react"
import { Routes, Route } from "react-router-dom"

import SubmitNote from "./Index/index"

const TranslationComponent = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>
				<Route path="" element={<SubmitNote />} />
			</Routes>
		</div>
	</div>
}

export default TranslationComponent
