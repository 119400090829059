import React, { useState, ReactElement, FormEvent } from "react"
import "./form.scss"
import FormField from "./FormField"
import { updateApi, createApi } from "../../../helpers/api_helper"
import { loadingSpinner } from "siteSettings"
import { Form } from "react-bootstrap"
import { FormColors } from "types/forms"

type FormBuilderProps = {
	FormFields: any[]
	itemData: any
	path: string
	method: "create" | "update"
	
	options?: {
		hideOnSuccess?: boolean
		successElement?: ReactElement
		role?: number
	}

	callbacks?: {
		success: (item: any) => void
	}
}

const FormBuilder = ({ FormFields, itemData, path, method, options, callbacks }: FormBuilderProps) => {
	if (!itemData) return loadingSpinner

	const [item, setItemData] = useState<any>(itemData)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [errors, setErrors] = useState<any[]>([])
	const [fieldColor, setFieldColor] = useState("transparent")

	const loadPage = () => { }

	const submit = async (e: FormEvent) => {
		e.preventDefault()
		if(requiredFieldsLeft !== 0) return false
		setLoading(true)
		let res: any = ""
		if (method === "create") { res = createApi(path, item, { loadPage }) }
		if (method === "update") { res = updateApi(path, item.id, item, { loadPage }) }
		res = await res
		setLoading(false)
		if (res === true) {
			setFieldColor("green")
			setSuccess(true)
			setErrors([])
			if(callbacks?.success) callbacks.success(item)
		}
		else {
			setFieldColor("red")
			setErrors([...errors, res.message])
		}
		setTimeout(() => { setFieldColor("transparent") }, 1000)
	}


	const requiredFieldsLeft = FormFields.filter(field => item[field.key] === null && field.options?.required).length

	if (loading) return loadingSpinner
	return <Form onSubmit={submit} style={{ backgroundColor: FormColors[fieldColor] }}>
		{success ? (options?.successElement ? options.successElement : "Successfully submitted") : ""}
		{errors.length > 0 ? errors.map(e => <p key={e}>{e}</p>) : ""}

		{(success && options?.hideOnSuccess) ? "" : <>
			{FormFields.map(fieldData => <>
				<FormField
					key={fieldData.key}
					fieldData={fieldData}
					update={setItemData}
					options={options}
					item={item} />
			</>)}

			<br /> <br />

			{requiredFieldsLeft === 0 ?
				<button type="submit" className="primary-button">Submit</button> :
				`Please complete all required fields (${requiredFieldsLeft})`}
		</>
		}
	</Form>

}

export default FormBuilder
