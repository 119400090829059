import React, { useContext } from "react"

import { DastContext } from "../../context"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { FormFieldType, FormItemType } from "types/forms"


function TableDataCell({ item, field, itemNumber }: { item: FormItemType, field: FormFieldType, itemNumber: number }) {
	const evenOddClass = itemNumber / 2 === Math.round(itemNumber / 2) ? "dr-even" : "dr-odd"

	const { options } = useContext(DastContext)

	const display = <DynamicField 
		item={item} 
		path={options.updateUrl || ""} 
		field={field} 
		context={options.context} 
		autosave={true} 
		blankDisplay={"?"} />

	
	const width = field.options?.width || options.cellWidth || "100px"
	return <div key={field.key} className={`data-cell ${evenOddClass}`} style={{ width }}>
		{display}
	</div>
}

export default TableDataCell
