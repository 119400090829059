import React from "react"
import "./tableOfContents.scss"
import { Container, Row, Col } from "react-bootstrap"

const TocOptions = ({context}: {context: any}) => {
	const { book, 
		localTranslationChoice, setLocalTranslationChoice,
		showAbridged, setShowAbridged,
		showCommentary, setShowCommentary,
		compareTranslation, setCompareTranslation } = context

	const translationOptions = book.allTranslations?.map((tv: any)=> {
		return { name: tv.abbreviation, value: tv.translation_version_id }
	})

	return <div className="toc-panel">
		<Container>
			<h5>Options</h5>
			<Row>
				<Col xs={12} md={6} lg={3}>
					{setShowAbridged ?
						<label>
							<Row>
								<Col xs={11} lg={8}>Show Abridged Version</Col>
								<Col xs={1} lg={1}><input type="checkbox" checked={showAbridged} onChange={(e) => setShowAbridged(e.target.checked)} /></Col>
							</Row>
						</label>
						: ""}
				</Col>
				<Col xs={12} md={6} lg={3}>
					{setShowCommentary ?
						<label>
							<Row>
								<Col xs={11} lg={8}>Show Commentary</Col>
								<Col xs={1} lg={1}><input type="checkbox" checked={showCommentary} onChange={(e) => setShowCommentary(e.target.checked)} /></Col>
							</Row>
						</label>
						: ""}

				</Col>
				<Col xs={12} md={6} lg={3}>
					<label>
						<Row>
							<Col xs={11} lg={8}>Show Original Translation</Col>
							<Col xs={1} lg={1}><input type="checkbox" checked={compareTranslation} onChange={(e) => setCompareTranslation(e.target.checked)} /></Col>
						</Row>
					</label>
				</Col>
				<Col xs={12} md={6} lg={3}>
					<label>
						<Row>
							<Col xs={9} lg={6}>
                                Available Translations
							</Col>
							<Col xs={3} lg={6}>
								<select onChange={(e) => setLocalTranslationChoice(Number.parseInt(e.target.value))}>
									{translationOptions?.map(({ name, value }: { name: string, value: number }) => 
										<option key={value} value={value} selected={value === localTranslationChoice}>
											{name}
										</option>
									)}
								</select>
							</Col>
						</Row>
					</label>
				</Col>
			</Row>

		</Container>
	</div>
}

export default TocOptions
