import React, { useEffect, useState } from "react"
import "./index.scss"
import { Container } from "react-bootstrap"

import SocialPost from "./SocialPost"
import { indexApi } from "helpers/api_helper"
import { SocialPostFormFields, SocialPostType } from "types/socialPost"

import MetaTags from "components/UI/MetaTags/metaTags"
import ApproveNextPost from "./ApproveNextPost"
import Lister from "components/UI/Lister/Lister"

const Index = () => {
	<MetaTags title={"Admin Social Posts"} url="social" privatePage />
	const [socialPosts, setSocialPosts] = useState<SocialPostType[]>([])

	useEffect(() => { loadPage() }, [])
	const loadPage = () => { indexApi("social_posts", setSocialPosts) }
	const context = { loadPage }

	return <div id="socialPosts-view">

		<div style={{ padding: "4rem" }}>
			<h1>Scheduled Posts</h1>
		</div>
		<div className="book-sort-banner">
			<ApproveNextPost context={context} />
		</div>

		<Container>
			<Lister
				items={socialPosts}
				FormFields={SocialPostFormFields}
				ItemComponent={SocialPost}
				context={context}
				filters={["kind"]}
				search={["title", "body"]}
				sort={["kind", "scheduled_date", "title"]}
				options={{ defaultSortField: "scheduled_date", defaultSortOrder: -1 }} />
		</Container>

	</div>

}

export default Index
