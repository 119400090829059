import React from "react"
import "./footer.scss"
import { Link } from "react-router-dom"
import { version } from "siteSettings"

const Footer = () => {
	return <div className="footer d-print-none">
		<div className="footer-content">

			<span>2023</span>
			&nbsp;|&nbsp;
			<Link to="/terms-and-conditions">Terms</Link> &nbsp;|&nbsp;
			<Link to="/privacy-policy">Privacy</Link> &nbsp;|&nbsp;
			{version}
		</div>
	</div>
}

export default Footer
