import React from "react"
import { App } from "./structure/App"
import { BrowserRouter } from "react-router-dom"
import { googleFontString } from "./siteSettings"
import { createRoot } from "react-dom/client"
import { ScrollToTop } from "components/UI/scrollToTop"
import { HelmetProvider } from "react-helmet-async"

const container = document.getElementById("root")
if (container) {
	const root = createRoot(container)

	root.render(<BrowserRouter>
		<HelmetProvider>
			<ScrollToTop />
			<App />
			<style>{`@import url('https://fonts.googleapis.com/css?family=${googleFontString()}&display=swap')`}</style>
		</HelmetProvider>
	</BrowserRouter>)
}