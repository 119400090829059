import React, {useContext} from "react"
import { Col, Row } from "react-bootstrap"

import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { printNumber, printVerse } from "helpers/verse_helper"
import { PrintTranslations } from "helpers/translation_helper"
import { VerseType } from "types/verse"
import { PrinterContext } from "../../printerContext"

const VerseComponent = ({ verse }: { verse: VerseType }) => {
	const { showAbridged,
		showCommentary,
		compareTranslation } = useContext(PrinterContext)
	if (!verse.verse_id) return ""

	//Possible to only show commentary.
	if (!compareTranslation && !showAbridged) {
		if (showCommentary && verse.commentary) {
			return <p>{printNumber(verse)} {convertNewLinesToLineBreaks(verse.commentary)}</p>
		} else {
			return ""
		}
	}

	let verseComponent: any = ""
	if (compareTranslation && showAbridged) {
		verseComponent = <Row>
			<Col xs={6}>
				{printVerse(verse)}
			</Col>
			<Col xs={6}>
				<PrintTranslations translations={verse.translations} />
			</Col>
		</Row>
	} else {
		if (compareTranslation) {
			verseComponent = <PrintTranslations translations={verse.translations} />
		} else {
			verseComponent = printVerse(verse)
		}
	}

	return <div className="verse-pair">
		{verse.subtitle ? <h4>{verse.subtitle}</h4> : ""}
		{verseComponent}
		{showCommentary && verse.commentary ? <p style={{ fontStyle: "italic" }}>*{convertNewLinesToLineBreaks(verse.commentary)}</p> : ""}
	</div>

}

export default VerseComponent
