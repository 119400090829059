import FormBuilder from "components/UI/FormBuilder/Form"
import Modal from "components/UI/Modal/Modal"
import { curr_user } from "helpers/helpers"
import { itemApi } from "helpers/api_helper"
import React, { useState } from "react"
import { loadingSpinner } from "siteSettings"
import { SocialPostFormFields, SocialPostType } from "types/socialPost"

interface SocialPostProps {
	context: { loadPage: () => void }
}

const ApproveNextPost = ({ context }: SocialPostProps) => {
	const [loading, setLoading] = useState(false)
	const [itemResult, setItemResult] = useState<SocialPostType | null>(null)

	const generatePost = async () => {
		context.loadPage()
		setLoading(true)
		await itemApi("social_posts", "next", setItemResult)
		setLoading(false)
	}

	return <Modal titleText="Next Post" buttonText="Generate Post" callbacks={{ onOpen: () => generatePost() }}>
		<div className="approve-post">
			{itemResult && !loading && <FormBuilder FormFields={SocialPostFormFields} itemData={{...itemResult, user_id: curr_user.user_id}} path="social_posts" method="create" callbacks={{ success: generatePost }} />}

			{loading && <div>{loadingSpinner}<br />This make take a minute or two.</div>}

			<span className="primary-button" onClick={generatePost}>Generate Post</span>
		</div>
	</Modal>
}

export default ApproveNextPost
