import React, { useEffect, useState } from "react"
import "./index.scss"
import { Container, Row } from "react-bootstrap"

import TranslationVersion from "./TranslationVersion"
import { createApi, indexApi } from "helpers/api_helper"
import { TranslationVersionType } from "types/translationVersions"
import { checkRole } from "helpers/helpers"
import { loadingSpinner } from "siteSettings"
import MetaTags from "components/UI/MetaTags/metaTags"
import ToolTip from "components/UI/ToolTip"

const Index = () => {
	const [translationVersions, setTranslationVersions] = useState<TranslationVersionType[]>([])
	const [newTranslationVersion, setNewTranslationVersion] = useState("")

	useEffect(() => { loadPage() }, [])

	const loadPage = () => {
		indexApi("translation_versions", setTranslationVersions)
	}

	const createTranslationVersion = () => {
		createApi("translation_versions", { name: newTranslationVersion }, context)
	}

	const context = {
		loadPage,
	}

	return <div id="translationVersions-view">
		<MetaTags
			title={"Translation Versions"}
			description={"All translations and versions we have of the Bible and other religious texts."}
			url={"translations"} />

		<div className="pv-5">
			<h1>All Manuscripts and Translations</h1>
			<p>A list of all the different English translations</p>
		</div>
		<div className="dark-stretchy">
			{checkRole(2) ?
				<div>
					<input value={newTranslationVersion} onChange={(e) => setNewTranslationVersion(e.target.value)} />&nbsp;
					<ToolTip text="Create new category">
						<span className="primary-button small-button" onClick={createTranslationVersion}><i className="fas fa-plus"></i></span>
					</ToolTip>
				</div> : ""}
		</div>

		{translationVersions.length === 0 ? loadingSpinner :
			<Container className="translationVersion-outer-container pv-2">
				<Row>
					{translationVersions.sort((a, b) => a.translation_version_id - b.translation_version_id).map(translationVersion =>
						<TranslationVersion key={translationVersion.translation_version_id} context={context} translationVersion={translationVersion} />
					)}
				</Row>
			</Container>}


	</div>

}

export default Index
