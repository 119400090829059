import React, { useContext } from "react"
import BookPart from "../../Part/Part"
import { createApi } from "helpers/api_helper"
import { BookEditorContext } from "components/BookEditor/context"



const BookParts = () => {
	const context = useContext(BookEditorContext)
	const {book} = context

	const appendPart = () => {
		createApi(`/parts/append/${book.book_id}`, {}, context)
	}

	return <div>

		{book.parts?.map(part => <BookPart part={part} key={part.book_part_id} />)}

		<span onClick={appendPart}>
			<span className="fas fa-circle-plus"></span> Add Part
		</span>

	</div>

}

export default BookParts
