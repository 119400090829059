import React from "react"
import { Link } from "react-router-dom"

import { CategoryType } from "types/categories"
import { checkRole } from "helpers/helpers"
import { deleteApi } from "helpers/api_helper"
import { Col } from "react-bootstrap"

interface CategoryProps {
	category: CategoryType
	categories: CategoryType[]
	context: any
}

const CategoryComponent = ({ category, context }: CategoryProps) => {
	if (!category) return ""
	const deletePath = () => { deleteApi("categories", category.category_id, context, category?.name) }

	return <Col xs={12} lg={4} className="category-item" key={category.category_id}>
		<div className="category-item-header">
			<Link to={`/categories/${category.name}`}><h2>{category.name}</h2></Link>
			{checkRole(2) && <span className="primary-button small-button" style={{ marginLeft: "2rem" }} onClick={() => deletePath()}><i className="fas fa-trash"></i></span>}
		</div>
		<p>{category.bookAmount} Books</p>
		<p>{category.short_description}</p>
		{category.subcategories.length > 0 && <div>Includes: {category.subcategories.sort((a, b) => a.order - b.order).map(c => c.name).join(", ")}</div>}
		<br />
		<div className="align-center p-3">
			<Link style={{ margin: "auto" }} className="primary-button secondary-button small-button" to={`/categories/${category.name}`}>See More</Link>
		</div>
	</Col>
}

export default CategoryComponent
