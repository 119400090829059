import React from "react"
import { Routes, Route } from "react-router-dom"

import ViewSocialPosts from "./Index/index"
import { checkRole } from "helpers/helpers"

const SocialPostComponent = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>
				{checkRole(2) && <Route path="" element={<ViewSocialPosts />} />}
			</Routes>
		</div>
	</div>
}

export default SocialPostComponent
