import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { abridgedStatus, authorText, bookFieldData, preservationText, printAcademicYearRange, printSettingYearRange } from "helpers/books_helper"
import { BookReaderContext } from "../../../context"
import { TranslationVersionType } from "types/translationVersions"
import ToolTip from "components/UI/ToolTip"

const MainInfo = () => {
	const { book } = useContext(BookReaderContext)

	const translationOptions = book.allTranslations.map((tv: TranslationVersionType) =>

		<ToolTip placement="bottom" key={tv.abbreviation} text={tv.name}>
			<Link style={{ padding: ".25rem" }} to={`/translations/${tv.abbreviation}/${book.name}`}>{tv.abbreviation}</Link>
		</ToolTip>
	)

	return <div>
		<div className="control-title-bar ctb-part" id="book-summary">
			<div className="control-main-bar">
				<h2 className="control-bar-name">Summary and Background</h2>
			</div>
		</div>
		<Row>
			<Col lg={12}>

				<div className="control-title-bar ctb-chapter">
					<div className="control-main-bar">
						<h2 className="control-bar-name">Stats</h2>
					</div>
				</div>

				<Row className="stats-box">
					<Col lg={3}>
						<b>Other Names</b><br />
						{book.abbreviation} {book.alternate_names}
					</Col>
					<Col lg={3}>
						<ToolTip text={bookFieldData("author").description}>
							<b>Author</b><br />
							{authorText(book)}
						</ToolTip>
					</Col>

					<Col lg={3}>
						<ToolTip text={bookFieldData("setting_year").description}>
							<b>Setting Range</b><br />
							{printSettingYearRange(book)}
						</ToolTip>
					</Col>

					<Col lg={3}>
						<b>Categories</b><br />
						{book.categories.map(c =>
							<ToolTip placement="bottom" key={c.category_id} text={c.short_description}>
								<Link to={`/categories/${c.name}`}> {c.name} </Link>
							</ToolTip>
						)}
						<br />
					</Col>

					<Col lg={3}>
						<ToolTip text={bookFieldData("written_year").description}>
							<b>Academic Composition Range</b><br />
							{printAcademicYearRange(book)}
						</ToolTip>
					</Col>

					<Col lg={3}>
						<ToolTip text="How much of the original text we've found to date.">
							<b>Historical Preservation</b><br />
							{preservationText(book)}
						</ToolTip>
					</Col>

					<Col lg={3}>
						<ToolTip text="Is there a public domain version of this text available?">
							<b>Public Domain Translations</b><br />
							{book.original_verse_count > 1 ? translationOptions : "Unknown and/or Copyrighted"}<br />
						</ToolTip>
					</Col>

					<Col lg={3}>
						<b>Abridged Status</b><br />
						{abridgedStatus(book)}
					</Col>

					<Col lg={3}>
						<b>Original Verse Count</b><br />
						{book.original_verse_count > 1 ? book.original_verse_count?.toLocaleString() : "?"} <br />
					</Col>

					<Col lg={3}>
						<b>Original Word Count</b><br />
						{book.original_word_count > 3 ? book.original_word_count?.toLocaleString() : "?"}<br />
					</Col>

					<Col lg={3}>
						<b>Abridged Word Count</b><br />
						{book.abridged_word_count?.toLocaleString()}<br />
					</Col>

					<Col lg={3}>
						<ToolTip text="How much shorter the abridged version is.">
							<b>Abridged Length</b><br />
							{Math.round(100 * book.abridged_word_count / book.original_word_count) + "%"}<br />
						</ToolTip>
					</Col>
				</Row>

			</Col>

			<Col lg={12}>
				{book.history ? <div>
					<div className="control-title-bar ctb-chapter">
						<div className="control-main-bar">
							<h2 className="control-bar-name">History and Background of the {book.full_name}</h2>
						</div>
					</div>
					<p className="info-faded">What do scholars and theologians know about the {book.full_name}?</p>
					<p className="align-left">{convertNewLinesToLineBreaks(book.history)}</p>
				</div> : ""}
			</Col>

			<Col lg={12}>

				{book.summary ? <div>
					<div className="control-title-bar ctb-chapter">
						<div className="control-main-bar">
							<h2 className="control-bar-name">Summary of the {book.full_name}</h2>
						</div>
					</div>
					<p className="info-faded">What happens in the {book.full_name}?</p>
					<p className="align-left">{convertNewLinesToLineBreaks(book.summary)}</p>
				</div> : ""}
			</Col>
		</Row >
	</div >

}

export default MainInfo
