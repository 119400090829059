import React, { useState } from "react"
import "./tableOfContents.scss"
import { Link } from "react-router-dom"
import TocOptions from "./Options"
import TocParts from "./Parts"
import { Container } from "react-bootstrap"
import { checkRole } from "helpers/helpers"

const TableOfContents = ({ context }: { context: any }) => {
	const { book } = context
	const [open, setOpen] = useState(false)

	const bgClick = (e: any) => {
		if (e.target.getAttribute("id") === "toc-body-bg") {
			setOpen(false)
		}
	}

	return <div className="table-of-contents">
		<div className="toc-panel" id="toc-header">
			<Container>
				<Link to="/books">{"< Back"}</Link>
				<Link to={`/print/books?books=${book.book_id}`}>{"Print"}</Link>
				{checkRole(2) ? <>
					<Link to={`/books/${book.name}`}>{"Read"}</Link>
					<Link to={`/books/${book.book_id}/edit`}>{"Edit"}</Link>
				</> : ""}
				<span onClick={() => setOpen(!open)}>{open ? <span>Close <i className="fas fa-caret-down"></i></span> : <span>Options <i className="fas fa-caret-up"></i></span>}</span>
			</Container>
		</div>

		{open ? <div id="toc-body-bg" className="toc-body" onClick={(e) => bgClick(e)}>
			<div style={{ minHeight: "100vh" }}>
				<Container>
					<TocOptions context={context} />
					<br /><br />
					<TocParts setOpen={setOpen} context={context} />
				</Container>
			</div>
		</div> : ""}
	</div>
}

export default TableOfContents
