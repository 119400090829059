import React from "react"

type PaginatorNumberSelectionType = {
	page: number
	paginator: {
		totalPages: number,
		count: number,
		pageSize: number
	}
	setPage: (page: number) => void
	pagesVisible?: number
}

const PaginatorNumberSelection = ({ page, paginator, setPage, pagesVisible }: PaginatorNumberSelectionType) => {
	if (!paginator.count || paginator.count === 0) return ""
	if (!pagesVisible) pagesVisible = 2

	//Get the rational start & end bounds based on current page and other stats.
	let startPage = page - pagesVisible
	if (startPage < 1) startPage = 1
	let endPage = page + pagesVisible
	if (endPage > paginator.totalPages) endPage = paginator.totalPages

	//Fill in the range
	const pages = []
	for (let p = startPage; p <= endPage; p++) {
		pages.push(p)
	}

	const pageLink = (number: number, title: string | null = null) => {
		if(number === page) return <span className="format-link p-2" onClick={() => setPage(number)}>{title || number}</span>
		return <span className="primary-button secondary-button small-button" onClick={() => setPage(number)}>{title || number}</span>
	}

	return <div>
		{ pages[0] !== 1 && <>{pageLink(1)} ... </> }

		{pages.map(p => pageLink(p))}

		{ pages[pages.length-1] !== paginator.totalPages && <> ... {pageLink(paginator.totalPages)} </>}
	</div>

}

export default PaginatorNumberSelection