import { FormFieldType } from "./forms"

export interface NewNoteType {
	foreign_id: number | null
	foreign_class: string | null
	text: string | null
	kind: number | null
	urgency: number | null
}

export const BlankNote = {
	foreign_id: null,
	foreign_class: null,
	text: null,
	kind: null,
	urgency: null
}

export interface NoteType extends NewNoteType {
	note_id: number
	id: number
	user_id: number | null
	created_at: number
	status: number
	item?: any
	user?: any
}

export type NoteForeignClassType = "book" | "part" | "chapter" | "verse" | "translation_version" | "translation" | "category"

export const foreign_class_options = [
	"book",
	"part",
	"chapter",
	"verse",
	"translation_version",
	"translation",
	"category"
]
export const note_kind_options = [
	"General Review",
	"Specific Fix",
	"Research",
	"Other",
]
export const note_urgency_options = [
	"V Low",
	"Low",
	"Mid",
	"Hi",
	"V Hi"
]
export const note_status_options = [
	"Unseen",
	"In Process",
	"Finished"
]



export const NoteFormFields: FormFieldType[] = [
	{
		key: "foreign_id",
		title: "",
		description: "",
		example: "",
		kind: "string",
		options: { hidden: true }
	},
	{
		key: "foreign_class",
		title: "",
		description: "",
		example: "",
		kind: "string",
		options: { hidden: true, valueArray: foreign_class_options }
	},
	{
		key: "kind",
		title: "Kind",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: note_kind_options }
	},
	{
		key: "text",
		title: "Text",
		description: "",
		example: "",
		kind: "text"
	},
	{
		key: "status",
		title: "Status",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: note_status_options }
	},
	{
		key: "urgency",
		title: "Urgency",
		description: "",
		example: "",
		kind: "enum",
		options: { valueArray: note_urgency_options }
	},
	{
		key: "created_at",
		title: "Created",
		description: "",
		example: "",
		kind: "date"
	},
]

export const NewNoteFields: FormFieldType[] = [
	...NoteFormFields.filter(f => !["created_at", "status", "urgency"].includes(f.key))
]