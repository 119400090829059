import TableAxisX from "./TableAxisX"
import TableAxisY from "./TableAxisY"
import TableData from "./TableData"
import { DastContext } from "../../context"
import React, { useContext } from "react"

function Table() {
	const { cssId } = useContext(DastContext)

	return <div className="dast-table-container" id={cssId}>
		<TableAxisY />

		<div className="dast-right">
			<TableAxisX />
			<TableData />
		</div>
	</div>
}

export default Table
