import React, { useContext } from "react"
import { convertNewLinesToLineBreaks } from "helpers/helpers"
import { printChapterHeading } from "helpers/chapters_helper"
import { ChapterType } from "types/chapter"
import { BookReaderContext } from "components/BookReader/context"
import LinkGrabber from "components/UI/LinkGrabber/LinkGrabber"

const ControlBar = ({ chapter }: { chapter: ChapterType }) => {
	const {book} = useContext(BookReaderContext)

	return <div className="control-title-bar ctb-chapter">
		<div className="control-main-bar">
			<h2 className="control-bar-name">
				{printChapterHeading(chapter)}
				
				<span style={{display:"inline-block",marginLeft:"1rem"}}>
					<LinkGrabber link={`books/${book.name}?chapter=${chapter.numbers[0]}`} linkNoun="chapter" />
				</span>
			</h2>
			{chapter.summary ? <div>
				<hr />
				<p className="align-left">{convertNewLinesToLineBreaks(chapter.summary)}</p>
			</div> : ""}
		</div>

	</div>

}

export default ControlBar
