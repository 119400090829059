import TableAxisXCell from "./TableAxisXCell"

import { DastContext } from "../../context"
import React, { useContext } from "react"

function TableAxisX() {
	const {
		fieldSort,
	} = useContext(DastContext)

	return <div className="dast-label-x dast-scroll-x">
		<div className="item-wide">
			{
				fieldSort().map((field) => 
					<TableAxisXCell key={field.key}  field={field} />
				)
			}
		</div>
	</div>
}

export default TableAxisX
