import React from "react"
import { Routes, Route } from "react-router-dom"

import TranslationVersionsListComponent from "./Index/index"
import TranslationVersionsViewComponent from "./View/index"
import TranslationVersionsBookComponent from "./Book/index"

const TranslationComponent = () => {
	return <div className="body">
		<div className="page-container">
			<Routes>
                
				<Route path="" element={<TranslationVersionsListComponent />} />
				<Route path=":id" element={<TranslationVersionsViewComponent />} />
				<Route path=":id/:book_id" element={<TranslationVersionsBookComponent />} />


			</Routes>
		</div>
	</div>
}

export default TranslationComponent
