import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"

import Title from "./components/Title"
import MainInfo from "./components/MainInfo"
import BookParts from "./components/BookParts"
import TableOfContents from "../TableOfContents/TableOfContents"
import TranslationStatus from "./components/TranslationStatus"
import BottomNavigation from "./components/BottomNavigation"
import { BookReaderContext } from "components/BookReader/context"
import TableOfContentsParts from "components/BookReader/components/TableOfContents/Parts"

const Book = () => {
	const context = useContext(BookReaderContext)
	return <>
		<TableOfContents context={context} />
		<Container>
			<Title />
			<Row>
				<Col className="d-lg-only" lg={3}>
					<div className="side-contents">
						<TableOfContentsParts context={context} setOpen={() => { }} />
					</div>
				</Col>
				<Col xs={12} lg={9}>
					<MainInfo />
					<BookParts />
				</Col>
			</Row>
			<TranslationStatus />
			<BottomNavigation />
		</Container>
	</>
}

export default Book
