import React, { useContext } from "react"
import { BookEditorContext } from "components/BookEditor/context"
import { BookField, BookFieldComplete } from "helpers/books_helper"
import { BookEditorFields } from "types/books"
import { Col, Row } from "react-bootstrap"

const MainInfo = () => {
	const context = useContext(BookEditorContext)
	const { book } = context

	return <div className="ctb-chapter control-title-bar">

		<h2 className="book-part-title control-main-bar">Stats & Info</h2>
		<Row className="book-part-stats">
			{BookEditorFields.map(field => <Col lg={3} key={field.key}>{BookFieldComplete(book, field.key, context)}</Col>)}
		</Row>

		<h2 className="book-part-title control-main-bar">History & Background</h2>
		<p className="align-left">
			{BookField(book, "history", context)}
		</p>

		<h2 className="book-part-title control-main-bar">Summary</h2>
		<p className="align-left">
			{BookField(book, "summary", context)}
		</p>

	</div>
}

export default MainInfo
