import React from "react"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { FField } from "./item_helper"
import { SocialPostFormFields } from "types/socialPost"

export const SocialField = (item: any, fieldname: any, context: any) => {
	const field = FField(fieldname, SocialPostFormFields)
	if(field) {
		return <DynamicField item={item} path={"social_posts"} context={context} autosave={true} field={field} />
	}
}