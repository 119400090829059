import { FullBookType, emptyBookObject } from "types/books"
import { ChapterType } from "types/chapter"
import { PartType } from "types/parts"
import React from "react"

export interface DragItemType {
	item_kind: string
	item_id: number
	item_order: number
	item_parent: number
}

export interface BookEditorContextType {
	loadPage: () => void
	book: FullBookType,
	parts: PartType[],
	chapters: ChapterType[],
	compareTranslation: boolean
	setCompareTranslation: (value: boolean) => void
	dragItem: DragItemType | null
	setDragItem: (value: DragItemType | null) => void
	localTranslationChoice: number
	setLocalTranslationChoice: (value: number) => void
}

export const BookEditorContext = React.createContext<BookEditorContextType>({
	loadPage: () => { return },
	book: emptyBookObject,
	parts: [],
	chapters: [],
	compareTranslation: true,
	setCompareTranslation: () => { return },
	dragItem: null,
	setDragItem: () => { return },
	localTranslationChoice: 0,
	setLocalTranslationChoice: () => { return }
})