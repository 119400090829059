import React from "react"
import { Row, Col } from "react-bootstrap"
import { FormFieldType } from "types/forms"

const FieldRow = (field: FormFieldType) => {
	return <Row style={{ margin: ".5rem 0", padding: ".5rem 0", borderTop: "1px solid #ccc" }}>
		<Col lg={3}>{field.title}</Col>
		<Col lg={6} className="align-left">{field.description}</Col>
		<Col lg={3} className="align-left">{field.example}</Col>
	</Row>
}

export default FieldRow