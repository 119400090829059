import React from "react"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import { NoteFormFields, NoteType } from "types/note"
import { note_kind_options, note_status_options, note_urgency_options } from "types/note"
import { FormFieldType } from "types/forms"

export const print_note_kind = (note: NoteType) => {
	if (note.kind == null) return null
	return note_kind_options[note.kind]
}

export const print_note_urgency = (note: NoteType) => {
	if (note.urgency == null) return null
	return note_urgency_options[note.urgency]
}

export const print_note_status = (note: NoteType) => {
	if (note.status == null) return null
	return note_status_options[note.status]
}

export const print_created_time = (note: NoteType) => {
	const dateObj = new Date(note.created_at)
	return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear() - 2000} ${dateObj.getHours()}:${dateObj.getMinutes()}`
}

export const noteFieldData = (fieldname: any) => {
	const result = NoteFormFields.find(f => f.key === fieldname)
	if(result) return result
	const blankResult: FormFieldType = {key: "", title: "", description: "", example: "", kind: "number"}
	return blankResult
}

export const NoteField = (note: any, fieldname: any, context: any) => {
	const field = noteFieldData(fieldname)
	if(field) {
		return <DynamicField item={note} path={"notes"} context={context} autosave={true} field={field} tooltip={true} />
	}
}
export const NoteFieldComplete = (note: any, fieldname: any, context: any) => {
	const field = noteFieldData(fieldname)
	if(field) {
		return <DynamicField item={note} path={"notes"} context={context} autosave={true} field={field} label={true} tooltip={true} />
	}
}
