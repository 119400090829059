import React from "react"
import { Routes, Route } from "react-router-dom"

import About from "./about/index"
import Help from "./help/index"
import HelpWanted from "./help-wanted/index"
import Privacy from "./privacy/index"
import Terms from "./terms/index"

import FourOhFour from "../../structure/404"

const Pages = () => {
	return <div>
		<Routes>
			<Route path="about" element={<About />} />
			<Route path="help" element={<Help />} />
			<Route path="help-the-project" element={<HelpWanted />} />
			<Route path="privacy-policy" element={<Privacy />} />
			<Route path="terms-and-conditions" element={<Terms />} />
			<Route path="" element={<FourOhFour />} />
		</Routes>
	</div>
}

export default Pages
