export type FieldKindType = "string" | "text" | "number" | "enum" | "boolean" | "password" | "id-select" | "placeholder" | "date"

export type FormFieldType = {
    key: string //the field name on the object
    title: string //the user-friendly title to show
    kind: FieldKindType
    description: string
    example: string
    options?: {
        width?: string //css string for width display
        valueArray?: any[]
        required?: boolean
        validation?: (data: any) => any
		widthSize?: number //int for char spaces
        hidden?: boolean //a hidden data field to pass along
        textbox?: boolean //override the input field with a textbox
    }
}

export interface FormItemType extends Record<string, any> {
    id: number
}

export const FormColors: Record<string, string> = {
	"none": "transparent",
	"red": "rgba(200,0,0,.4)",
	"green": "rgba(0,200,0,.4)"
}