import React, {useEffect, useState} from "react"
import "./index.scss"
import UserCard from "./components/userCard"

import { UserType } from "types/user"
import { indexApi } from "helpers/api_helper"
import { Container } from "react-bootstrap"

import MetaTags from "components/UI/MetaTags/metaTags"

const Index = () => {
	const [users, setUsers] = useState<UserType[]>([])

	useEffect(() => loadPage(), [])
	
	const loadPage = () => { indexApi("users/", setUsers) }

	return <Container style={{paddingTop: "5rem"}}>
		<MetaTags title={"User Management"} url={"users"} privatePage />

		<h1>Manage Users</h1>
		<h5>Total Users: {users.length}</h5>
		{
			users.map((user) => <div key={user.user_id}>
				<UserCard user={user} loadPage={loadPage}/>
			</div>)
		}
	</Container>
	
}

export default Index
