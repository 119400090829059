import React from "react"
import { Link } from "react-router-dom"
import { checkRole } from "helpers/helpers"

import { Col, Row } from "react-bootstrap"
import { deleteApi } from "helpers/api_helper"
import { abridgedProgressButton, publicDomainButton } from "helpers/books_helper"
import { BookCategoryType, CategoryType } from "types/categories"
import DynamicField from "components/UI/DynamicFormField/DynamicField"
import ToolTip from "components/UI/ToolTip"

interface CategoryProps {
	book: BookCategoryType;
	context: {
		loadPage: () => void;
	}
	category: CategoryType
}

const Book = ({ book, context, category }: CategoryProps) => {
	const removeBook = (books_to_category_id: number) => {
		deleteApi("categories/books/", books_to_category_id, context, "book connection")
	}

	book.id = book.books_to_category_id
	return <Row className="category-view-book-item">
		<Col xs={2} lg={1}>
			#<DynamicField item={book} path={"categories/books"} field={{ key: "order", title: "order", description: "", example: "", kind: "number", options: { widthSize: 3 } }} autosave={true} context={context} />.
		</Col>
		<Col xs={7} lg={8} className="book-name align-left">
			<Link to={`/books/${book.name}`}>{book.name}</Link><br />
			{book.description}
		</Col>
		<Col xs={2} lg={1}>
			{abridgedProgressButton(book)}
		</Col>
		<Col xs={2} lg={1}>
			{publicDomainButton(book)}
		</Col>
		{checkRole(2) ? <Col xs={1} lg={1}>
			<ToolTip text={`Remove ${book.name} from ${category.name}`}>
				<span className="format-link fas fa-trash" onClick={() => { removeBook(book.books_to_category_id) }}></span>
			</ToolTip>
		</Col> : ""}
	</Row>
}

export default Book
