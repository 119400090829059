import React, { useContext, useEffect } from "react"
import ChapterVerses from "./components/ChapterVerses"
import ControlBar from "./components/ControlBar"
import { convertNewLinesToLineBreaks, scrollToId } from "helpers/helpers"
import { ChapterType } from "types/chapter"
import { BookReaderContext } from "components/BookReader/context"

const ChapterComponent = ({ chapter }: { chapter: ChapterType }) => {
	const { scrollPosition } = useContext(BookReaderContext)

	useEffect(() => {
		if (scrollPosition.chapter && chapter.numbers.includes(scrollPosition.chapter) && scrollPosition.verse === null) {
			scrollToId(`chapter-${chapter.chapter_id}`)
		}
	}, [])

	if (chapter.chapter_id !== undefined) {
		chapter.id = chapter.chapter_id
		return <div id={`chapter-${chapter.chapter_id}`}>
			<ControlBar chapter={chapter} />
			{chapter.commentary ? <div>
				<h5 className="book-part-title">Commentary</h5>
				<p className="align-left">{convertNewLinesToLineBreaks(chapter.commentary)}</p>
			</div> : ""}
			<ChapterVerses chapter={chapter} />
		</div>
	} else return ""
}

export default ChapterComponent
