import React, { useEffect, useState } from "react"
import "./index.scss"

import { indexApi } from "helpers/api_helper"
import { BookType, BookCompletionFields } from "types/books"
import DoubleAxisScrollTable from "components/UI/DoubleAxisScrollTable/DoubleAxisScrollTable"
import { Container } from "react-bootstrap"
import { loadingSpinner } from "siteSettings"

import MetaTags from "components/UI/MetaTags/metaTags"
import { FormFieldType, FormItemType } from "types/forms"
import AddBookButton from "./addBook"

const Index = () => {

	const [books, setBooks] = useState<BookType[]>([])

	useEffect(() => { loadPage() }, [])

	const loadPage = () => { indexApi("books", setBooks) }

	const context = { loadPage }

	const fields = BookCompletionFields

	const dastOptions = {
		itemHeaderDisplay: (item: FormItemType) => `${item.name}`,
		fieldHeaderDisplay: (field: FormFieldType) => field.title.replaceAll("_", " "),
		defaultSortField: "order",
		context: context,
		updateUrl: "books",
		cellWidth: "75px"
	}

	return <div id="books-completion-view">
		<MetaTags title={"Mass Book Editor"} url="books/completion" privatePage />
		{
			books.length === 0 ? loadingSpinner :
				<Container style={{ height: "80vh" }}>
					<DoubleAxisScrollTable FormFields={fields} items={books.map(b => { return { ...b, id: b.book_id } })} options={dastOptions} />
				</Container>
		}

		<AddBookButton context={context} />
	</div>

}

export default Index
